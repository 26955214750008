export default function PetGrooming() {
  return (
    <>
      <div className="page-container">
        <main className="content ">
          <h2 className="heading">coming soon</h2>
        </main>
      </div>
    </>
  );
}
