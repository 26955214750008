import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "./style.css";
import "swiper/css";
import "swiper/css/pagination";
import Rating from "./Rating";
import MadhurimaImg from "../../assets/reviewImg/Madhurima.jpg";
import NidhiImg from "../../assets/reviewImg/Nidhi.jpeg";
import Bhartiimg from "../../assets/reviewImg/Bharti.jpg";

const Review = ({ reviewData }) => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    setReviews(reviewData);
  }, [reviewData]);

  const newReviews = [
    { id: 1, name: "Nidhi", review: "We booked home grooming services by DearPet for Goofy and TimTim , and had an amazing experience with them . Goofy and Timtim were extremely comfortable and relaxed throughout . Groomers are very well experienced and good at handling the babies . We would recommend everyone to try their home grooming services .", rating: "5", image: NidhiImg },
    { id: 2, name: "Madhurima", review: "Spa at home with DearPet ! Mishu loves mud but not bathing , ofcourse . He starts barking and gets irritated a lot . But with DearPet he remained calm whole time , during bath as well as during blow - drying . We're so happy to find a service which comes to home and everything happens infront of our eyes . If your idea of grooming is home , comfort and calmness , book your session with DearPet .", rating: "5", image: MadhurimaImg },
    { id: 3, name: "Bharti", review: " It was really a great experience with DearPet , as whiskey doesn't allow strangers to touch him but the groomers of DearPet are so kind , friendly and well - experienced that they first make your pet comfortable and safe then they go ahead with the grooming . They consider your pet as their pet .", rating: "5", image: Bhartiimg },
  ];

  return (
    <div className="container my-5 mb-3">
      <h4 className="sec4Heading my-3 text-center">Reviews</h4>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{ clickable: true }}
        breakpoints={{
          640: { slidesPerView: 1, spaceBetween: 20 },
          768: { slidesPerView: 2, spaceBetween: 30 },
          1024: { slidesPerView: 3, spaceBetween: 40 },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {newReviews.map((review) => (
          <SwiperSlide key={review.id}>
            <div className="card reviewCard">
              <div className="review-bottom">
                <img
                  src={review.image}
                  className="reviewImg"
                  alt={`${review.name}'s profile`}
                />
              </div>
              <div className="card-body reviewCardBody">
                <p className="text-start m-0 rnm">{review.name}</p>
                <Rating value={parseInt(review.rating)} totalStars={5} />
                <p className="card-text reviewMsg">
                  <q>{review.review}</q>
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Review;
