export const packagesData = {
  "Basic": {
    "included": ["Shampoo", "Conditioning", "Combing/Brushing", "Teeth Cleaning/Mouth spray", "Paw massage", "Nail Clipping", "Ear Cleaning", "Eyes cleaning"],
    "not_included": ["Under Paw Trim", "Sanitary Trim", "Face Haircut", "Body Massage", "Full Body Trimming", "Hair Styling", "Anti-Tick Bath", "De-Matting"]
  },
  "Basic + Hygiene": {
    "included": ["Shampoo", "Conditioning", "Combing/Brushing", "Teeth Cleaning/Mouth spray", "Paw massage", "Nail Clipping", "Ear Cleaning", "Eyes cleaning", "Under Paw Trim", "Sanitary Trim", "Face Haircut"],
    "not_included": ["Body Massage", "Full Body Trimming", "Hair Styling", "Anti-Tick Bath", "De-Matting"]
  },
  "Essential": {
    "included": ["Shampoo", "Conditioning", "Combing/Brushing", "Teeth Cleaning/Mouth spray", "Paw massage", "Nail Clipping", "Ear Cleaning", "Eyes cleaning", "Body Massage", "Full Body Trimming"],
    "not_included": ["Under Paw Trim", "Sanitary Trim", "Face Haircut", "Full Body Trimming", "Hair Styling", "Anti-Tick Bath", "De-Matting"]
  },
  "Advance": {
    "included": ["Shampoo", "Conditioning", "Combing/Brushing", "Teeth Cleaning/Mouth spray", "Paw massage", "Nail Clipping", "Ear Cleaning", "Eyes cleaning","Under Paw Trim","Sanitary Trim","Face Haircut", "Body Massage", "Hair Styling", "Anti-Tick Bath", "De-Matting"],
    "not_included": ["Under Paw Trim", "Sanitary Trim", "Face Haircut"]
  },
  "Haircut": {
    "included": ["Nail Clipping", "Ear Cleaning", "Eyes cleaning", "Full Body Trimming"],
    "not_included": ["Shampoo", "Conditioning", "Combing/Brushing", "Teeth Cleaning/Mouth spray", "Paw massage", "Under Paw Trim", "Sanitary Trim", "Face Haircut", "Body Massage", "Hair Styling", "Anti-Tick Bath", "De-Matting"]
  },
  "Only Bath": {
    "included": ["Shampoo and Conditioning", "Drying", "Combing/Brushing", "Nail Clipping", "Ear Cleaning", "Eyes Cleaning", "Paw Massage"],
    "not_included": ["Teeth Cleaning/Mouth spray", "Under Paw Trim", "Sanitary Trim", "Face Haircut", "Body Massage", "Full Body Trimming", "Hair Styling", "Anti-Tick Bath", "De-Matting"]
  },
  
};

