import React from "react";
import SinglePaw from "../../assets/icons/SinglePaw.svg";
import StarIcon from "../../assets/icons/star.svg";
import RightCheck from "../../assets/icons/rightCheck.svg";
import CrossIcon from "../../assets/icons/crossIcon.svg";
import DropIcon from "../../assets/icons/DropDownIcon.svg";
import { packagesData } from "../Constants/PackageData";
import { TiTick } from "react-icons/ti";

const BookingPlanCard = ({
  plan,
  index,
  isMobile,
  length,
  handleBookNow,
  isModal,
}) => {
  const planName = plan.title.replace(/Dog|Cat|Grooming/g, "").trim();
  const planDescriptions = plan.description.split(/\r\n|\n/);

  const includedServices = packagesData[planName]?.included || [];
  const notIncludedServices = packagesData[planName]?.not_included || [];

  return (
    <div
      key={index}
      className={
        isModal
          ? "col-12 col-md-12 col-lg-6 col-xl-4 my-3"
          : `col-12 col-md-6 col-lg-4 col-xl-4  my-3`
      }
      style={{ position: "relative" }}
    >
      {planName === "Essential" ? (
        <div className="topSeller">
          <span>Best Seller</span>
        </div>
      ) : null}

      {isMobile ? (
        <div key={index} className="palnHeader">
          <div className="palnMainDiv">
            <div className="dropCardIcon">
              <img src={DropIcon} alt="icon" className="me-2" />
            </div>
            <div className="planOffDiv">
              <div>
                <span className="planHeading">
                  {plan.title.replace(/Dog|Cat|Grooming/g, "").trim()}{" "}
                </span>
                {/* <div className="bookingReview">
          <img src={StarIcon} alt="icon" className="me-2" />
          <p className="planReview text-start m-0">
            {" "}
            {plan.rating} ({plan.total_rating} reviews)
          </p>
        </div> */}
              </div>

              <div className="booking-right-Price">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1px",
                  }}
                >
                  <span
                    className=""
                    style={{
                      fontSize: "15px",
                      textDecoration: "line-through",
                      color: "#FFF",
                      paddingLeft: "2px",
                      fontFamily: "Poppins",
                    }}
                  >
                    ₹{plan.price}/-
                  </span>
                  <span
                    className="planPrice"
                    style={{
                      fontSize: "20px",
                      color: "#F7BD40",
                      paddingLeft: "2px",
                    }}
                  >
                    ₹{plan.offer_price}/-
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="card card-body border-0 p-0 mt-3 brb">
            <ul className="planDescList text-lg">
              {planDescriptions.map((item, subIndex) => (
                <li key={subIndex} className="leastItemCard">
                  <img
                    src="https://dm6g3jbka53hp.cloudfront.net/static-images/grooming-home__packages__green-tick-icon.svg"
                    alt="icon"
                    className="me-2"
                    style={{ width: "25px", height: "25px" }}
                  />
                  {item}
                </li>
              ))}
              
              {/* {plan.description.split(/\r\n|\n/).map((item, subIndex) => (
    <li key={subIndex}><img src={RightCheck} alt="icon" className="me-2" style={{ width: "20px" }} /> {item}</li>
  ))} */}
            </ul>
          </div>

          <div
            className="planfooter"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <button
              className="planBookNowBtn"
              onClick={() => handleBookNow(plan)}
            >
              Book Appointment
            </button>
          </div>
        </div>
      ) : (
        // <div key={index} className={`palnHeader ${index === 0 ? "" : "collapsed"}`} data-bs-toggle="collapse"
        //   data-bs-target={`#collapseExample${index}`}
        //   aria-expanded={index === 0 ? "true" : "false"}
        //   aria-controls={`collapseExample${index}`}>
        //   <div className={`palnMainDiv`}>
        //     <div className="dropCardIcon">
        //       <img src={DropIcon} alt="icon" className="me-2" />
        //     </div>
        //     <div className="planOffDiv">
        //       <div>
        //         <span className="planHeading">
        //           {plan.title.replace(/Dog|Cat|Grooming/g, '').trim()}{" "}
        //         </span>
        //         <div className="bookingReview">
        //           <img src={StarIcon} alt="icon" className="me-2" />
        //           <p className="planReview text-start m-0">
        //             {" "}
        //             {plan.rating} ({plan.total_rating} reviews)
        //           </p>
        //         </div>
        //       </div>
        //       <div className="booking-right-Price">
        //         <div style={{ display: 'flex', flexDirection: 'column', gap: '1px' }}>
        //           <span className="" style={{ fontSize: "15px", textDecoration: "line-through", color: "#FFF" }}>₹{plan.price}/-</span>
        //           <span className="planPrice" style={{ fontSize: "20px", color: "#F7BD40" }}>₹{plan.offer_price}/-</span>
        //         </div>
        //       </div>
        //     </div>
        //   </div>

        //   <div className={`collapse ${index === 0 ? "show" : ""}`} id={`collapseExample${index}`}>
        //     <div className="card card-body border-0 p-0 mt-3 brb">
        //       <ul className="planDescList">
        //         {includedServices.map((item, subIndex) => (
        //           <li key={subIndex}>
        //             <img src={RightCheck} alt="icon" className="me-2" style={{ width: "20px" }} /> {item}
        //           </li>
        //         ))}
        //       </ul>
        //     </div>
        //   </div>

        //   <div className="planfooter" style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
        //     <button className="planBookNowBtn" onClick={() => handleBookNow(plan)}>Book Appointment</button>
        //   </div>
        // </div>
        <div
          className="palnMainDiv"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "550px",
          }}
        >
          <div className="planOffDiv">
            {/* <div className="singlePawIcons">
              <img src={SinglePaw} alt="paw" className="me-1 pb-1" />
            </div> */}

            <div>
              <p className="planHeading text-start m-0">
                {plan.title.replace(/Dog|Cat|Grooming/g, "").trim()}
              </p>
              {/* <div className="bookingReview mb-1">
                <img src={StarIcon} alt="icon" className="me-2" />
                <p className="planReview text-start m-0">
                  {plan.rating} ({plan.total_rating} reviews)
                </p>
              </div> */}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "1px",
              }}
            >
              <span
                className=""
                style={{
                  fontSize: "15px",
                  textDecoration: "line-through",
                  color: "#FFFFFF",
                  fontFamily: "Poppins",
                  paddingLeft: "10px",
                }}
              >
                ₹{plan.price}/-
              </span>
              <span
                className="planPrice"
                style={{
                  fontSize: "24px",
                  color: "#F7BD40",
                  paddingLeft: "10px",
                }}
              >
                ₹{plan.offer_price}/-
              </span>
            </div>
          </div>

          <div
            className="card card-body border-0 p-0 mt-3 brb"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <ul className="planDescList">
              {planDescriptions.map((item, subIndex) => (
                <li key={subIndex} className="leastItemCard ">
                  {/* <TiTick className="text-green-500" /> */}
                  <img
                    src="https://dm6g3jbka53hp.cloudfront.net/static-images/grooming-home__packages__green-tick-icon.svg"
                    alt="icon"
                    className="me-2"
                    style={{ width: "25px", height: "25px" }}
                  />
                  {item}
                </li>
              ))}

              {/* {plan.description.split(/\r\n|\n/).map((item, subIndex) => (
                <li key={subIndex}><img src={RightCheck} alt="icon" className="me-2" style={{ width: "20px" }} /> {item}</li>
              ))} */}
            </ul>

            <div
              className="planfooter"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <button
                className="planBookNowBtn"
                onClick={() => handleBookNow(plan)}
              >
                Book Appointment
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingPlanCard;
