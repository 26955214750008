import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../component/swiper.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Navigation, Pagination } from 'swiper/modules';
import { heroSlider } from '../pages/data';

export default function HeroSlider() {
  return <Swiper modules={[Pagination,Navigation]} loop={true} pagination={{
    clickable:true,
    dynamicMainBullets:true,
  }}
  navigation={true}
  className='heroSlider'
  >
    {heroSlider.map((slide,index)=>{
      const{title,subtitle,image,buttonText}=slide;
      return <SwiperSlide className='py-12 lg:py-16' key={index}>
        <div className="container mx-auto text-center lg:text-left">
          <div className='flex flex-col justify-between items-center lg:flex-row'>
            {/* text */}
            <div className='flex-1'>
              <h1 className='text-blue text-[36px] leading-tight lg:text-[45px] lg:leading-[98px] font-extrabold mb-4'>{title}</h1>
              <p className='text-base lg:text-[18px] lg:leading-8 max-w-[540px] mb-8'>{subtitle}</p>
              <button className='btn mb-8'>{buttonText}</button>
            </div>
            {/* image */}
            <div className='flex-1 flex justify-center'>
              <img className='h-96 lg:h-auto' src={image.type}/>
            </div>
          </div>
        </div>
      </SwiperSlide>
    })}
  </Swiper>;
}
