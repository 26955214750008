import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getProductPlans } from '../../../service/api';
import dogIcon from "../../../assets/icons/mdi-dog.svg";
import catIcon from "../../../assets/icons/mdi-cat.svg";
import dogDarkIcon from "../../../assets/icons/mdi-dark-dog.svg";
import catDarkIcon from "../../../assets/icons/mdi-dark-cat.svg";
import StarGreyIcon from "../../../assets/icons/star-grey.svg";
import { ThreeDots } from "react-loader-spinner";
import VetPlanCard from '../../../component/Card/VetPlanCard';
import DogImage from '../../../assets/images/dog-cardi.png';
import CatImage from '../../../assets/images/cat-card.png';

function VetOnCallPackages({ catId, selectedCityId }) {
    const [selectedPet, setSelectedPet] = useState("Dog");
    const [bookingPlans, setBookingPlans] = useState([]);
    const [planLoader, setPlanLoader] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const packagesRef = useRef(null);
    const [selectedCity, setSelectedCity] = useState("");
    const [userAddress, setUserAddress] = useState("");
    const [userPincode, setUserPincode] = useState("");
    const [userAddId, setUserAddId] = useState();
    const [inputDisable, setInputDisable] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [loggedUserData, setLoggedUserData] = useState();

    const navigate = useNavigate();

    const contentMap = {
        "3": { // Online Consultation
            Dog: {
                title: "Dog Consultation Online",
                features: ["Gut Issues", "Skin Problems", "Behavioral Advice & More"],
                image: DogImage,
                buttonText: "Book A Video Dog Consultation"
            },
            Cat: {
                title: "Cat Consultation Online",
                features: ["Gut Issues", "Skin Problems", "Behavioral Advice & More"],
                image: CatImage,
                buttonText: "Book A Video Cat Consultation"
            },
        },
        "12": { // At-Home Consultation
            Dog: {
                title: "Dog Consultation At Home",
                features: ["Expert Health Advice for Your Dog", "Dog Care & Lifestyle Management", "Behavior Correction & More"],
                image: DogImage,
                buttonText: "Book At-Home Dog Consultation"
            },
            Cat: {
                title: "Cat Consultation At Home",
                features: ["Complete Health Checkup for Cats", "Managing Daily Cat Care", "Insights into Cat Behavior & More"],
                image: CatImage,
                buttonText: "Book At-Home Cat Consultation"
            },
        }
    };

    const dogContent = contentMap[catId]?.Dog;
    const catContent = contentMap[catId]?.Cat;

    useEffect(() => {
        const updateWindowDimensions = () => {
            setIsMobile(window.innerWidth < 768);
        };
        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions);
    }, []);

    useEffect(() => {
        fetchPlans();
    }, [selectedCityId, selectedPet]);

    useEffect(() => {
        const loginuser = JSON.parse(localStorage.getItem("loggedin"));
        const loginUserData = JSON.parse(localStorage.getItem("userIdData"));
        if (loginuser) {
          setIsLogin(loginuser);
        }
        if (loginUserData) {
          setLoggedUserData(loginUserData);
        }
      }, []);
    
    

    const fetchPlans = async () => {
        try {
            const bodySend = {
                category_id: catId ?? "1",
                city_id: selectedCityId,
                isMultipleCity: true,
                pet: selectedPet,
            };
            const planData = await getProductPlans(bodySend);
            const sortedPlans = (planData.data ?? []).sort((a, b) => {
                if (a.id == 217 || a.id == 202) return -1;
                if (b.id == 217 || b.id == 202) return 1;
                return 0;
            });

            setBookingPlans(sortedPlans);
            if (planData.data.length !== 0 && planData.success === "1") {
                setPlanLoader(false);
            }
        } catch (error) {
            console.error("Error fetching plans:", error);
        }
    };

    const onPetChange = (pettype) => {
        setSelectedPet(pettype);
        window.scrollTo({ top: packagesRef.current.offsetTop, behavior: 'smooth' });
    };

    const handleBookNow = (plan) => {
        localStorage.setItem("selectedPlan", JSON.stringify(plan));
        // Uncomment the navigation logic as needed
        const naviData = {
          fromPage: "packages",
        };
        if (isLogin) {
          navigate("/mypets", { state: { fromWhich: naviData } });
        } else {
          navigate("/personal-details",{ state: { fromWhich: naviData } });
        }
      };

    return (
        <div>
            <div className="vet-card-container">
                <div className='vet-service-card-container'>
                    {dogContent && (
                        <div className="vet-service-card dog-card">
                            <img src={dogContent.image} alt="Dog" className="vet-card-image" />
                            <div className="vet-card-content">
                                <h2 className="vet-card-title">{dogContent.title}</h2>
                                <ul className="vet-card-text">
                                    {dogContent.features.map((feature, index) => (
                                        <li key={index}>{feature}</li>
                                    ))}
                                </ul>
                                <button className="vet-card-button" onClick={() => onPetChange("Dog")}>
                                    {dogContent?.buttonText}
                                </button>
                            </div>
                        </div>
                    )}

                    {catContent && (
                        <div className="vet-service-card cat-card">
                            <div className="vet-card-content">
                                <h2 className="vet-card-title">{catContent.title}</h2>
                                <ul className="vet-card-text">
                                    {catContent.features.map((feature, index) => (
                                        <li key={index}>{feature}</li>
                                    ))}
                                </ul>
                                <button className="vet-card-button" onClick={() => onPetChange("Cat")}>
                                    {catContent?.buttonText}
                                </button>
                            </div>
                            <img src={catContent.image} alt="Cat" className="vet-card-image" />
                        </div>
                    )}
                </div>
            </div>

            <div className="mainBookingContainer" ref={packagesRef}>
                <div className="bookingPlansWrapper" style={{}}>

                    <div className="cityMain">
                        <div className="cityGroomingWrapper">
                            <div>

                                <h1 className="packageConfirmTitle homePackageTitle mt-3 text-center">
                                    <span style={{ color: "#F29A4C" }}>{selectedCity?.title ?? ""}</span>{" "}
                                    {'Packages'}
                                </h1>
                                <div className="city-botom-txt mb-4">
                                    <img src={StarGreyIcon} alt="star" className="me-1 pb-1" />
                                    <span className="citytext">
                                        Prices may vary according to your City and Pet
                                    </span>
                                </div>
                            </div>
                            <div className="selectPetDiv ">
                                <button
                                    className={
                                        "petBtn " + (selectedPet === "Dog" ? "petBtnActive" : "")
                                    }
                                    onClick={() => {
                                        // setSelectedPet("Dog");
                                        onPetChange("Dog");
                                    }}
                                >
                                    <img
                                        src={selectedPet === "Dog" ? dogIcon : dogDarkIcon}
                                        className="me-2"
                                        alt="Dog"
                                    />
                                    Dogs{" "}
                                </button>
                                <button
                                    className={
                                        "petBtn " + (selectedPet === "Cat" ? "petBtnActive" : "")
                                    }
                                    onClick={() => {
                                        // setSelectedPet("Cat");
                                        onPetChange("Cat");
                                    }}
                                >
                                    <img
                                        src={selectedPet === "Cat" ? catIcon : catDarkIcon}
                                        className="me-2"
                                        alt="Cat"
                                    />
                                    Cats{" "}
                                </button>
                            </div>
                        </div>

                    </div>
                    {planLoader && (
                        <div className="d-flex justify-content-center">
                            <ThreeDots color="#7B5BFB" height={50} width={50} />
                        </div>
                    )}

                    {!planLoader && (
                        <div
                            className="row justify-content-center bookingCardContainer mt-2"
                            style={{ margin: "0 auto" }}
                        >
                            {bookingPlans.map((plan, index) => (
                                <VetPlanCard
                                    key={index}
                                    plan={plan}
                                    index={index}
                                    length={bookingPlans.length}
                                    isMobile={isMobile}
                                    handleBookNow={handleBookNow}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default VetOnCallPackages;
