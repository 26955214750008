import React from "react";
import SinglePaw from "../../assets/icons/SinglePaw.svg";
import StarIcon from "../../assets/icons/star.svg";
import RightCheck from "../../assets/icons/rightCheck.svg";
import CrossIcon from "../../assets/icons/crossIcon.svg";
import DropIcon from "../../assets/icons/DropDownIcon.svg";
import { packagesData } from "../Constants/PackageData";
import { TiTick } from "react-icons/ti";

const VetPlanCard = ({
  plan,
  index,
  isMobile,
  length,
  handleBookNow,
  isModal,
}) => {
  const planName = plan.title;
  const planDescriptions = plan.description.split(/\r\n|\n/);

  const includedServices = packagesData[planName]?.included || [];
  const notIncludedServices = packagesData[planName]?.not_included || [];

  return (
    <div
      key={index}
      className={
        isModal
          ? "col-12 col-md-12 col-lg-6 col-xl-4 my-3"
          : `col-12 col-md-6 col-lg-4 col-xl-4  my-3`
      }
      style={{ position: "relative" }}
    >
      {planName === "Essential" ? (
        <div className="topSeller">
          <span>Best Seller</span>
        </div>
      ) : null}

     
        <div
          className="palnMainDivVet"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "100%",
          }}
        >
          <div className="planOffDivVet">
            <div>
              <p className="planHeadingVet text-start m-0">
                {plan.title}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "1px",
              }}
            >
            
              <span
                className="planPrice"
                style={{
                  fontSize: "24px",
                  color: "#7B5BFB",
                  paddingRight: "10px",
                  fontWeight:"bolder",
                  fontFamily: "Poppins",
                }}
              >
                ₹{plan.offer_price}/-
              </span>
              <span
                className=""
                style={{
                  fontSize: "13px",
                  textDecoration: "line-through",
                  textDecorationColor:"#FB0000",
                  color: "#9E9E9E",
                  fontFamily: "Poppins",
                  
                }}
              >
                ₹{plan.price}/-
              </span>
            </div>
          </div>
          <div
            style={{
              height: "1px",
              backgroundColor: "#D8D8D8", // Light grey color for the line
              margin: "2px 20px", // Adds 40px space on both sides (left and right)
            }}
          /><div className="divider-horizontal-vet" />

          <div
            className="card card-body border-0 p-0 mt-3 brb"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <ul className="planDescListVet">
              {planDescriptions.map((item, subIndex) => (
                <li key={subIndex} className="leastItemCardVet ">
                  {/* <TiTick className="text-green-500" /> */}
                  <img
                    src="https://dm6g3jbka53hp.cloudfront.net/static-images/grooming-home__packages__green-tick-icon.svg"
                    alt="icon"
                    className="me-2"
                    style={{ width: "25px", height: "25px" }}
                  />
                  {item}
                </li>
              ))}

              {/* {plan.description.split(/\r\n|\n/).map((item, subIndex) => (
                <li key={subIndex}><img src={RightCheck} alt="icon" className="me-2" style={{ width: "20px" }} /> {item}</li>
              ))} */}
            </ul>

            <div
              className="planfooter"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <button
                className="planBookNowBtn"
                onClick={() => handleBookNow(plan)}
              >
                Book Appointment
              </button>
            </div>
          </div>
        </div>
      
    </div>
  );
};

export default VetPlanCard;
