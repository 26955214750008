import React from 'react'

const TernsCondition = () => {
  return (
    <div className="container mt-3 pt-5"> {/* Adjusted the margin-top and padding-top */}
      <h5 className="text-center my-4 pv-title">Terms & Condition</h5>
      <div className="row mt-3">
        <div className="col-md-12">
          <div className="rte">
            <div className="spreate">
              <p className="p4">
                <b>Service Agreement : </b> : By booking a grooming service with
                Dearpet, customers agree to provide a safe and accessible
                environment for our groomers to perform their services.
              </p>
              <p className="p4">
                <b>Pet Health and Safety : </b> Customers must disclose any
                health issues, behavioral problems, or special needs their pet
                may have prior to the appointment. Dearpet reserves the right to
                refuse service in cases where a pet's condition may pose a risk
                to the safety of our staff.
              </p>
              <p className="p4">
                <b>Liability Waiver : </b> While utmost care will be taken,
                Dearpet is not liable for any accidents, injuries, or health
                problems that may occur during or post-grooming.
              </p>
              <p className="p4">
                <b>Photographs and Media : </b> Dearpet may take before and
                after photographs of pets for our records and promotional use.
                Customers can opt out of this by notifying us prior to the
                appointment.
              </p>
              <p className="p4">
                <b>Payment Terms : </b> Payment must be completed at the time of
                booking. Prices are subject to change without prior notice.
              </p>
              <p className="p4">
                <b>Privacy Policy: </b> Customer and pet information will be
                confidential and will not be shared with third parties, except
                as required by law.
              </p>
              <p className="p4">
                <b>Amendments to Terms : </b> Dearpet reserves the right to
                amend these terms & conditions without prior notice.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TernsCondition
