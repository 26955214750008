import React, { useEffect, useState } from "react";
import "./style.css";
import CatAndDog from "../../assets/images/Cat and dog-cuate.svg";
import { useNavigate } from "react-router-dom";
import { addPet, getBreedList } from "../../service/api";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import dogIocn from "../../assets/icons/mdi-dog.svg";
import catIocn from "../../assets/icons/mdi-cat.svg";
import dogDarkIocn from "../../assets/icons/mdi-dark-dog.svg";
import catDarkIocn from "../../assets/icons/mdi-dark-cat.svg";

const BreedDropdown = ({ breedOptions, selectedBreed, onBreedChange }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBreedName, setSelectedBreedName] = useState("");
  const [dropdownWidth, setDropdownWidth] = useState(null);

  const filteredBreeds = breedOptions.filter((breed) =>
    breed.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleBreedSelect = (breedId, breedTitle) => {
  
    setSelectedBreedName(breedTitle);
    onBreedChange(breedId);
  };

  return (
    <div className="breed-drop px-3">
      <label htmlFor="breed" className="form-label">
        Pet’s Breed
      </label>
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-basic">
          {selectedBreedName ? selectedBreedName : "Select Breed"}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ maxHeight: "200px", overflowY: "scroll" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {filteredBreeds.map((breed) => (
            <Dropdown.Item
              key={breed.id}
              onClick={() => handleBreedSelect(breed.id, breed.title)}
            >
              {breed.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
        <div className="dropdown-menu-prepend">
          <div className="dropdown-toggle-append" />
        </div>
      </Dropdown>
    </div>
  );
};

const AddPet = () => {
  const navigate = useNavigate();
  const [pet_name, setPet_name] = useState("");
  const [pet_type, setPet_type] = useState("");
  const [breed, setBreed] = useState("");
  const [gender, setGender] = useState("");
  const [pet_size, setPet_size] = useState("");
  const [aggression, setAggression] = useState("");
  const [age, setAge] = useState("");
  const [note, setNote] = useState("");
  const [breedOptions, setBreedOptions] = useState([]);
  const [dob, setDob] = useState("");

  // State variables for error messages
  const [petNameError, setPetNameError] = useState("");
  const [petTypeError, setPetTypeError] = useState("");
  const [breedError, setBreedError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [petSizeError, setPetSizeError] = useState("");
  const [aggressionError, setAggressionError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [noteError, setNoteError] = useState("");

  useEffect(() => {
    const homeData = JSON.parse(localStorage.getItem("homeData"));
    if (homeData) {
      setPet_type(homeData.selectedPet);
    }

    const petData = JSON.parse(localStorage.getItem("petData"));
    if (petData) {
      setPet_name(petData.pet_name);
      setBreed(petData.breed);
      setGender(petData.gender);
      setPet_size(petData.pet_size);
      setAggression(petData.aggression);
      setAge(petData.pet_age);
      setNote(petData.description);
    }

    getBreeds();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      savepet();
    }
  };

  const getBreeds = async () => {
    try {
      const breeds = await getBreedList();
      setBreedOptions(breeds.data);
    } catch (error) {
      console.log("Error on Get BreedList", error);
    }
  };

  const handleBreedChange = (selectedBreed) => {
    setBreed(selectedBreed);
    setBreedError("");
  };

  const validateForm = () => {
    let isValid = true;

    // validate PetName
    if (!pet_name) {
      setPetNameError("Pet Name is required");
      isValid = false;
    } else {
      setPetNameError("");
    }

    // validate PetType
    if (!pet_type) {
      setPetTypeError("Pet Type is required");
      isValid = false;
    } else {
      setPetTypeError("");
    }

    // Validate breed
    if (!breed) {
      setBreedError("Breed is required");
      isValid = false;
    } else {
      setBreedError("");
    }

    // Validate gender
    if (!gender) {
      setGenderError("Gender is required");
      isValid = false;
    } else {
      setGenderError("");
    }

    // Validate pet size
    if (!pet_size) {
      setPetSizeError("Pet size is required");
      isValid = false;
    } else {
      setPetSizeError("");
    }

    // Validate aggression
    if (!aggression) {
      setAggressionError("Aggression level is required");
      isValid = false;
    } else {
      setAggressionError("");
    }

    // Validate age
    if (!age) {
      setAgeError("Age is required");
      isValid = false;
    } else {
      setAgeError("");
    }

    // Validate note length
    // if (note.length > 1000) {
    //   setNoteError("Note cannot exceed 1000 characters");
    //   isValid = false;
    // } else {
    //   setNoteError("");
    // }

    return isValid;
  };

  const savepet = async () => {
    try {
      const bodySend = {
        pet_name: pet_name,
        pet_type: pet_type,
        breed: breed,
        gender: gender,
        pet_size: pet_size,
        aggression: aggression,
        dob: dob,
        description: note,

        name: pet_name,
        allergy: "null",
        disease: "null",
        life_stage : age,
      };
      const planData = await addPet(bodySend);
      if (planData.success === "1") {
        const localPetData = {
          pet_name: pet_name,
          pet_type: pet_type,
          breed: breed,
          gender: gender,
          pet_size: pet_size,
          aggression: aggression,
          dob: dob,
          description: note,
          life_stage : age,
          pet_id: planData.data.id,
        };

        localStorage.setItem("petData", JSON.stringify(localPetData));

        Swal.fire({
          title: "Success",
          text: "Pet Saved Successfully",
          icon: "success",
          showConfirmButton: false,
          confirmButtonText: "OK",
          willClose: (isConfirmed) => {
            if (isConfirmed) {
              // window.scrollTo(0, 0);
              // navigate("/planconfirm");
              navigate("/mypets");
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 500);
            }
          },
          timer: 1000,
          timerProgressBar: true,
        }).then((result) => {});
      }
    } catch (error) {
      console.error("Error in Save pet:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const generateRandomDate = (selectesAge) => {
    let randomDate;
    if (selectesAge === "Puppy") {
      const currentDate = new Date();
      const threeMonthsAgo = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 3,
        currentDate.getDate()
      );

      randomDate = formatDate(threeMonthsAgo);
    }
    if (selectesAge === "Adult") {
      const currentDate = new Date();
      const threeYearsAgo = new Date(
        currentDate.getFullYear() - 3,
        currentDate.getMonth(),
        currentDate.getDate()
      );
      randomDate = formatDate(threeYearsAgo);
    }
    if (selectesAge === "Senior") {
      const today = new Date();
      const eightYearsAgo = new Date(today);
      eightYearsAgo.setFullYear(today.getFullYear() - 8);
      randomDate = formatDate(eightYearsAgo);
    }

   
    setDob(randomDate);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="container my-5">
      <div className="row justify-content-center align-items-center px-4">
        <div className="col-md-6 d-none d-lg-block">
          <img src={CatAndDog} alt="img" />
        </div>
        <div className="col-md-6 text-start formRightDiv">
          <h5 className="text-center mt-3">Add Pet Details</h5>
          {/* <form className="my-4"> */}
          <div className="mb-3 px-3 top-typeDiv">
            <label
              htmlFor="pet_type"
              className="form-label text-center whatTypeTitle"
            >
              {/* What type of pet? */}
              Selected Pet Type
            </label>
            <div className="selectPetDivAdd">
              <button
                className={
                  "petBtn " + (pet_type === "Dog" ? "petBtnActive" : "")
                }
                onClick={() => {
                  setPet_type("Dog");
                  setPetTypeError("");
                }}
              >
                <img
                  src={pet_type === "Dog" ? dogIocn : dogDarkIocn}
                  className="me-2"
                  alt="Dog"
                />
                Dogs{" "}
              </button>
              <button
                className={
                  "petBtn " + (pet_type === "Cat" ? "petBtnActive" : "")
                }
                onClick={() => {
                  setPet_type("Cat");
                  setPetTypeError("");
                }}
              >
                <img
                  src={pet_type === "Cat" ? catIocn : catDarkIocn}
                  className="me-2"
                  alt="Cat"
                />
                Cats{" "}
              </button>
            </div>
            {/* <div className="typePet">
              <div className="form-check petT">
                <input
                  type="radio"
                  // className="btn-check"
                  className={`btn-check ${petTypeError ? "is-invalid" : ""}`}
                  name="pet_type"
                  id="success-outlined"
                  value="Dog"
                  checked={pet_type === "Dog"}
                  onChange={() => {
                    setPet_type("Dog");
                    setPetTypeError("");
                  }}
                />
                <label className="btn petTypeInput" htmlFor="success-outlined">
                  Dog
                </label>
              </div>
              <div className="form-check petT">
                <input
                  type="radio"
                  className={`btn-check ${petTypeError ? "is-invalid" : ""}`}
                  name="pet_type"
                  id="pet_type2"
                  value="Cat"
                  checked={pet_type === "Cat"}
                  onChange={() => {
                    setPet_type("Cat");
                    setPetTypeError("");
                  }}
                />
                <label className="btn  petTypeInput" htmlFor="pet_type2">
                  Cat
                </label>
              </div>
            </div> */}
            {petTypeError && (
              <div className="invalid-feedback">{petTypeError} </div>
            )}
          </div>
          {/* <div className="mb-3 px-3 top-typeDiv">
            <label
              htmlFor="pet_type"
              className="form-label text-center whatTypeTitle"
            >
              Selected Pet Type
            </label>
            <div className="typePet">
              <div className="form-check petT">
                <input
                  type="radio"
                  className={`btn-check ${petTypeError ? "is-invalid" : ""}`}
                  name="pet_type"
                  id="success-outlined"
                  value={pet_type}
                  checked={pet_type}
                  onChange={() => {
                    setPet_type(pet_type);
                    setPetTypeError("");
                  }}
                  disabled
                />
                <label className="btn petTypeInput" htmlFor="success-outlined">
                  {pet_type}
                </label>
              </div>
            </div>
            {petTypeError && (
              <div className="invalid-feedback">{petTypeError} </div>
            )}
          </div> */}
          <div className="mb-3 px-3 ">
            <label htmlFor="pet_name" className="form-label">
              Pet’s name
            </label>
            <input
              type="text"
              className={`form-control borderradius ${
                petNameError ? "is-invalid" : ""
              }`}
              id="pet_name"
              name="pet_name"
              placeholder="Please enter your pet’s name..."
              value={pet_name}
              onChange={(e) => {
                setPet_name(e.target.value);
                setPetNameError("");
              }}
            />
            {petNameError && (
              <div className="invalid-feedback">{petNameError} </div>
            )}
          </div>

          <BreedDropdown
            breedOptions={breedOptions}
            selectedBreed={breed}
            onBreedChange={handleBreedChange}
          />
          <div className="mb-3 px-3">
            {breedError && <div className="invalid-feedback">{breedError}</div>}
          </div>

          <div className="mb-3 px-3">
            <label htmlFor="gender" className="form-label">
              Gender of your pet?
            </label>
            <div className="form-row genderDiv">
              <div className="form-check genderbox">
                <input
                  type="radio"
                  className={`btn-check ${genderError ? "is-invalid" : ""}`}
                  name="gender"
                  id="gender1"
                  value="Female"
                  checked={gender === "Female"}
                  onChange={() => {
                    setGender("Female");
                    setGenderError("");
                  }}
                />
                <label className="btn petAgeInput" htmlFor="gender1">
                  Female
                </label>
              </div>

              <div className="form-check genderbox ms-3">
                <input
                  type="radio"
                  className={`btn-check ${genderError ? "is-invalid" : ""}`}
                  name="gender"
                  id="gender2"
                  value="Male"
                  checked={gender === "Male"}
                  onChange={() => {
                    setGender("Male");
                    setGenderError("");
                  }}
                />
                <label className="btn  petAgeInput" htmlFor="gender2">
                  Male
                </label>
              </div>
            </div>
            {genderError && (
              <div className="invalid-feedback">{genderError}</div>
            )}
          </div>
          <div className="mb-3 px-3">
            <label htmlFor="petSize" className="form-label">
              What size of your pet?
            </label>
            <div className="form-row">
              <div className="form-check">
                <input
                  className={`form-check-input ${
                    petSizeError ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="pet_size"
                  id="size1"
                  value="1"
                  checked={pet_size === "1"}
                  onChange={() => {
                    setPet_size("1");
                    setPetSizeError("");
                  }}
                />
                <label className="form-check-label radioLabel" htmlFor="size1">
                  {" "}
                  Small{" "}
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${
                    petSizeError ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="pet_size"
                  id="size2"
                  value="3"
                  checked={pet_size === "3"}
                  onChange={() => {
                    setPet_size("3");
                    setPetSizeError("");
                  }}
                />
                <label className="form-check-label radioLabel" htmlFor="size2">
                  {" "}
                  Medium{" "}
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${
                    petSizeError ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="pet_size"
                  id="size3"
                  value="7"
                  checked={pet_size === "7"}
                  onChange={() => {
                    setPet_size("7");
                    setPetSizeError("");
                  }}
                />
                <label className="form-check-label radioLabel" htmlFor="size3">
                  Large
                </label>
              </div>
            </div>
            {petSizeError && (
              <div className="invalid-feedback">{petSizeError}</div>
            )}
          </div>
          <div className="mb-3 px-3">
            <label htmlFor="petAggresive" className="form-label">
              How aggressive is your pet?{" "}
            </label>
            <div className="form-row">
              <div className="form-check">
                <input
                  className={`form-check-input ${
                    aggressionError ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="aggression"
                  id="aggressive1"
                  value="18"
                  checked={aggression === "18"}
                  onChange={() => {
                    setAggression("18");
                    setAggressionError("");
                  }}
                />
                <label
                  className="form-check-label radioLabel"
                  htmlFor="aggressive1"
                >
                  {" "}
                  Low{" "}
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${
                    aggressionError ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="aggression"
                  id="aggressive2"
                  value="17"
                  checked={aggression === "17"}
                  onChange={() => {
                    setAggression("17");
                    setAggressionError("");
                  }}
                />
                <label
                  className="form-check-label radioLabel"
                  htmlFor="aggressive2"
                >
                  {" "}
                  Normal{" "}
                </label>
              </div>
              <div className="form-check">
                <input
                  className={`form-check-input ${
                    aggressionError ? "is-invalid" : ""
                  }`}
                  type="radio"
                  name="aggression"
                  id="aggressive3"
                  value="16"
                  checked={aggression === "16"}
                  onChange={() => {
                    setAggression("16");
                    setAggressionError("");
                  }}
                />
                <label
                  className="form-check-label radioLabel"
                  htmlFor="aggressive3"
                >
                  High
                </label>
              </div>
            </div>
            {aggressionError && (
              <div className="invalid-feedback">{aggressionError}</div>
            )}
          </div>
          <div className="mb-3 px-3">
            <label htmlFor="petOld" className="form-label">
              How old is your pet?
            </label>
            <div className="form-colum">
              <div className="form-check">
                <input
                  type="radio"
                  className={`btn-check ${ageError ? "is-invalid" : ""}`}
                  name="age"
                  id="petAge1"
                  value="Puppy"
                  checked={age === "Puppy"}
                  onChange={() => {
                    setAge("Puppy");
                    setAgeError("");
                    generateRandomDate("Puppy");
                  }}
                />
                <label className="btn petAgeInput" htmlFor="petAge1">
                  Puppy ( Upto 3 months)
                </label>
              </div>

              <div className="form-check">
                <input
                  type="radio"
                  className={`btn-check ${ageError ? "is-invalid" : ""}`}
                  name="age"
                  id="petAge2"
                  value="Adult"
                  checked={age === "Adult"}
                  onChange={() => {
                    setAge("Adult");
                    setAgeError("");
                    generateRandomDate("Adult");
                  }}
                />
                <label className="btn  petAgeInput" htmlFor="petAge2">
                  Adult ( less than 8 years)
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className={`btn-check ${ageError ? "is-invalid" : ""}`}
                  name="age"
                  id="petAge3"
                  value="Senior"
                  checked={age === "Senior"}
                  onChange={() => {
                    setAge("Senior");
                    setAgeError("");
                    generateRandomDate("Senior");
                  }}
                />
                <label className="btn  petAgeInput" htmlFor="petAge3">
                  Senior more than 8 years
                </label>
              </div>
              {ageError && <div className="invalid-feedback">{ageError}</div>}
            </div>
          </div>
          <div className="mb-3 px-3 ">
            <textarea
              className={`form-control ${noteError && "is-invalid"}`}
              rows="3"
              name="note"
              placeholder="Additional Note for Groomer (optional)"
              onChange={(e) => {
                setNote(e.target.value);
                setNoteError("");
              }}
              value={note}
            ></textarea>
            {noteError && <div className="invalid-feedback">{noteError}</div>}
          </div>
          <div className="mb-3 px-3 text-center">
            <button
              type="submit"
              className="btn nextBtn text-center"
              onClick={handleSubmit}
            >
              Next
            </button>
          </div>
          {/* </form> */}
        </div>
      </div>
    </div>
  );
};

export default AddPet;
