import React, { useEffect, useState } from "react";
import "./style.css";
import LeftImg from "../../assets/images/Mobile login-bro.svg";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { loginWithOtp, sendOtp } from "../../service/api";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import LogoIcon from "../../assets/icons/logo.svg";
import { setAuthToken } from "./../../service/apiService";

const Login = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const fromRegister = location.state?.fromRegister || "";

  const [otpState, setOtpState] = useState(false);
  const [otp, setOtp] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [mobile, setMobile] = useState("");
  const [nationalNumber, setNationalNumber] = useState();
  const [phoneTouched, setPhoneTouched] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [verified, setVerified] = useState(false);

  const [otpSendSts, setOtpSendSts] = useState(false);

  const [otpBtnState, setOtpBtnState] = useState(false);
  const [authTokens, setAuthTokens] = useState();

  useEffect(() => {
    localStorage.setItem("addPageCall", JSON.stringify(fromRegister));
  }, [fromRegister]);

  // useEffect(() => {
  //   if (phoneTouched) {
  //     if (!nationalNumber) {
  //       setPhoneError("Phone number is required.");
  //       setOtpBtnState(true);
  //     } else if (nationalNumber.length !== 10) {
  //       setPhoneError("Invalid Phone number");
  //       setOtpBtnState(true);
  //     } else {
  //       setPhoneError("");
  //       setOtpBtnState(false);
  //     }
  //   }
  // }, [nationalNumber, phoneTouched]);

  const handlePhoneChange = (value, country, e, formattedValue) => {
    const countryCode = country.dialCode;
    const phoneNumber = value.slice(countryCode.length);
    setMobile(value);

    setCountryCode(countryCode);
    setNationalNumber(phoneNumber);
    setPhoneError("");
    setPhoneTouched(true);
  };

  const validateFields = () => {
    let isValid = true;
    // Validate Phone
    if (!nationalNumber) {
      setPhoneError("Phone number is required.");
      isValid = false;
    } else if (nationalNumber.length !== 10) {
      setPhoneError("Invalid Phone number");
      isValid = false;
    } else {
      setPhoneError("");
    }
    return isValid;
  };

  const onSendOtp = async () => {
    if (validateFields()) {
      try {
        const bodySend = {
          username: nationalNumber,
        };
        const userData = await sendOtp(bodySend);
        if (userData.success === "1") {
          setOtpState(true);
          setOtpSendSts(true);
        }
      } catch (error) {
        console.error("Error in send Otp:", error);
      }
    }
  };

  useEffect(() => {
    const LoginApi = async () => {
      try {
        const bodySend = {
          otp: otp,
          username: nationalNumber,
          device_type: "Web",
        };

        const loginData = await loginWithOtp(bodySend);

        setAuthToken(loginData.data.token);
        setAuthTokens(loginData.data.token);

        if (loginData.status === "111") {
          Swal.fire({
            text: loginData.message,
            icon: "warning",
            showConfirmButton: true,
            confirmButtonText: "OK",
          }).then((result) => { });
        } else if (loginData.success === "0") {
          setOtpError("Invalid Otp");
        } else if (loginData.status === "200" && loginData.success === "1") {
          setVerified(true);
          setOtpBtnState(false);
          setOtpState(false);
          localStorage.setItem("authToken", loginData.data.token);
          localStorage.setItem("userIdData", JSON.stringify(loginData.data));
          // localStorage.setItem("userIdData", JSON.stringify(existingData));

          // const existingData = JSON.parse(localStorage.getItem("userIdData"));
          // if (existingData) {
          //   existingData.verified = true;
          //   localStorage.setItem("userIdData", JSON.stringify(existingData));
          // }
        } else {
        }
      } catch (error) {
        console.log("error on login with otp", error);
      }
    };

    if (otp.length === 6) {
      LoginApi();
    }
  }, [otp, nationalNumber]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.setItem("loggedin", "true");
    Swal.fire({
      title: "Success",
      text: "User Login Successfully",
      icon: "success",
      showConfirmButton: false,
      confirmButtonText: "OK",
      willClose: (isConfirmed) => {
        if (isConfirmed) {
          if (fromRegister === "register") {
            window.scrollTo(0, 0);
            window.location.href = "/mypets";
          } else {
            window.scrollTo(0, 0);
            window.location.href = "/";
          }
        }
      },
      timer: 1000,
      timerProgressBar: true,
    }).then((result) => { });
  };

  return (
    <div className="container mt-6">
      <div className="row justify-content-center align-items-center px-4">
        <div className="col-md-6 d-none d-lg-block">
          <img src={LeftImg} alt="img"  />
        </div>
        
        <div className="col-md-6 text-center">
          <img src={LogoIcon} alt="Logo" style={{marginLeft:"auto", marginRight:"auto"}} />
          <h5 className="text-center registerTitle">Login</h5>
          <p className="text-center">Welcome</p>
          <form className="my-4">
            <div className="mb-3 inputWrapper">
              <label className="inputFormControl">
                <PhoneInput
                  country={"in"}
                  value={mobile}
                  onChange={handlePhoneChange}
                  inputMode="tel"
                  buttonStyle={{ border: "none", backgroundColor: "transparent", }}
                />

                {verified ? (
                  <p className="verifiedTextPhone">Verified</p>
                ) : otpSendSts ? (
                  <p className="verifiedTextPhone otp-sent">Otp Sent</p>
                ) : (
                  <input
                    type="button"
                    className={"sendOtpBtnPhone " + (otpBtnState ? "dis" : "")}
                    value="Send OTP"
                    onClick={() => {
                      // setOtpState(true);
                      onSendOtp();
                    }}
                  />
                )}
              </label>
              {phoneError && (
                <div className="invalid-feedback">{phoneError}</div>
              )}
            </div>
            {otpState ? (
              <div className="mb-3 px-3 otp-wrapper">
                <label className="otpLabel">Enter OTP Here</label>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} inputMode="tel" />}
                />
                {otpError && <div className="invalid-feedback">{otpError}</div>}
              </div>
            ) : (
              <></>
            )}

            <div className="mb-3 text-center">
              <button
                type="submit"
                className={
                  "btn nextBtn text-center " + (verified ? "" : "disabled")
                }
                onClick={handleSubmit}
              >
                Login
              </button>
              <p className="bottomLink text-center mt-3">
              DON'T HAVE AN ACCOUNT?{" "}
                <a className="loginLink" href="/register">
                SIGN UP.
                </a>
              </p>
          {/* <span className="dontHaveAccount">DON'T HAVE AN ACCOUNT? <span className="signUpText">SIGN UP.</span></span> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
