import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { activeAccount, addToCartProducts, getCities, saveAddress, sendOtp, userDetails } from '../../service/api';
import Swal from 'sweetalert2';
import LeftImg from "../../assets/images/Personal site-amico.svg";
import { setAuthToken } from '../../service/apiService';
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";
import "sweetalert2/dist/sweetalert2.min.css";
import OtpInput from "react-otp-input";
import ReactGoogleAutocomplete from 'react-google-autocomplete';

function PersonalDetailsProduct() {
  const navigate = useNavigate();
  const autocompleteRef = useRef(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const currentDate = new Date();
  const today = new Date();
  const [date, setDate] = useState(currentDate.toISOString().split("T")[0]);

  // -----------------------------------------
  const [utmParams, setUtmParams] = useState({});

  useEffect(() => {
    // Retrieve UTM parameters from localStorage
    const savedParams = JSON.parse(localStorage.getItem('utmParams'));
    if (savedParams) {
      setUtmParams(savedParams);
    }
  }, []);

  // -----------------------------------------

  const [otpState, setOtpState] = useState(false);
  const [otp, setOtp] = useState("");


  const [fname, setFname] = useState("");

  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [mobile, setMobile] = useState("");
  const [verified, setVerified] = useState(false);
  const [nationalNumber, setNationalNumber] = useState();

  const [allUData, setAllUData] = useState([]);

  const [fnameError, setFNameError] = useState("");

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [otpError, setOtpError] = useState("");
  const [phoneTouched, setPhoneTouched] = useState(false);

  const [otpBtnState, setOtpBtnState] = useState(false);
  const [loginApiCall, setLoginApiCall] = useState();
  const [otpSendSts, setOtpSendSts] = useState(false);

  const [authTokens, setAuthTokens] = useState();
  const [googleAutocomplete, setGoogleAutocomplete] = useState();

  const [pet_name, setPet_name] = useState("");
  const [pet_type, setPet_type] = useState("");
  const [breed, setBreed] = useState("");
  const [breedName, setBreedName] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [breedOptions, setBreedOptions] = useState([]);
  const [dob, setDob] = useState("");
  const [petNameError, setPetNameError] = useState("");
  const [petTypeError, setPetTypeError] = useState("");
  const [breedError, setBreedError] = useState("");
  const [aggressionError, setAggressionError] = useState("");
  const [aggression, setAggression] = useState("");

  const [addressError, setAddressError] = useState("");
  const [houseNoError, setHouseNoError] = useState("");
  const [localityError, setLocalityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [latitude, setLatitude] = useState('23.022505');
  const [longitude, setLongitude] = useState('72.5713621');

  const [inputSearchValue, setInputSearchValue] = useState();
  const [address, setAddress] = useState("");
  const [locality, setLocality] = useState("");
  const [houseNo, setHouseNo] = useState('');
  const [pincode, setPincode] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCityId, setSelectedCityId] = useState();
  const [selectedStateId, setSelectedStateId] = useState();


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  }, [])

  useEffect(() => {
    fetchCities();
  }, []);

  const fetchCities = async () => {
    try {
      const cityData = await getCities();

      setCities(cityData.data);
      if (!selectedCity && !selectedCityId) {
        console.log(selectedCity);
        console.log(selectedCityId);
        const defaultCity = (cityData.data || []).find(city => city.id == 3);
        console.log(defaultCity)
        if (defaultCity) {
          setSelectedCityId(defaultCity?.id);
          setSelectedCity(defaultCity?.title);
        }
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  useEffect(() => {
    const homeData = JSON.parse(localStorage.getItem("homeData"));
    if (homeData) {
      setPet_type(homeData.selectedPet);
    }

    const petData = JSON.parse(localStorage.getItem("petData"));
    if (petData) {
      setPet_name(petData.pet_name);
      setBreed(petData.breed);

    }

  }, []);

  useEffect(() => {
    if (googleAutocomplete) {



      const selectedCityMap = cities.find((city) =>
        googleAutocomplete.address_components.some((component) =>
          component.long_name.toLowerCase().includes(city?.title.toLowerCase())
        )
      );

      const pincodeComponent = googleAutocomplete.address_components.find((component) =>
        component.types.includes("postal_code")
      );
      const pincode = pincodeComponent ? pincodeComponent.long_name : "";

      const sublocalityComponents = googleAutocomplete.address_components.filter((component) =>
        component.types.includes("sublocality")
      );

      const sublocalities = sublocalityComponents.map((component) => component.long_name);

      const combinedSublocalities = sublocalities.join(', ');





      const filteredComponents = googleAutocomplete.address_components.filter((component) =>
        !component.types.some((type) =>
          ["plus_code", "locality", "country", "postal_code"].includes(type)
        )
      );

      const filteredAddress = filteredComponents.map((component) => component.long_name).join(", ");

      setAddress(filteredAddress);
      setAddressError("");

      if (selectedCityMap) {

        setSelectedCity(selectedCityMap?.title);
        setSelectedCityId(selectedCityMap?.id);
      }
      if (pincode) {
        setPincode(pincode);
        setPincodeError("");
      }
      if (combinedSublocalities) {
        setLocality(combinedSublocalities);
      }
    }
  }, [cities, googleAutocomplete]);

  useEffect(() => {
    const uData = JSON.parse(localStorage.getItem("userIdData"));
    const homeCity = JSON.parse(localStorage.getItem("homeData"));



    if (uData) {
      setAllUData(uData);
      const mynum = uData.country_code + uData.phone_number;
      setMobile(mynum);
      setNationalNumber(uData.phone_number);
      setFname(uData.fname);
      // setLname(uData.lname);
      setEmail(uData.email);
      // setVerified(uData.verified);
    }


  }, []);

  useEffect(() => {
    if (phoneTouched) {
      if (!nationalNumber) {
        setPhoneError("Phone number is required.");
        setOtpBtnState(true);
      } else if (nationalNumber.length !== 10) {
        setPhoneError("Invalid Phone number");
        setOtpBtnState(true);
      } else {
        setPhoneError("");
        setOtpBtnState(false);
      }
    }
    if (allUData.phone_number === nationalNumber) {
      setVerified(allUData.verified);
    } else {
      setVerified(false);
    }
  }, [nationalNumber, allUData, phoneTouched]);


  useEffect(() => {
    const uData = JSON.parse(localStorage.getItem("userIdData"));
    const homeCity = JSON.parse(localStorage.getItem("homeData"));
    if (uData) {
      setAllUData(uData);
      const mynum = uData.country_code + uData.phone_number;
      setMobile(mynum);
      setNationalNumber(uData.phone_number);
      setFname(uData.fname);
      // setLname(uData.lname);
      setEmail(uData.email);
      setCountryCode(uData.country_code);

      // setSelectedState(uData.state);
      // setSelectedCity(uData.city);
      // setAddress(uData.address);
      // setPincode(uData.pincode);
      // setSelectedCityId(uData.selectedCityId);
      // setSelectedStateId(uData.selectedStateId);
      // setVerified(uData.verified);
    }

    if (homeCity) {
      setSelectedCity(homeCity.selectedCity?.title);
      setSelectedCityId(homeCity.selectedCityId);
      setSelectedStateId(homeCity.selectedCity?.state_id);
    }
  }, []);


  const handlePhoneChange = (value, country, e, formattedValue) => {
    const countryCode = country.dialCode;
    const phoneNumber = value.slice(countryCode.length);

    setMobile(value);

    setCountryCode(countryCode);
    setNationalNumber(phoneNumber);
    setPhoneError("");
    // setMobile(val);
    // const phoneNumber = parsePhoneNumber(val);
    // const nationalNumber = phoneNumber ? phoneNumber.nationalNumber : "";
    // console.log("phn", phoneNumber);
    // console.log("nationalNumber", nationalNumber);
    // setNationalNumber(nationalNumber);
    // const countryCode = phoneNumber ? phoneNumber.countryCallingCode : "+91";
    // console.log("countrycode ", countryCode);
    // setCountryCode(countryCode);
    setPhoneTouched(true);
  };

  // -----------------

  // this was the otp function before utm data fetching
  // const onSendOtp = async () => {
  //   if (validateFields()) {
  //     try {
  //       const bodySend = {
  //         user_type: "Customer",
  //         name: fname,
  //         email: email,
  //         country_code: "+" + countryCode,
  //         phone_number: nationalNumber,
  //         password: "123456",
  //         gender: "Male",
  //         dob: "1993-12-06",
  //         otp: otp,

  //         // ------------utm work
  //       };
  //       const userData = await userDetails(bodySend);

  //       setLoginApiCall(userData.success);

  //       if (userData.success === "1") {
  //         try {
  //           const bodySend = {
  //             username: nationalNumber,
  //           };
  //           const userData = await sendOtp(bodySend);
  //           if (userData.success === "1") {
  //             setOtpState(true);
  //             setOtpSendSts(true);
  //           }
  //         } catch (error) {
  //           console.error("Error in send Otp:", error);
  //         }

  //         const combinedData = {
  //           ...bodySend,
  //           ...userData.data,
  //           verified: verified,
  //           fname: fname,
  //         };
  //         localStorage.setItem("userIdData", JSON.stringify(combinedData));
  //       } else if (userData.status === "201") {
  //         if (userData.message === "Phone Number Already Exist") {
  //           const bodySend = {
  //             username: nationalNumber,
  //           };
  //           const userData = await sendOtp(bodySend);
  //           if (userData.success === "1") {
  //             setOtpState(true);
  //             setOtpSendSts(true);
  //           }
  //           const combinedData = {
  //             ...bodySend,
  //             ...userData.data,
  //             verified: verified,
  //             fname: fname,
  //             email: email,
  //             country_code: "+" + countryCode,
  //           };
  //           localStorage.setItem("userIdData", JSON.stringify(combinedData));

  //         } else {
  //           Swal.fire({
  //             title: "",
  //             text: userData.message,
  //             icon: "error",
  //           });
  //         }

  //       }
  //     } catch (error) {
  //       console.error("Error in Save user:", error);
  //     }
  //   }
  // };
  // end's here

  // this is the new function of otp which the fetching the data from the utp..

  const onSendOtp = async () => {
    if (validateFields()) {
      try {
        const utm_id = localStorage.getItem('utm_id');
        const utmSource = localStorage.getItem('utm_source');
        const utmMedium = localStorage.getItem('utm_medium');
        const utmCampaign = localStorage.getItem('utm_campaign');
        const utmContent = localStorage.getItem('utm_content');
        const utmTerm = localStorage.getItem('utm_term');

        const bodySend = {
          user_type: "Customer",
          name: fname,
          email: email,
          country_code: "+" + countryCode,
          phone_number: nationalNumber,
          password: "123456",
          gender: "Male",
          dob: "1993-12-06",
          otp: otp,

          // UTM parameters
          utm_id: utm_id,
          utm_source: utmSource,
          utm_medium: utmMedium,
          utm_campaign: utmCampaign,
          utm_content: utmContent,
          utm_term: utmTerm
        };

        const userData = await userDetails(bodySend);
        setLoginApiCall(userData.success);

        if (userData.success === "1") {
          try {
            const bodySendOtp = {
              username: nationalNumber,
            };
            const otpData = await sendOtp(bodySendOtp);
            if (otpData.success === "1") {
              setOtpState(true);
              setOtpSendSts(true);
            }
          } catch (error) {
            console.error("Error in send Otp:", error);
          }

          const combinedData = {
            ...bodySend,
            ...userData.data,
            verified: verified,
            fname: fname,
          };
          localStorage.setItem("userIdData", JSON.stringify(combinedData));
        } else if (userData.status === "201") {
          if (userData.message === "Phone Number Already Exist") {
            const bodySendOtp = {
              username: nationalNumber,
            };
            const otpData = await sendOtp(bodySendOtp);
            if (otpData.success === "1") {
              setOtpState(true);
              setOtpSendSts(true);
            }
            const combinedData = {
              ...bodySend,
              ...otpData.data,
              verified: verified,
              fname: fname,
              email: email,
              country_code: "+" + countryCode,
            };
            localStorage.setItem("userIdData", JSON.stringify(combinedData));
          } else {
            Swal.fire({
              title: "",
              text: userData.message,
              icon: "error",
            });
          }
        }
      } catch (error) {
        console.error("Error in Save user:", error);
      }
    }
  };

  // -------------------------------------------------------- end here's

  useEffect(() => {
    const verifyOtpandActive = async () => {
      try {
        const bodySend = {
          otp: otp,
          username: nationalNumber,
        };

        const verifyData = await activeAccount(bodySend);

        setAuthToken(verifyData.data.token);
        setAuthTokens(verifyData.data.token);
        localStorage.setItem("authToken", verifyData.data.token);
        if (verifyData.success === "0") {
          setOtpError("Invalid Otp");
        }

        if (verifyData.status === "200") {
          setOtpError("");
          setVerified(true);
          setOtpState(false);
          const existingData = JSON.parse(localStorage.getItem("userIdData"));
          if (existingData) {
            existingData.verified = true;
            localStorage.setItem("userIdData", JSON.stringify(existingData));
          }
        }
      } catch (error) {
        console.error("Error in verifying OTP:", error);
      }
    };


    if (otp.length === 6) {
      // if(loginApiCall === '0'){
      //   LoginApi();
      // }
      // else{
      //   verifyOtpandActive();
      // }
      verifyOtpandActive();
    }
  }, [otp, nationalNumber]);

  const validateFields = () => {
    let isValid = true;
    // Validate Name
    if (!fname) {
      setFNameError(" Name is required.");
      isValid = false;
    } else if (fname.length < 2 || fname.length > 99) {
      setFNameError("Name must be between 3 and 99 characters.");
      isValid = false;
    } else {
      setFNameError("");
    }

    if (!email) {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError("Invalid email address.");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Validate Phone
    if (!nationalNumber) {
      setPhoneError("Phone number is required.");
      isValid = false;
    } else {
      setPhoneError("");
    }

    if (!houseNo) {
      setHouseNoError("House No. is required.");
      isValid = false;
    } else if (houseNo.length < 1 || houseNo.length > 1000) {
      setHouseNoError("House No. must be between 1 and 1000 characters.");
      isValid = false;
    } else {
      setHouseNoError("");
    }


    // Validate Address
    if (!address) {
      setAddressError("Address is required.");
      isValid = false;
    } else if (address.length < 3 || address.length > 1000) {
      setAddressError("Address must be between 3 and 1000 characters.");
      isValid = false;
    } else {
      setAddressError("");
    }


    if (!locality) {
      setLocalityError("locality is required.");
      isValid = false;
    } else if (locality.length < 3 || locality.length > 1000) {
      setLocalityError("Locality must be between 3 and 1000 characters.");
      isValid = false;
    } else {
      setLocalityError("");
    }

    // Validate State
    // if (!selectedStateId) {
    //   setStateError("State is required.");
    //   isValid = false;
    // } else {
    //   setStateError("");
    // }

    // Validate City
    if (!selectedCityId) {
      setCityError("City is required.");
      isValid = false;
    } else {
      setCityError("");
    }

    // Validate Pincode
    if (!pincode) {
      setPincodeError("Pincode is required.");
      isValid = false;
    } else if (!/^\d+$/.test(pincode)) {
      setPincodeError("Pincode must contain only digits.");
      isValid = false;
    } else if (pincode.length != 6) {
      setPincodeError("Pincode must be 6 digits.");
      isValid = false;
    }  else {
      const city = cities.find(city => city.id == selectedCityId);
      if (city) {
          const regex = new RegExp(city.pincode_prefix);
          if (!regex.test(pincode)) {
              setPincodeError(`Invalid Pincode for ${city?.title}. Please enter a valid pincode.`);
              isValid = false;
          } else {
              setPincodeError(""); // Clear error if valid
          }
      } else {
          setPincodeError("Invalid city selection.");
          isValid = false;
      }
  }

    return isValid;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if address is already added
    const existingAddress = localStorage.getItem("addressSubmitted");

    if (existingAddress) {
        // If address is already added, directly proceed to add to cart
        await addToCartDirectly();
        return;
    }

    // Combine house number, locality, and address
    let completeAddress = houseNo + ', ' + locality + ', ' + address;
    if (authTokens) {
        try {
            const bodySend = {
                name: fname,
                phone_number: nationalNumber,
                state_id: selectedStateId,
                city_id: selectedCityId,
                address_type: "other",
                address: completeAddress,
                pincode: pincode,
                latitude: latitude,
                longitude: longitude,
            };

            // Save address data
            const saveAddressData = await saveAddress(bodySend);

            if (saveAddressData.status === "200") {
                const existingData = JSON.parse(localStorage.getItem("userIdData"));

                if (existingData) {
                    existingData.state = selectedState;
                    existingData.city = selectedCity;
                    existingData.address = address;
                    existingData.pincode = pincode;
                    existingData.selectedCityId = selectedCityId;
                    existingData.selectedStateId = selectedStateId;
                    localStorage.setItem("userIdData", JSON.stringify(existingData));
                }
                
                // Mark the address as saved
                localStorage.setItem("addressSubmitted", "true");
                localStorage.setItem("loggedin", "true");

                // Proceed to add items to cart
                await addToCartDirectly();
            }
        } catch (error) {
            console.error("Error saving address:", error);
        }
    }
};

// Separate function to handle adding items to cart
const addToCartDirectly = async () => {
    const storedCartBodyItem = localStorage.getItem("cartBodyItem");

    if (storedCartBodyItem) {
        const bodySend = JSON.parse(storedCartBodyItem);

        try {
            const cart = await addToCartProducts(bodySend);

            if (cart.status === 200 || cart.message === "Item already in cart") {
                Swal.fire({
                    title: "Added to cart",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1000,
                    timerProgressBar: true,
                }).then(() => {
                    localStorage.removeItem("cartBodyItem");
                    localStorage.removeItem("addressSubmitted");
                    window.location.href = '/myorder';
                });
            }else if (cart.message === "Product does not exist") {
              Swal.fire({
                title: "Not Available",
                text: "Package Not available in your city will available soon.",
                icon: "info",
                showConfirmButton: true,
                confirmButtonText: "OK",
              });
            }
        } catch (error) {
            console.error("Error adding to cart:", error);
        }
    }
};


  useEffect(() => {
    if (authTokens === undefined) {
      const mytoken = localStorage.getItem("authToken");
      setAuthTokens(mytoken);
    }
  }, [authTokens]);

  const handleNavigation = () => {
    navigate("/login", { state: { fromRegister: "register" } });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };



  const handleBreedChange = (selectedBreed) => {
    setBreed(selectedBreed);
    setBreedError("");
  };

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    let filteredSuggestions = [];
    if (userInput.length > 0) {
      filteredSuggestions = breedOptions.filter(
        (option) =>
          option?.title.toLowerCase().includes(userInput.toLowerCase()) && option.type == (pet_type ?? "Dog")
      );
    }

    setSuggestions(filteredSuggestions);
    setBreedName(userInput);
    setShowSuggestions(true);
    setBreedError("")
  };

  const handleSelectSuggestion = (breed) => {
    setBreedName(breed?.title);
    setBreed(breed.id);
    setShowSuggestions(false);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 200); // Delay closing suggestions to allow selection
  };



  return (
    <div className="container-xxl main-detail-container">
      <div className="row justify-content-center align-items-center py-5 px-2">
        <div className="col-md-6 d-none d-lg-block">
          <img src={LeftImg} alt="img" />
        </div>
        <div className="col-md-6 text-start formRightDiv">
          <h5 className="text-center bold my-4">
            Address Details
          </h5>


          <div className="mb-3 px-3 inputWrapper">
            <label htmlFor="location" className="inputLabel">Your locality</label>
            <ReactGoogleAutocomplete
              ref={autocompleteRef}
              apiKey="AIzaSyATEexg-rv1JsMhL7B46chlOv3wWzGrR7E"
              onPlaceSelected={(place) => {
                if (place.geometry) {
                  setSelectedPlace(place);
                  const lat = place.geometry.location.lat();
                  const lng = place.geometry.location.lng();
                  setLatitude(lat);
                  setLongitude(lng);
                  setGoogleAutocomplete(place);
                  setInputSearchValue("");
                  setAddressError("");

                }
              }}
              placeholder={'Search for location'}
              className="inputFormControl"
              options={{
                types: [],
                componentRestrictions: { country: "in" },
              }}
            />

            {addressError && (
              <div className="invalid-feedback">{addressError}</div>
            )}
          </div>

          <div className="mb-3 px-3 inputWrapper">
            <label htmlFor="houseno" className="inputLabel">Flat No./Budling</label>
            <input
              type="text"
              className={`inputFormControl ${addressError ? "is-invalid" : ""}`}
              name="houseno"
              placeholder="Please add Flat, House no."
              onChange={(e) => {
                setHouseNo(e.target.value)
                setHouseNoError("");
              }}
              value={houseNo}
            />
            {houseNoError && (
              <div className="invalid-feedback">{houseNoError}</div>
            )}
          </div>

          <div className="mb-3 px-3 inputWrapper">
            <label htmlFor="locality" className="inputLabel">Locality/Street</label>
            <input
              type="text"
              className={`inputFormControl ${localityError ? "is-invalid" : ""}`}
              name="locality"
              placeholder="Please add Locality, Street"
              onChange={(e) => {
                setLocality(e.target.value)
                setLocalityError("");
              }}
              value={locality}
            />
            {localityError && (
              <div className="invalid-feedback">{localityError}</div>
            )}
          </div>

          <div className="mb-3 px-3 inputWrapper">
            <label htmlFor="city" className="inputLabel">City</label>
            <select
              className="inputFormControl"
              onChange={async (e) => {
                const selectedCityId = e.target.value;
                const selectedCity = cities.find(
                  (city) => city.id === selectedCityId
                );

                setSelectedCityId(selectedCityId);
                setSelectedCity(selectedCity ? selectedCity?.title : "");
                setCityError("");

              }}
              value={selectedCityId}

            >
              <option value="" disabled hidden>
                {" "}
                Select City
              </option>
              {cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city?.title}
                </option>
              ))}
            </select>
            {cityError && <div className="invalid-feedback">{cityError}</div>}
          </div>
          <div className="mb-3 px-3 inputWrapper">
            <label htmlFor="pincode" className="inputLabel">Pincode</label>
            <input
              type="text"
              className={`inputFormControl ${pincodeError ? "is-invalid" : ""}`}
              name="pincode"
              placeholder="Pincode"
              onChange={(e) => {
                setPincode(e.target.value);
                setPincodeError("");
              }}
              value={pincode}
            />
            {pincodeError && (
              <div className="invalid-feedback">{pincodeError}</div>
            )}
          </div>


          <h5 className="text-center bold mt-3">
            Add Personal Details
          </h5>
          <form className="my-4">
            <div className="mb-3 formR px-3 inputWrapper">
              <label htmlFor="name" className="inputLabel">Name</label>
              <input
                type="text"
                className={`inputFormControl ${fnameError ? "is-invalid" : ""}`}
                name="name"
                placeholder="Full Name"
                onChange={(e) => {
                  setFname(e.target.value);
                  setFNameError("");
                }}
                value={fname}
              />
            </div>
            <div className="mb-3 px-3 inputWrapper">

              {fnameError && (
                <div className="invalid-feedback">{fnameError}</div>
              )}
            </div>


            <div className="mb-3 px-3 inputWrapper">
              <label htmlFor="email" className="inputLabel">Email</label>
              <input
                type="email"
                className={`inputFormControl ${emailError ? "is-invalid" : ""}`}
                name="email"
                placeholder="Email"
                onChange={(e) => {
                  setEmail((e.target.value).trim());
                  setEmailError("");
                }}
                value={email}
              />
              {emailError && (
                <div className="invalid-feedback">{emailError}</div>
              )}
            </div>

            <div className="mb-3 px-3 inputWrapper">
              <label htmlFor="phone" className="inputLabel">Phone Number</label>
              <div className="inputFormControl" style={{ padding: "7px 10px" }}>
                <PhoneInput
                  country={"in"}
                  onlyCountries={['in']}
                  value={mobile}
                  onChange={handlePhoneChange}
                  inputMode="tel"
                  buttonStyle={{ border: "none", backgroundColor: "transparent", }}
                />

              </div>
              {verified ? (
                <p className="verifiedTextPhone">Verified</p>
              ) : otpSendSts ? (
                <p className="verifiedTextPhone otp-sent">Otp Sent</p>
              ) : (
                <input
                  type="button"
                  className={"sendOtpBtnPhone " + (otpBtnState ? "dis" : "")}
                  value="Send OTP"
                  onClick={onSendOtp}
                />
              )}

            </div>
            <div className="mb-3 px-3 inputWrapper">
              {phoneError && (
                <div className="invalid-feedback">{phoneError}</div>
              )}
            </div>

            {otpState ? (
              <div className="mb-3 px-3 otp-wrapper">
                <label className="otpLabel">Enter OTP Here</label>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} inputMode="tel" />}
                />
                {otpError && <div className="invalid-feedback">{otpError}</div>}
              </div>
            ) : (
              <></>
            )}


            <div className="mb-3 px-3 text-center">
              <button
                type="submit"
                className={
                  "btn nextBtn text-center " + (verified ? "" : "disabled")
                }
                onClick={handleSubmit}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* ------------------ */}
    </div>
  )
}

export default PersonalDetailsProduct