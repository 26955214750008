import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './ProductDetails.css';
import { addToCart, addToCartProducts } from '../../../service/api';
import FoodDesc from '../Foods/FoodDesc';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function ProductDetails({ product, isFood, primaryColor, secondaryColor }) {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [isLogin, setIsLogin] = useState(false);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [selectedPack, setSelectedPack] = useState(null);
  const [selectedVariationTitle, setSelectedVariationTitle] = useState('');
  const [pricePerPack, setPricePerPack] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedQuantityPrice, setSelectedQuantityPrice] = useState(0);
  const [packsQuantity, setPackQuantity] = useState(1);
  const [packDiscount, setPackDiscount] = useState(0);


  useEffect(() => {
    const calculatePrices = () => {
      const discountFactor = 1 - packDiscount / 100;

      // Calculate total price
      const newTotalPrice =
        selectedQuantityPrice * packsQuantity * quantity * discountFactor;

      // Calculate price per pack
      const newPricePerPack = newTotalPrice / (packsQuantity * quantity);

      // Update state with new values
      setTotalPrice(newTotalPrice);
      setPricePerPack(newPricePerPack);
    };

    calculatePrices();
  }, [selectedQuantityPrice, packsQuantity, quantity, packDiscount]);


  useEffect(() => {



    const isLoginData = JSON.parse(localStorage.getItem("loggedin"));
    if (isLoginData) {
      setIsLogin(isLoginData);
    } else {
      setIsLogin(false);
    }

 
  }, []);

  useEffect(() => {
    if (product) {
      // Automatically select the first variation if available
      if (product.variations && product.variations.length > 0) {
        setSelectedVariation(product.variations[0].variation_id);
        setPricePerPack(product.variations[0].offer_price);
        setTotalPrice(product.variations[0].offer_price * quantity);
        setSelectedQuantityPrice(product.variations[0].offer_price);
        setSelectedVariationTitle(product.variations[0].title)
      }

      // Automatically select the first pack if available
      if (product.packs && product.packs.length > 0) {
        setSelectedPack(product.packs[0].id);
      }
    }
  }, [product]);

  if (!product) {
    return <div>Product not found</div>;
  }


  const increaseQuantity = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  // Function to handle decreasing the quantity
  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  function argbToHex(argb) {
    return '#' + argb.slice(-6);
  }

  const handleVariationSelect = (variation) => {
    setSelectedVariation(variation.variation_id);
    setSelectedQuantityPrice(variation.offer_price);
  };
  const handlePackSelect = (pack) => {
    setSelectedPack(pack.id);
    setPackQuantity(pack.value);
    setPackDiscount(pack.discount)
  };

  const addToCartData = async () => {

    // Check if there are variations or packs and if none is selected
    if ((product.variations.length > 0 && !selectedVariation) || (product.packs.length > 0 && !selectedPack)) {
      Swal.fire({
        title: "Please select a variation or pack",
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Ok",
      });
      return; // Exit the function, don't proceed
    }

    // Prepare the body object to send
    const bodySend = {
      quantity: quantity,
      item_id: product.id,
      isMultipleCity: false,
      isProduct: true,
      packId: selectedPack
    };

    // Add variation_id if a variation is selected
    if (selectedVariation) {
      bodySend['variation_id'] = selectedVariation;
    }

    // If the user is logged in, proceed to add the product to the cart
    if (isLogin) {
      const cart = await addToCartProducts(bodySend);
      if (cart.status == 200 || cart.message == "Item already in cart") {
        Swal.fire({
          title: "Added to cart",
          icon: "success",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        }).then(() => {
        });
      }
    }
    // If not logged in, save the item in localStorage and redirect to user details page
    else {
      localStorage.setItem("cartBodyItem", JSON.stringify(bodySend));
      navigate('/user-details');
    }
  };


  const buyNow = async () => {
    

    const bodySend = {
      quantity: quantity,
      item_id: product.id,
      isMultipleCity: false,
      isProduct: true,
      packId: selectedPack
    };

    if (selectedVariation) {
      bodySend['variation_id'] = selectedVariation;
    }

    if(isLogin){

      const cart = await addToCartProducts(bodySend);
      if (cart.status == 200 || cart.message == "Item already in cart") {
        if (isLogin) {
          navigate("/myorder");
          
        }
      }
    }
    else {
      localStorage.setItem("cartBodyItem", JSON.stringify(bodySend));
      navigate('/user-details');
    }
  }

  return (
    <div className="product-details-container">
      <div className="product-images">
        {product.images.length > 0 ? (
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            pagination={{ clickable: true }}
            className="product-swiper"
          >
            {product.images.map((img, index) => (
              <SwiperSlide key={index}>
                <img src={img} alt={`${product.title} ${index + 1}`} className="additional-image" />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <img src={product.image} alt={product.title} className="single-image" />
        )}
      </div>

      <div className="product-info">
        <h2 className='product-title'>{product.title}</h2>
        <div className="rating">
          Rating: {product.rating} ({product.total_rating} reviews)
        </div>
        <div className='product-pq my-2' style={{ backgroundColor: product.background_light_color ? argbToHex(product.background_light_color) : '#F6F3FF' }}>
          <div className="product-price">
            <span className='pq-label'>Price</span>
            <div className='price-container'>
              <span className="current-price" style={{ color: product.background_color ? argbToHex(product?.background_color) : '#7B5BFB' }}>₹{(totalPrice ?? product.offer_price).toFixed(2)}</span>
              {isFood && totalPrice != pricePerPack && <div className="price-per-pack">
                <span className='price-per-pack-span' style={{ fontWeight: '600', color: product.background_color ? argbToHex(product.background_color) : '#7B5BFB' }}>
                  {`(₹${pricePerPack} per pack , ${selectedVariationTitle})`}
                </span>
              </div>}
            </div>
          </div>

          <div className='product-quantity'>
            <span className='pq-label'>Quantity</span>
            <div className="quantity-selector">
              <button className="quantity-btn" style={{ backgroundColor: product.background_color ? argbToHex(product?.background_color) : '#7B5BFB', borderTopLeftRadius: '40%', borderTopRightRadius: '40%', borderBottomLeftRadius: '40%', borderBottomRightRadius: '0' }} onClick={decreaseQuantity}>-</button>
              <input
                type="text"
                value={quantity}
                onChange={(e) => setQuantity(parseInt(e.target.value) || 1)}
                className="quantity-input"
              />
              <button className="quantity-btn" style={{ backgroundColor: product.background_color ? argbToHex(product?.background_color) : '#7B5BFB', borderTopLeftRadius: '40%', borderTopRightRadius: '40%', borderBottomLeftRadius: '0', borderBottomRightRadius: '40%' }} onClick={increaseQuantity}>+</button>
            </div>
          </div>

        </div>
        {isFood && <div className='container m-0 p-0' >
          <FoodDesc fill={product.background_color ? argbToHex(product.background_color) : '#7B5BFB'} />
        </div>}
        {product.variations.length > 0 && <div className="variations">
          <span className='variation-label'>{product.variation_title}</span>
          <div className="variation-options">
            {product?.variations?.map(variation => (
              <div
                key={variation.id}
                className={`variation ${selectedVariation == variation.variation_id ? 'selected' : ''}`}
                style={{
                  color: selectedVariation == variation.variation_id
                    ? 'white'
                    : (product.background_color ? argbToHex(product.background_color) : '#7B5BFB'), // Text color based on selection

                  backgroundColor: selectedVariation == variation.variation_id
                    ? (product.background_color ? argbToHex(product.background_color) : '#7B5BFB') // Background color based on selection
                    : 'white',

                  borderColor: product.background_color ? argbToHex(product.background_color) : '#7B5BFB', // Border color
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderTopLeftRadius: '50px',
                  borderTopRightRadius: '50px',
                  borderBottomLeftRadius: '50px',
                  borderBottomRightRadius: '0',
                  padding: '5px 15px',
                }}


                onClick={() => handleVariationSelect(variation)}
              >
                <span>{variation.title}</span>
              </div>
            ))}
          </div>
        </div>}
        {product.packs.length > 0 && <div className="packs">
          <span className='packs-label'>{product.pack_title}</span>
          <div className="pack-options">
            {product.packs.map(pack => (
              <div
                key={pack.id}
                className={`pack ${selectedPack == pack.id ? 'selected' : ''}`}
                onClick={() => handlePackSelect(pack)}
                style={{
                  color: selectedPack == pack.id
                    ? 'white'
                    : (product.background_color ? argbToHex(product.background_color) : '#7B5BFB'), // Text color based on selection

                  backgroundColor: selectedPack == pack.id
                    ? (product.background_color ? argbToHex(product.background_color) : '#7B5BFB') // Background color based on selection
                    : 'white',

                  borderColor: product.background_color ? argbToHex(product.background_color) : '#7B5BFB', // Border color
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderTopLeftRadius: '40px',
                  borderTopRightRadius: '40px',
                  borderBottomLeftRadius: '40px',
                  borderBottomRightRadius: '0',
                  padding: '8px 16px',
                  position: 'relative'
                }}

              >
                <span>{pack.title}</span>
                {/* Display discount if greater than 0 */}
                {pack.discount > 0 && (
                  <span
                    style={{
                      position: 'absolute',
                      top: '-10px',
                      right: '20px',
                      left: '20px',
                      backgroundColor: selectedPack == pack.id
                        ? 'white'
                        : (product.background_color ? argbToHex(product.background_color) : '#7B5BFB'),
                      color: selectedPack == pack.id
                        ? (product.background_color ? argbToHex(product.background_color) : '#7B5BFB') : 'white',
                      padding: '1px 5px',
                      fontSize: '10px',
                      borderRadius: '5px',
                      fontWeight: 'bold',
                      border: '1px solid',
                      borderColor: selectedPack == pack.id ?
                        (product.background_color ? argbToHex(product.background_color) : '#7B5BFB') : 'white',
                      textAlign: 'center'
                    }}
                  >
                    {pack.discount}% off
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>}
        <div className="action-buttons">
          <button className="add-to-cart-btn" style={{
            color: product.background_color ? argbToHex(product.background_color) : '#7B5BFB', borderColor: product.background_color ? argbToHex(product.background_color) : '#7B5BFB', borderWidth: '1px',
            borderStyle: 'solid',
          }} onClick={addToCartData}>Add to Cart</button>
          <button className="buy-now-btn" style={{
            color: 'white', backgroundColor: product.background_color ? argbToHex(product.background_color) : '#7B5BFB', borderColor: product.background_color ? argbToHex(product.background_color) : '#7B5BFB', borderWidth: '1px',
            borderStyle: 'solid',
          }} onClick={buyNow}>Buy Now</button>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
