import React from 'react'
import ProductBanner from './ProductBanner'
import ProductCategories from './ProductCategories'

function ProductHome() {
  return (
    <div>
        <ProductBanner />
        <ProductCategories />
    </div>
  )
}

export default ProductHome