import React, { useState, useEffect } from 'react';
import './PackageModal.css'; // Add your styles for the modal

const PackageModal = ({ isOpen, onClose, packageDetails }) => {
  const [isMobile, setIsMobile] = useState(false);

  // Check screen size to determine if it's mobile
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768); // 768px is the breakpoint for mobile
    };

    checkScreenSize(); // Initial check
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  if (!isOpen) return null;

  return (
    <div className={`modal-overlay ${isMobile ? 'mobile' : ''}`}>
      <div className={`modal-content ${isMobile ? 'bottom-sheet' : ''}`}>
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <h2>{packageDetails.product.title}</h2>

        {packageDetails.product.description_html && (
          <div className="modal-description">
            <h3>Package Details:</h3>
            <div dangerouslySetInnerHTML={{ __html: packageDetails.product.description_html }} />
          </div>
        )}
        {packageDetails.product.info_html && (
          <div className="modal-info">
            <h3>Additional Information:</h3>
            <div dangerouslySetInnerHTML={{ __html: packageDetails.product.info_html }} />
          </div>
        )}
        {packageDetails.product.cancellation_and_refund_policy_html && (
          <div className="modal-cancellation">
            <h3>Cancellation and Refund Policy:</h3>
            <div dangerouslySetInnerHTML={{ __html: packageDetails.product.cancellation_and_refund_policy_html }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PackageModal;
