import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from "../../../assets/images/dark-logo.png";
import { getImage64 } from '../../../service/api';
import { useLocation } from 'react-router-dom';

function DownloadPrescription() {
  const location = useLocation();
  const [data, setData] = useState([]);

  useEffect(() => {


    if (location?.state?.data) {
      setData(location?.state?.data)
    }

    else {
      const queryParams = new URLSearchParams(location.search);

      const fetchedData = {
        owner: {
            name: queryParams.get('owner.name'),
            contact: queryParams.get('owner.contact'),
        },
        pet: {
            pet_name: queryParams.get('pet.pet_name'),
            pet_age: queryParams.get('pet.pet_age'),
            pet_gender: queryParams.get('pet.pet_gender'),
            pet_breed: queryParams.get('pet.pet_breed'),
            pet_vaccinated: queryParams.get('pet.pet_vaccinated')
        },
        vendor: {
            vendor_name: queryParams.get('vendor.vendor_name'),
            vendor_contact: queryParams.get('vendor.vendor_contact'),
            vendor_degree: queryParams.get('vendor.vendor_degree'),
            vendor_signature: queryParams.get('vendor.vendor_signature')
        },
        prescription: {
            prescription_id: queryParams.get('prescription.prescription_id'),
            custom_prescription_id: queryParams.get('prescription.custom_prescription_id'),
            complaints: queryParams.get('prescription.complaints'),
            medication: decodeURIComponent(queryParams.get('prescription.medication')),
            pet_name: queryParams.get('prescription.pet_name'),
            updated_at: queryParams.get('prescription.updated_at'),
            diet_chart: queryParams.get('prescription.diet_chart') ? decodeURIComponent(queryParams.get('prescription.diet_chart')) : null,
        }
    };
      setData(fetchedData);
      console.log(queryParams.get('prescription'));
    }

    // Set the fetched data to state

  }, [location]);



  const loadImageAsBase64 = async (imgSrc) => {
    const body = { url: imgSrc };
    const imgBase = await getImage64(body);
    return imgBase.base64;
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleDateString('en-US', options).replace(',', '');
  }

  const generatePDF = async () => {
    const pdf = new jsPDF('p', 'pt', 'a4');
    const margin = 40;
    const lineHeight = 20;
    const pageHeight = pdf.internal.pageSize.height;
    let y = margin;

    // Add Logo
    const img = new Image();
    img.src = Logo;
    img.onload = async () => {
      pdf.addImage(img, 'PNG', margin, y, 170, 35);

      pdf.setFontSize(14);
      pdf.setFont('helvetica', 'bold');

      // Calculate width of the vendor name text for right alignment
      let vendorNameWidth = pdf.getTextWidth(data.vendor.vendor_name);
      pdf.text(data.vendor.vendor_name, pdf.internal.pageSize.width - margin - vendorNameWidth, y + 10);

      pdf.setFontSize(12);
      pdf.setFont('helvetica', 'normal');

      // Calculate width of the vendor degree text for right alignment
      let vendorDegreeWidth = pdf.getTextWidth(data.vendor.vendor_degree);
      pdf.text(data.vendor.vendor_degree, pdf.internal.pageSize.width - margin - vendorDegreeWidth, y + 30);

      // Calculate width of the updated_at text for right alignment
      let updatedAtWidth = pdf.getTextWidth(formatDate(data.prescription.updated_at));
      pdf.text(formatDate(data.prescription.updated_at), pdf.internal.pageSize.width - margin - updatedAtWidth, y + 50);

      // Calculate width of the prescription ID text for right alignment
      let prescriptionIdWidth = pdf.getTextWidth(`Prescription ID: ${data.prescription.custom_prescription_id}`);
      pdf.text(`Prescription ID: ${data.prescription.custom_prescription_id}`, pdf.internal.pageSize.width - margin - prescriptionIdWidth, y + 70);


      // Move to next section
      y += 100;

      // Draw a line
      pdf.setLineWidth(1.5);
      pdf.line(margin, y, pdf.internal.pageSize.width - margin, y);
      y += lineHeight;

      // Pet Information
      pdf.setFontSize(14);
      pdf.setFont('helvetica', 'bold');
      pdf.text('Pet Information', margin, y);
      pdf.setFontSize(12);
      pdf.setFont('helvetica', 'normal');
      y += lineHeight;
      pdf.text(`Name: ${data.pet.pet_name}`, margin, y);
      y += lineHeight;
      pdf.text(`Age: ${data.pet.pet_age ?? "Not Provided"}`, margin, y);
      y += lineHeight;
      pdf.text(`Gender: ${data.pet.pet_gender ?? "Not Provided"}`, margin, y);
      y += lineHeight;
      pdf.text(`Vaccinated: ${data.pet.pet_vaccinated ?? "Not Provided"}`, margin, y);
      y += lineHeight * 2;

      pdf.setFontSize(14);
      pdf.setFont('helvetica', 'bold');
      pdf.text('Pet Parent Information', margin, y);
      pdf.setFontSize(12);
      pdf.setFont('helvetica', 'normal');
      y += lineHeight;
      pdf.text(`Name: ${data.owner.name}`, margin, y);
      y += lineHeight;
      pdf.text(`Contact: ${data.owner.contact}`, margin, y);
      y += lineHeight * 2;

      pdf.setFontSize(14);
      pdf.setFont('helvetica', 'bold');
      pdf.text('Medical Information', margin, y);

      pdf.setFontSize(12);
      pdf.setFont('helvetica', 'normal');
      y += lineHeight;
      if (data.prescription.complaints) {
        pdf.setFont('helvetica', 'bold');
        pdf.setTextColor(128, 128, 128);
        pdf.text('Concerns', margin, y);

        pdf.setFont('helvetica', 'normal');
        pdf.setTextColor(0, 0, 0);
        y += lineHeight;

        const complaintsLine = data.prescription.complaints.split('\n');
        complaintsLine.forEach((line) => {
          pdf.text(`• ${line}`, margin, y); // Adding bullet point before each line
          y += lineHeight; // Increment y for each line
        });
        y += 10;
      }

      if (data.prescription.diagnosis) {
        pdf.setFont('helvetica', 'bold');
        pdf.setTextColor(128, 128, 128);
        pdf.text('Diagnosis', margin, y);

        pdf.setFont('helvetica', 'normal');
        pdf.setTextColor(0, 0, 0);
        y += lineHeight;

        const diagnosisLines = data.prescription.diagnosis.split('\n');
        diagnosisLines.forEach((line) => {
          pdf.text(`• ${line}`, margin, y); // Adding bullet point before each line
          y += lineHeight; // Increment y for each line
        });
        y += 10;
      }

      if (data.prescription.medication) {
        pdf.setFont('helvetica', 'bold');
        pdf.setTextColor(128, 128, 128);
        pdf.text('Medications', margin, y);

        pdf.setFont('helvetica', 'normal');
        pdf.setTextColor(0, 0, 0);
        y += lineHeight;

        const medicationLines = data.prescription.medication.split('\n');
        medicationLines.forEach((line) => {
          pdf.text(`• ${line}`, margin, y); // Adding bullet point before each line
          y += lineHeight; // Increment y for each line
        });
        y += 10;
      }

      if (data.prescription.advice_notes) {
        pdf.setFont('helvetica', 'bold');
        pdf.setTextColor(128, 128, 128);
        pdf.text('Advice Notes', margin, y);

        pdf.setFont('helvetica', 'normal');
        pdf.setTextColor(0, 0, 0);
        y += lineHeight;

        const notes = data.prescription.advice_notes.split('\n');
        notes.forEach((note) => {
          pdf.text(`• ${note}`, margin, y); // Adding bullet point before each note
          y += lineHeight; // Increment y for each line
        });
      }


      const signatureY = pageHeight - margin - 80;

      const addFooter = (pdf) => {
        const pageCount = pdf.internal.getNumberOfPages();
        pdf.setFontSize(10);
        pdf.setFont('helvetica', 'normal');

        const pageWidth = 595.28;  // A4 page width
        const pageHeight = 841.89; // A4 page height
        const margin = 20;         // Left and right margin

        // Calculate the start and end of the divider
        const dividerStart = margin;
        const dividerEnd = pageWidth - margin;
        const footerY = pageHeight - 50;  // Position of the footer above the bottom

        // Text positions
        const leftLabel = "Email:";
        const leftValue = "support@dearpet.in";
        const centerLabel = "Website:";
        const centerValue = "https://www.dearpet.in";
        const rightLabel = "Contact:";
        const rightValue = "8888880404";

        // Calculate the space for the text sections
        const leftTextWidth = pdf.getStringUnitWidth(leftLabel + leftValue) * 5;
        const centerTextWidth = pdf.getStringUnitWidth(centerLabel + centerValue) * 5;
        const rightTextWidth = pdf.getStringUnitWidth(rightLabel + rightValue) * 5;

        // Calculate the horizontal positions for each section
        const leftX = dividerStart;
        const centerX = dividerStart + 220;
        const rightX = dividerStart + 460;

        for (let i = 1; i <= pageCount; i++) {
          pdf.setPage(i);

          // Divider line above footer
          pdf.setLineWidth(0.5);
          pdf.line(dividerStart, footerY, dividerEnd, footerY);

          // Left-aligned text (Email)
          pdf.setTextColor(0, 0, 0);  // Black color for label
          pdf.text(leftLabel, leftX, footerY + 15);
          pdf.setTextColor(0, 0, 255);  // Blue color for value
          pdf.textWithLink(leftValue, leftX + 30, footerY + 15, { url: `mailto:${leftValue}` });

          // Centered text (Website)
          pdf.setTextColor(0, 0, 0);  // Black color for label
          pdf.text(centerLabel, centerX, footerY + 15);
          pdf.setTextColor(0, 0, 255);  // Blue color for value
          pdf.textWithLink(centerValue, centerX + 43, footerY + 15, { url: centerValue });

          // Right-aligned text (Contact)
          pdf.setTextColor(0, 0, 0);  // Black color for label
          pdf.text(rightLabel, rightX, footerY + 15);
          pdf.setTextColor(0, 0, 255);  // Blue color for value
          pdf.textWithLink(rightValue, rightX + 40, footerY + 15, { url: `tel:${rightValue}` });
        }
      };


      if (data.vendor?.vendor_signature) {
        try {
          const base64Signature = await loadImageAsBase64(data.vendor.vendor_signature);
          pdf.addImage(base64Signature, 'PNG', pdf.internal.pageSize.width - margin - 100, signatureY, 100, 30);
        } catch (error) {
          console.error('Error loading image:', error);
        }
      }

      // Adjust the Y position for the doctor info
      const doctorInfoY = signatureY + 50; // Move it down more if needed

      // Set a smaller font size
      pdf.setFontSize(10); // Adjust the size as needed
      pdf.text(data.vendor.vendor_name, pdf.internal.pageSize.width - margin - 100, doctorInfoY);



      if (data.prescription.diet_chart && data.prescription.diet_chart.length > 0) {
        pdf.addPage();
        y = margin + 10; // Reset y position

        pdf.setFontSize(14);
        y += lineHeight;

        const dietChartTableData = data.prescription.diet_chart.map((dietEntry) => [
          dietEntry.time,
          dietEntry.diet,
        ]);

        pdf.setFont('helvetica', 'bold');

        const textWidth = pdf.getStringUnitWidth('Diet Chart') * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        const pageWidth = pdf.internal.pageSize.width;
        const textX = (pageWidth - textWidth) / 2;

        pdf.text('Diet Chart', textX, y);

        const tableStartY = y + 10;

        pdf.autoTable({
          startY: tableStartY,
          head: [['Time', 'Diet']],
          body: dietChartTableData,
          theme: 'striped',
          margin: { top: 10, left: 14, right: 14 },
          styles: {
            fontSize: 10,
            cellPadding: 2,
          },
          headStyles: {
            fillColor: [200, 200, 200],
            textColor: [0, 0, 0],
            fontStyle: 'bold',
            halign: 'center',
            valign: 'middle',
          },
          bodyStyles: {
            valign: 'middle',
          },
          columnStyles: {
            0: { halign: 'center' },
            1: { halign: 'center' },
          },
        });

        pdf.setFont('helvetica', 'normal');

        const boxMargin = 10;

        const notesStartY = pdf.autoTable.previous.finalY + 10;


        pdf.setFontSize(8);
        pdf.setTextColor(128, 128, 128);
        const notesText = [
          "* This diet chart is made according to the age of the pet and condition.",
          "* Treats should not be given frequently.",
          "* Water content is important for healthy skin and body."
        ];

        notesText.forEach((line, index) => {
          pdf.text(line, boxMargin + 5, notesStartY + 10 + (index * 10));
        });
      }


      addFooter(pdf);

      const customId = data.prescription.custom_prescription_id;
      const filename = `prescription_${customId}.pdf`;
      pdf.save(filename);
    };
  };


  return (
    <div style={{ paddingTop: "100px", display: 'flex', justifyContent: 'center', flexDirection: "column", alignItems: "center" }}>

      <div
        id='pdfContent'
        style={{
          padding: '20px 20px 150px 20px',
          width: '100%',
          maxWidth: '800px',
          minHeight: '1000px',
          borderRadius: '10px',
          backgroundColor: '#fff',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          border: '1px solid #ddd',
          boxSizing: 'border-box',
          position: 'relative'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: "10px 10px 10px 10px"
          }}
        >

          <img
            src={Logo}
            alt="Logo"
            style={{ width: "240px" }}
          />


          <div style={{ textAlign: 'right' }}>
            <span style={{ margin: 0, fontSize: "14px", fontWeight: "600" }}>{data.vendor?.vendor_name}</span><br />
            <span style={{ margin: 0, fontSize: "12px" }}>{data.vendor?.vendor_degree}</span><br />
            <span style={{ margin: 0, fontSize: "11.5px" }}>{formatDate(data.prescription?.updated_at)}</span><br />
            <span style={{ margin: 0, fontSize: "11.5px" }}>Prescription ID: {data.prescription?.custom_prescription_id}</span>
          </div>
        </div>

        <hr style={{ margin: '20px 0', borderColor: '#000' }} />

        <div style={{ margin: "20px 10px" }}>
          <h3 style={{ color: 'black', fontWeight: 'bold', marginBottom: '13px' }}>Pet Information</h3>
          <span style={{ color: 'grey' }}>Name: {data.pet?.pet_name}</span><br />
          <span style={{ color: 'grey' }}>Age: {data.pet?.pet_age ?? 'Not Provided'}</span><br />
          <span style={{ color: 'grey' }}>Gender: {data.pet?.pet_gender ?? 'Not Provided'}</span><br />
          <span style={{ color: 'grey' }}>Vaccinated: {data.pet?.pet_vaccinated ?? 'Not Provided'}</span>
        </div>

        <div style={{ margin: "20px 10px" }}>
          <h3 style={{ color: 'black', fontWeight: 'bold', marginBottom: '13px' }}>Pet Parent Information</h3>
          <span style={{ color: 'grey' }}>Name: {data.owner?.name}</span><br />
          <span style={{ color: 'grey' }}>Contact: {data.owner?.contact}</span>
        </div>

        <div style={{ margin: "20px 10px" }}>
          <h3 style={{ color: 'black', fontWeight: 'bold', marginBottom: '13px' }}>Medical Information</h3>

          {data.prescription?.complaints && (
            <>
              <span style={{ color: 'grey', fontWeight: 'bold' }}>Concerns</span>
              <p style={{ color: 'black', whiteSpace: 'pre-wrap' }}>
                {data.prescription.complaints.split('\n').map((complaint, index) => (
                  <React.Fragment key={index}>
                    &bull; {complaint}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </>
          )}

          {data.prescription?.diagnosis && (
            <>
              <span style={{ color: 'grey', fontWeight: 'bold' }}>Diagnosis</span>
              <p style={{ color: 'black', whiteSpace: 'pre-wrap' }}>
                {data.prescription.diagnosis.split('\n').map((diagnosis, index) => (
                  <React.Fragment key={index}>
                    &bull; {diagnosis}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </>
          )}

          {data.prescription?.medication && (
            <>
              <span style={{ color: 'grey', fontWeight: 'bold' }}>Medications</span>
              <p style={{ color: 'black', whiteSpace: 'pre-wrap' }}>
                {data.prescription.medication.split('\n').map((medication, index) => (
                  <React.Fragment key={index}>
                    &bull; {medication}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </>
          )}

          {data.prescription?.advice_notes && (
            <>
              <span style={{ color: 'grey', fontWeight: 'bold' }}>Advice Notes</span>
              <p style={{ color: 'black', whiteSpace: 'pre-wrap' }}>
                {data.prescription.advice_notes.split('\n').map((note, index) => (
                  <React.Fragment key={index}>
                    &bull; {note}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </>
          )}


        </div>

        {data.prescription?.diet_chart && data.prescription?.diet_chart.length > 0 && (
          <div style={{ margin: "20px 10px" }}>
            <h3 style={{ color: 'black', fontWeight: 'bold', marginBottom: '13px' }}>Diet Chart</h3>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
              <thead>
                <tr style={{ backgroundColor: '#ccc', textAlign: 'center' }}>
                  <th style={{ padding: '10px', border: '1px solid #000' }}>Time</th>
                  <th style={{ padding: '10px', border: '1px solid #000' }}>Diet</th>
                </tr>
              </thead>
              <tbody>
                {data.prescription.diet_chart.map((dietEntry, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: 'center', border: '1px solid #000' }}>{dietEntry.time}</td>
                    <td style={{ textAlign: 'center', border: '1px solid #000' }}>{dietEntry.diet}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {data.prescription?.diet_chart && data.prescription?.diet_chart.length > 0 &&
          <div style={{ marginTop: '10px', fontSize: '10px', padding: '5px' }}>
            <span>* This diet chart is made according to the age of the pet and condition.</span><br />
            <span>* Treats should not be given frequently.</span><br />
            <span>* Water content is important for healthy skin and body.</span> <br />
          </div>
        }


        <div style={{
          position: 'absolute',
          bottom: '40px',
          right: '50px',
          textAlign: 'right'
        }}>
          {data.vendor?.vendor_signature ? (
            <img
              id='signature'
              src={data.vendor.vendor_signature}
              alt="Signature"
              style={{ width: '100px', marginBottom: '5px' }}
            />
          ) : null}
          <div>
            <p style={{ margin: 0 }}>{data.vendor?.vendor_name}</p>
          </div>
        </div>

      </div>

      <button
        onClick={generatePDF}
        style={{
          marginTop: '20px',
          marginBottom: '30px',
          padding: '10px 20px',
          backgroundColor: '#7b5bfb',
          color: '#fff',
          border: 'none',
          borderRadius: '5px',
          fontSize: '16px',
          fontWeight: 'bold',
          cursor: 'pointer',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.3s ease, transform 0.3s ease',
          outline: 'none',
          maxWidth: '250px'
        }}
        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#6a4ae0')}
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#7b5bfb')}
        onMouseDown={(e) => (e.currentTarget.style.transform = 'scale(0.95)')}
        onMouseUp={(e) => (e.currentTarget.style.transform = 'scale(1)')}
      >
        Download PDF
      </button>

    </div>
  );
}

export default DownloadPrescription;
