import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getProductDetails } from '../../service/api';
import ProductDetails from './ProductPage/ProductDetails';
import FoodDescription from './Foods/FoodDescription';
import ProductDescription from './ProductPage/ProductDescription';

function ProductPage() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Fetch product details using the id
   

    fetchProduct();
  }, []);

  const fetchProduct = async () => {
    try {
      const productData = await getProductDetails(id);
      setProduct(productData.data)
    } catch (error) {
      console.error('Error fetching product:', error);
    }
  };

  return (
    <div className='container product-container mt-0' style={{paddingTop:'60px' }}>
      <ProductDetails product={product} />
      <ProductDescription productDetails={product} color={'#7B5BFB'} />
    </div>
  )
}

export default ProductPage