import React from 'react';


const PetGroomingService = ({ city }) => {
  return (
    <div className="petGroomingContainer">
      <h1 className='mainHeading'>
        Book Grooming Appointment
      </h1>
      <div className="section">
        <div className="subsection">

          <h1 className="subHeading">Book Pet Grooming Service at Home in {city} with DearPet</h1>
          <p>Welcome to DearPet, where we offer premier pet grooming services at home in {city}. Our mission is to provide stress-free and professional grooming for your beloved pets right in the comfort of your home. With DearPet, you can easily book dog and cat grooming services online, eliminating the need for phone calls and in-person appointments.</p>
        </div>
      </div>

     

      <div className="sectionTwo">
        <div className="subsectionTwo">

          <h1 className="subHeading">Top Pet Grooming Services in {city}: Your Ultimate Guide</h1>
          <p>Are you searching for "pet grooming near me" or "dog grooming near me" in {city}? Look no further! DearPet offers top-notch pet grooming services designed to cater to all your furry friend's needs. Whether you need dog clipping, cat grooming, or a luxurious spa experience for your pet, we have you covered.</p>
        </div>
      </div>
      <div className="section">
        <div className="subsection">
          <h1 className="subHeading">Why Choose DearPet for Home Grooming Services?</h1>
          <ul>
            <li><strong>Stress-Free Experience:</strong> Our expert groomers come to your home, ensuring your pet gets one-on-one attention and care.</li>
            <li><strong>Convenient Online Booking:</strong> Our user-friendly online booking system makes it simple to schedule grooming services at your convenience.</li>
            <li><strong>Comprehensive Grooming:</strong> From bath and dry, ear cleaning, brush out, to sanitary clipping, our services ensure your pet looks and feels their best.</li>
          </ul>
        </div>
      </div>

      <div className="sectionTwo">
        <div className="subsectionTwo">

          <h1 className="subHeading">Expert Dog Groomers Close to You in {city}</h1>
          <p>Finding skilled dog groomers in {city} has never been easier. Our passionate professionals offer precision dog clipping and luxurious dog grooming at home. We specialize in breed-specific grooming, including the perfect Shih Tzu haircut, ensuring personalized care for your pet.</p>
        </div>
      </div>


      <div className="section">
        <div className="subsection">

          <h1 className="subHeading">Comprehensive Pet Grooming in {city}</h1>
          <p>At DearPet, we focus on your pet's overall well-being. Our grooming services in {city} include essential dog bathing services and complete grooming sessions to maintain optimal health and hygiene. Whether it’s a routine trim or a full grooming session, our services are tailored to meet your pet's unique needs.</p>
        </div>
      </div>

      <div className="sectionTwo">
        <div className="subsectionTwo">

          <h1 className="subHeading">Dog Grooming at Home: Tips from {city}'s Best</h1>
          <p>For pet owners interested in grooming their dogs at home, DearPet provides expert advice and tips. From choosing the right dog hair trimmer to achieving a professional-grade Shih Tzu haircut, our experts share their knowledge to make home grooming a rewarding experience.</p>
        </div>
      </div>

      <div className="section">
        <div className="subsection">

          <h1 className="subHeading">Cat and Dog Grooming Near Me: Serving All {city} Pets</h1>
          <p>Our services extend beyond dogs. If you're looking for "cat grooming near me" or comprehensive dog and cat grooming services in {city}, our experienced groomers handle both cats and dogs, ensuring a comfortable and safe grooming session for all pets.</p>
        </div>
      </div>

      <div className="sectionTwo">
        <div className="subsectionTwo">

          <h1 className="subHeading">Luxurious Dog Salon in {city}</h1>
          <p>Indulge your pet with a visit to our luxurious dog salon in {city}. Our full range of services includes spa baths and expert styling, providing your pet with a pampering experience in a serene and welcoming environment.</p>
        </div>
      </div>

      <div className="section">
        <div className="subsection">

          <h1 className="subHeading">{city}'s Premier Dog Bathing Service</h1>
          <p>Our dog bathing service in {city} goes above and beyond to ensure your pet's coat shines and their skin stays healthy. Using gentle, pet-safe products, our thorough cleaning process addresses any underlying skin issues or sensitivities.</p>
        </div>
      </div>

      <div className="sectionTwo">
        <div className="subsectionTwo">

          <h1 className="subHeading">Affordable Pet Grooming at Home in {city}</h1>
          <p>At DearPet, we believe that every pet deserves top-quality grooming at an affordable price. Our services are designed to provide the best care for your pet without breaking the bank.</p>
        </div>
      </div>

      <div className="section">
        <div className="subsection">

          <p>For the best pet grooming services in {city}, DearPet offers the perfect blend of professionalism, care, and convenience. From specialized at-home grooming to luxury experiences in our dog salon, we are committed to providing the highest standard of service for your pet. Schedule an appointment today and discover why we are {city}'s preferred choice for pet grooming.</p>
        </div>
      </div>
    </div>
  );
};

export default PetGroomingService;
