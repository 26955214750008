import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ServiceCard.css';
import PackageModal from './PackageModal'; // Import the modal component

export default function ServiceCard({ plan, title, description, selectedCity, icon, isDelhi }) {
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const navigate = useNavigate();

  const handleBookNow = () => {

    // if (plan.category_id == 3) {
    //   // localStorage.setItem("selectedPlan", JSON.stringify(plan.product));
    //   // navigate("/issue-list");
    //   navigate("/vet-on-call", { state: { catId: plan.category_id, petType: plan.type, selectedCityId: selectedCity } });
    // } else 
    if(plan.category_id == 12){
      navigate("/vet-at-home", { state: { catId: plan.category_id, petType: plan.type, selectedCityId: selectedCity } });

    } else if (!plan.is_product) {
      localStorage.setItem("categoryId", JSON.stringify(plan.category_id));
      navigate(`/packages`, { state: { catId: plan.category_id, petType: plan.type, selectedCityId: selectedCity, planTitle: plan.title } });
    } else {
      localStorage.setItem("selectedPlan", JSON.stringify(plan.product));
      const loginuser = JSON.parse(localStorage.getItem("loggedin"));
      const naviData = {
        fromPage: "vet",
      };
      if (loginuser) {
        navigate("/mypets", { state: { fromWhich: naviData } });
      } else {
        navigate("/personal-details", { state: { fromWhich: naviData } });
      }
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const firstProduct = plan.product ? plan.product : null;

  return (
    <div className="service-card">
      {plan.image && <img src={plan.image} alt={plan.title} className="service-image" />}
      <h3 className="service-title">{plan.title}</h3>
      <div className="service-content">
        {firstProduct && plan.is_product ? (
          <div className="service-pricing">
            <span className="service-price">
              <s>₹{firstProduct.price}</s>
            </span>
            <span className="service-offer-price">
              ₹{firstProduct.offer_price}
            </span>
          </div>
        ) : (
          <div className="service-pricing">
            {firstProduct && (
              <span className="service-offer-price">
                Starting from ₹{firstProduct.offer_price}
              </span>
            )}
          </div>
        )}
        {/* {plan.is_product  && (
          <button className="view-details-btn" onClick={handleOpenModal}>
            Package Details
          </button>
        )} */}
        {!plan.is_coming_soon ?
          <button className="book-now-btn" onClick={handleBookNow}>
            Book Now
          </button> : <button className="coming-soon-btn" disabled>
            Coming Soon
          </button>}
        
        {isModalOpen && (
          <PackageModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            packageDetails={plan}
          />
        )}
      </div>
    </div>
  );
}
