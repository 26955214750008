import React from "react";
// import work1 from "../../assets/images/work1.svg";
import work1 from "../../assets/images/1n.svg";
// import work2 from "../../assets/images/work2.svg";
import work2 from "../../assets/images/2n.svg";
// import work3 from "../../assets/images/work3.svg";
import work3 from "../../assets/images/3n.svg";
// import work4 from "../../assets/images/work4.svg";
import work4 from "../../assets/images/4n.svg";

import img1 from "../../assets/images/Mask group1.svg";
import img2 from "../../assets/images/Mask group2.svg";

import "./style.css";
const HowWorks = () => {
  return (
    <div className="howWorksContainer px-4 py-4">
      <h5 className="sec2Heading my-3">How Pet Grooming works at your home?</h5>
      <div className="row howDiv">
        <div className="col-md-5 d-flex  position-relative workcrd my-2">
          <div className="textDiv leftTextDiv">
            <h4 className="sec2CrdTitle">Book Online</h4>
            <p className="sec2CrdTxt m-0">Select a service, date, and time.</p>
          </div>
          <div className="img1">
            <img src={work1} className="workImg" alt="..." />
          </div>
        </div>
        <div className="col-md-5 d-flex  position-relative workcrd my-2">
          <div className="img2">
            <img src={work2} className="workImg" alt="..." />
          </div>
          <div className="textDiv">
            <h4 className="sec2CrdTitle text-start">Groomer Arrival</h4>
            <p className="sec2CrdTxt text-start m-0">
              Professional groomers visit your home with all professional
              equipment
            </p>
          </div>
        </div>
        <div className="col-md-5 d-flex  position-relative workcrd my-2 pe-0">
          <div className="textDiv leftTextDiv">
            <h4 className="sec2CrdTitle text-start">Professional Care</h4>
            <p className="sec2CrdTxt text-start m-0">
              Expert groomers provide high-quality, personalized service
            </p>
          </div>
          <div className="img3 mb-2">
            <img src={img1} className="workImg" alt="..." />
          </div>
        </div>
        <div className="col-md-5 d-flex  position-relative workcrd my-2 ps-0">
          <div className="img4">
            <img src={img2} className="workImg" alt="..." />
          </div>
          <div className="textDiv">
            <h4 className="sec2CrdTitle">Clean Space</h4>
            <p className="sec2CrdTxt m-0">
              Post-grooming cleanup ensures no mess at home.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWorks;
