import './HealthCheckUp.css';
import SkinCoat from '../../assets/icons/skincoat.svg';
import OralHealth from '../../assets/icons/oralhealth.svg';
import Weight from '../../assets/icons/weightassist.svg';
import Heart from '../../assets/icons/heartlung.svg';
import Eye from '../../assets/icons/eyeear.svg';
import Mobility from '../../assets/icons/mobilityjoint.svg';

export default function HealthCheckUp() {
  return (
    <>
      <h3 className='helth-check-up-title font-extrabold text-center mt-6 pt-6'>Get Help</h3>
      <div className="health-check-up"></div>
      <div className="health-checkup-container">
        <div className="health-checkup-item">
          <img src={SkinCoat} alt="Skin & Coat" className="icon" />
          Skin & Coat Examination
        </div>
        <div className="health-checkup-item">
          <img src={OralHealth} alt="Oral Health" className="icon" />
          Oral Health
        </div>
        <div className="health-checkup-item">
          <img src={Weight} alt="Weight Assessment" className="icon" />
          Weight Assessment
        </div>
        <div className="health-checkup-item">
          <img src={Heart} alt="Heart & Lung" className="icon" />
          Heart & Lung Evaluation
        </div>
        <div className="health-checkup-item">
          <img src={Eye} alt="Eye & Ear" className="icon" />
          Eye & Ear Checkup
        </div>
        <div className="health-checkup-item">
          <img src={Mobility} alt="Mobility & Joint" className="icon" />
          Mobility & Joint Health
        </div>
      </div>
    </>
  );
}

