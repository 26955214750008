import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "./Food.css";
import "swiper/css";
import "swiper/css/pagination";
import Rating from "../../Home/Rating";
import ReviewImg from "../../../assets/reviewImg/review.JPG";
import ReviewImg2 from "../../../assets/reviewImg/review2.jpg";
import ReviewImg3 from "../../../assets/reviewImg/review3.jpg";

function FoodReviews({reviewData}) {
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
      setReviews(reviewData);
    }, [reviewData]);
  
    const newReviews = [
      { id: 1, name: "Archit Gulati", review: "I've noticed such a change after switching to chicken and Brown rice food. He is having so much energy. It's been a month, but every bowl of this food gets the same excited reaction", rating: "4.9", image: ReviewImg },
      { id: 2, name: "Kanika Kapoor", review: "I recently bought DearPet fresh meal for dobby, He really loved it as a busy pet parent I would recommend if you do not have time to prepare food for your pet then you can surely go for DP meals, full of nutrition and tasty for Dobby.", rating: "5", image: ReviewImg2 },
      { id: 3, name: "Deepak", review: "Oscar's tummy was always a concern. Dearpet's grain-free food turned her into a happy, healthy baby who's more playful than ever.", rating: "5", image: ReviewImg3 },
    ];
  
    return (
      <div className="container">
        <h4 className="sec4Heading my-2  text-center"  style={{color:"#F29A4C"}}>Customer Reviews</h4>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          pagination={{ clickable: true }}
          breakpoints={{
            640: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 2, spaceBetween: 30 },
            1024: { slidesPerView: 3, spaceBetween: 40 },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {newReviews.map((review) => (
            <SwiperSlide key={review.id}>
              <div className="card reviewCard">
                <div className="review-bottom">
                  <img
                    src={review.image}
                    className="reviewImg"
                    alt={`${review.name}'s profile`}
                  />
                </div>
                <div className="card-body reviewCardBody">
                  <p className="text-start m-0 rnm">{review.name}</p>
                  <Rating value={parseInt(review.rating)} totalStars={5} />
                  <p className="card-text reviewMsg">
                    <q>{review.review}</q>
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  };

export default FoodReviews