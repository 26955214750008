import React from 'react';
import './LandingInfo.css';
import Search from '../../assets/icons/searchIcon.svg';
import Calendar from '../../assets/icons/appoinment.svg';
import Relax from '../../assets/icons/relax.svg';

const BookingInfo = () => {
    return (
        <div className="booking-info">
            <h2>How to Schedule a Service with DearPet</h2>
            <p>Charges may vary according to your City and Pet.</p>

            <div className="info-container">
                <div className="info-card">
                    <img src={Search} alt="Explore Services" className="info-icon" />
                    <h2>Explore Services</h2>
                    <p>
                        Visit our app or website to find grooming, vet-at-home services, vaccinations, and more. Choose what suits your pet best!
                    </p>
                </div>

                <div className="info-card">
                    <img src={Calendar} alt="Schedule an Appointment" className="info-icon" />
                    <h2>Book with Ease</h2>
                    <p>
                        Pick a time that works for you, provide pet details for personalized care, and confirm your booking with secure payment options.
                    </p>
                </div>

                <div className="info-card">
                    <img src={Relax} alt="Sit Back & Relax" className="info-icon" />
                    <h2>Sit Back & Relax</h2>
                    <p>
                        On the day of the service, just prepare your pet, and let us handle the rest. Whether it’s an at-home visit or virtual consultation, we ensure a smooth, stress-free experience.
                    </p>
                </div>
            </div>
        </div>

    );
};

export default BookingInfo;
