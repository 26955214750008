import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CancelOrder, getOrderById } from "../../service/api";
import { ThreeDots } from "react-loader-spinner";
import "./style.css";
import PeparIcon from "../../assets/icons/Paper.svg";
import CalenderIcon from "../../assets/icons/Calendar.svg";
import WatchIcon from "../../assets/icons/Time Circle-gray.svg";
import GraydogIcon from "../../assets/icons/gray-dog.svg";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const BookedPlan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.bookingID || "";
  const [bookedOrder, setBookedOrder] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getBookedPlanHere(id);
  }, [id]);

  const getBookedPlanHere = async (id) => {
    try {
      const orderData = await getOrderById(id);
      setIsLoading(false);
      setBookedOrder(orderData.data);
      console.log(orderData)
    } catch (error) {
      console.log("Error on get orderby id", error);
    }
  };

  const onCancelOrder = async (id) => {
    Swal.fire({
      title: "Are you sure you want to cancel your booking?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7B5BFB",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const bodySend = {
            id: id,
          };

          const cancelData = await CancelOrder(bodySend);
          if (cancelData.success === "1") {
            Swal.fire({
              title: "Success",
              text: "Order Canceled Successfully",
              icon: "success",
              showConfirmButton: false,
              confirmButtonText: "OK",
              willClose: (isConfirmed) => {
                if (isConfirmed) {
                  navigate("/mybookings");
                  setTimeout(() => {
                    window.scrollTo(0, 0);
                  }, 500);
                }
              },
              timer: 1000,
              timerProgressBar: true,
            }).then((confirm) => { });
          }
        } catch (error) {
          console.log("Error on Cacncel Order", error);
        }
      }
    });
  };

  const onRescheduleOrder = (id) => {
    // navigate("/timeSlot");
    const naviData = {
      fromPage: "bookedplan",
      id: id,
    };
    navigate("/timeSlot", { state: { fromWhich: naviData } });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  return (
    <div className="container mb-5 bookingContainer">
      <div className="row">
        <p className="text-center bookingHeading">Your Bookings </p>
        {isLoading && (
          <div className="d-flex justify-content-center">
            <ThreeDots color="#7B5BFB" height={50} width={50} />
          </div>
        )}
        {!isLoading && (
          <>
            {" "}
            <div className="col-md-12">
              <div className="booking-top">
                <p
                  className={
                    "text-center b-sts " +
                    (bookedOrder.status === "New"
                      ? " new-color"
                      : bookedOrder.status === "Accepted"
                        ? " accept-color"
                        : bookedOrder.status === "Scheduled" ||
                          bookedOrder.status === "Reschedule"
                          ? " schedule-color"
                          : bookedOrder.status === "Canceled" ||
                            bookedOrder.status === "Rejected" ||
                            bookedOrder.status === "Failed"
                            ? " canceled-color"
                            : bookedOrder.status === "Proceed" ||
                              bookedOrder.status === "Preparing" ||
                              bookedOrder.status === "Dispatched" ||
                              bookedOrder.status === "Out-For-Delivery" ||
                              bookedOrder.status === "Refund"
                              ? " proceed-color"
                              : bookedOrder.status === "Reached-Location"
                                ? " reach-loc-color"
                                : bookedOrder.status === "Completed" ||
                                  bookedOrder.status === "Delivered"
                                  ? " completed-color"
                                  : "")
                  }
                >
                  {bookedOrder.status_lable}
                </p>
                <p className="bt-title m-0">{bookedOrder.items[0].title}</p>
                <div className="reco-bottom-price">
                  <p className="rec-oPrice m-0 me-3">
                    ₹ {bookedOrder.items[0].offer_price}
                  </p>
                  <span className="reco-gray me-3">
                    Save Up to ₹{bookedOrder.items[0].total - bookedOrder.items[0].offer_price}
                  </span>{" "}
                  <p className="reco-pri m-0">
                    <s>₹ {bookedOrder.items[0].total}</s>
                  </p>
                </div>
              </div>
              <div key={bookedOrder.id} className="order-item my-2">
                <div className="book-flex">
                  <p className="item-title m-0">Booking Details</p>
                </div>

                <div className="book-flex">
                  <p className="item-d-text d-flex m-0">
                    <img className="b-icon" src={PeparIcon} alt="icon" /> Booking ID:
                  </p>
                  <span className="item-d-value">{bookedOrder.booking_id}</span>
                </div>

                <div className="book-flex">
                  <p className="item-d-text d-flex m-0">
                    <img className="b-icon" src={CalenderIcon} alt="icon" /> Booking
                    Date :{" "}
                  </p>
                  <span className="item-d-value">{bookedOrder.created_at}</span>
                </div>

                {bookedOrder.appoinment_date && (
                  <div className="book-flex">
                    <p className="item-d-text d-flex m-0">
                      <img className="b-icon" src={CalenderIcon} alt="icon" /> Appoinment Date :{" "}
                    </p>
                    <span className="item-d-value">{bookedOrder.appoinment_date}</span>
                  </div>
                )}

                {bookedOrder.appoinment_time && (
                  <div className="book-flex">
                    <p className="item-d-text d-flex m-0">
                      <img className="b-icon" src={WatchIcon} alt="icon" /> Appoinment Time :{" "}
                    </p>
                    <span className="item-d-value">{bookedOrder.appoinment_time}</span>
                  </div>
                )}

                {bookedOrder.pet_name && (
                  <div className="book-flex">
                    <p className="item-d-text d-flex m-0">
                      <img className="b-icon"  src={GraydogIcon} alt="icon" /> Pet Name :{" "}
                    </p>
                    <span className="item-d-value">{bookedOrder.pet_name}</span>
                  </div>
                )}


                <hr />
                <div className="order-items" id="order-items">
                  <div className="book-flex">
                    <p className="item-title">Payment Mode : </p>
                    <span className="item-d-value-cod">
                      {bookedOrder.payment_mode}
                    </span>
                  </div>
                  <div className="book-flex">
                    <p className="item-d-text m-0">Item Total : </p>
                    <span className="item-d-value">
                      {bookedOrder.item_total}
                    </span>
                  </div>
                  <div className="book-flex">
                    <p className="item-d-text">Discount : </p>
                    <span className="item-d-value">{bookedOrder.discount}</span>
                  </div>
                </div>
                <div className="book-flex mt-1">
                  <p className="item-title">Total Payable : </p>
                  <span className="item-d-value">
                    {bookedOrder.grand_total}
                  </span>
                </div>
              </div>
            </div>
            <div className="btm-btn">
              <div className="button-group d-flex justify-content-center flex-wrap">
                {bookedOrder.prescription_details && (
                  <button
                    className="prescription-btn my-1 text-center mx-2"
                    onClick={() => {
                      navigate('/prescription', {
                        state: {
                          pdfType: 'Prescription',
                          data: {
                            owner: {
                              name:    bookedOrder.contact_person,
                              contact:  bookedOrder.contact_number,
                              address: bookedOrder.address
                            },
                            pet: {
                              pet_name:  bookedOrder.pet_name,
                              pet_age:  bookedOrder.pet_age,
                              pet_gender:   bookedOrder.pet_gender,
                              pet_breed: bookedOrder.pet_breed,
                              pet_vaccinated:   bookedOrder.pet_vaccinated,
                            },
                            vendor: {
                              vendor_name:  bookedOrder.vendor_name,
                              vendor_contact:  bookedOrder.vendor_contact,
                              vendor_degree:  bookedOrder.vendor_degree,
                              vendor_signature: bookedOrder.vendor_sign,
                            },
                            prescription: bookedOrder.prescription_details,
                          },
                        },
                      });
                    }}
                  >
                    View Prescription
                  </button>
                )}

                {bookedOrder.vaccine_details && (
                  <button
                    className="vaccine-btn my-1 text-center mx-2"
                    onClick={() => {
                      navigate('/vaccine', {
                        state: {
                          pdfType: 'Vaccine',
                          data: {
                            owner: {
                              name:  bookedOrder.contact_person,
                              contact:  bookedOrder.contact_number,
                              address:  bookedOrder.address
                            },
                            pet: {
                              pet_name:  bookedOrder.pet_name,
                              pet_age:  bookedOrder.pet_age,
                              pet_gender: bookedOrder.pet_gender,
                              pet_breed:  bookedOrder.pet_breed,
                              pet_vaccinated:  bookedOrder.pet_vaccinated,
                            },
                            vendor: {
                              vendor_name:  bookedOrder.vendor_name,
                              vendor_contact: bookedOrder.vendor_contact,
                              vendor_degree: bookedOrder.vendor_degree,
                              vendor_signature: bookedOrder.vendor_sign,
                            },
                            vaccine: bookedOrder.vaccine_details,
                          },
                        },
                      });
                    }}
                  >
                    View Vaccine
                  </button>
                )}
              </div>

              <button
                className={
                  "reschedule-btn my-1 text-center mx-2 " +
                  (bookedOrder.status === "New" ||
                    bookedOrder.status === "Accepted"
                    ? ""
                    : "d-none")
                }
                onClick={() => {
                  onRescheduleOrder(bookedOrder.id);
                }}
              >
                Reschedule
              </button>
              <button
                className={
                  "cancel-btn my-1 text-center mx-2 " +
                  (bookedOrder.status === "New" ||
                    bookedOrder.status === "Accepted" ||
                    bookedOrder.status === "Reschedule"
                    ? ""
                    : "d-none")
                }
                onClick={() => {
                  onCancelOrder(bookedOrder.id);
                }}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BookedPlan;
