import React from 'react';
import './VetCover.css'; // Import the CSS file
import vetImage from '../../assets/images/vetcover.png'; // Replace with your actual image path

const VetCover = () => {
  return (
    <div className="vet-cover">
      <img
        src={vetImage}
        alt="Vet Cloth"
      />
      <div className="vet-cover-text">
        <h1>
          Protect the pet<br />you love, for life
        </h1>
      </div>
    </div>
  );
}

export default VetCover;
