import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated import
import GroomingImage from '../../assets/images/groom-dog.jpg';
import FoodImage from '../../assets/images/foodBanner4.jpeg';
import ShampooImage from '../../assets/images/shampooBack.jpeg';
import VetImage from '../../assets/images/vet-dog.jpg';
import { getDashboard } from '../../service/api';

function LandingProduct() {
    const navigate = useNavigate(); 



    const navigateTo = (path) => {
        navigate(path);
    };

    return (
        <div className='container mt-3'>
            <h3 className="landing-services-title" style={{color:'#F29A4C'}}>DearPet Products</h3>
            <div className='landingcard-container'>
                <div className="landing-service-card">
                    <img src={FoodImage} alt={'Grooming'} className="landing-service-image" />
                    <h3 className="service-title mt-3 mb-2">Food</h3>
                    <div className="service-content">
                        <button className="banner-card-btn" style={{backgroundColor:'#F29A4C'}} onClick={()=>{
                            navigateTo("/dearpet-foods")
                        }}>
                            Buy Now
                        </button>
                    </div>
                </div>
                <div className="landing-service-card">
                    <img src={ShampooImage} alt={'Veterinary'} className="landing-service-image" />
                    <h3 className="service-title mt-3 mb-2">Shampoo</h3>
                    <div className="service-content">
                        <button className="banner-card-btn" style={{backgroundColor:'#F29A4C'}} onClick={()=>{
                            navigate('/product/176');
                        }}>
                            Buy Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LandingProduct;
