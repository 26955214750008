import React, { useEffect, useState } from 'react'
import { getProductPlans } from '../../../service/api';
import FoodGridCard from '../../../component/Card/FoodPlanCard';

function FoodMeal() {
    const [foodPlans, setFoodPlans] = useState([]);

    useEffect(() => {
        fetchPlans();
    },[]);

    const fetchPlans = async() =>{
        const sendData = {     
                "category_id": "5",
                "city": "Delhi",
                "pet": "Dog",
                "city_id": "3",
                "hide": true,
                "isMultipleCity":false,
                "isProduct":true
       
        };
     
        const food = await getProductPlans(sendData);
        setFoodPlans(food.data);
    }

   

  return (
    <div className='container' style={{margin:'0 auto'}}>
        <h2 className='food-title'>DearPet Meals</h2>
        <div className="food-grid ">
                {foodPlans.map(plan => (
                    <FoodGridCard
                        key={plan.id}
                        data={plan}
                    />
                ))}
            </div>
    </div>
  )
}

export default FoodMeal