import React from 'react';
import naturalfresh from '../../../assets/images/natural.svg';
import naturalfreshmb from '../../../assets/images/naturalmb.svg';
import './Food.css'; // Import the CSS file for media queries

function Natural() {
  return (
    <div className='container p-0'>
      {/* Image for larger screens */}
      <img
        src={naturalfresh}
        className="desktop-image"
        alt="Natural Fresh"
      />
      {/* Image for mobile screens */}
      <img
        src={naturalfreshmb}
        className="mobile-image"
        alt="Natural Fresh Mobile"
      />
    </div>
  );
}

export default Natural;
