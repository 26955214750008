import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// If you want to use additional modules
import { Pagination, Navigation, Autoplay } from 'swiper/modules';


function ProductBanner() {
    const images = [
      'https://via.placeholder.com/800x400?text=Slide+1',
      'https://via.placeholder.com/800x400?text=Slide+2',
      'https://via.placeholder.com/800x400?text=Slide+3',
    ];
  
    return (
      <div className="product-banner">
        <Swiper
          modules={[Pagination, Navigation, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
          loop={true}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt={`Slide ${index + 1}`} style={{ width: '100%', height: '400px' }} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }
  
  export default ProductBanner;
  