import React, { useEffect, useState } from 'react'
import { getDashboard } from '../../service/api';
import './ProductHome.css'
import { useNavigate } from 'react-router-dom';

function ProductCategories() {
    const navigate = useNavigate();
    const [catList, setCatList] = useState([]);

    useEffect(() => {
        window.scroll(0, 0);
        dashboardData();
    }, []);

    const dashboardData = async () => {
        try {
            const dashboard = JSON.parse(localStorage.getItem("dashboardData"));
            if (!dashboard) {
                const dashboardData = await getDashboard();
                if (dashboardData.status === '200') {
                    setCatList(dashboardData.data.products);
                }
            } else {
                setCatList(dashboard.products);
            }
        } catch (error) {
            console.error("Error in get home page data:", error);
        }
    };


    const handleCategoryClick = (categoryId) => {
        // console.log(categoryId);
        navigate('/products-list', { state: { catId: categoryId } });
    };

    return (
        <div className='container-xxl mb-4'>
            <h2 className="landing-services-title">DearPet Categories</h2>
            <div className="product-categories ">
                {catList.map((category) => (
                    <div
                        key={category.id}
                        className="category-card"
                        style={{
                            backgroundImage: `linear-gradient(to bottom right, ${category.color1}, ${category.color2})`,

                        }}
                        onClick={() => handleCategoryClick(category.id)}
                    >
                        <div className="category-content">
                            <img src={category.image} alt={category.title} className="category-image" />
                            <span className="category-title">{category.title}</span>
                        </div>
                    </div>

                ))}
            </div>
        </div>
    )
}

export default ProductCategories