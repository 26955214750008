import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function VaccineForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    owner: {
      name: '',
      contact: '',
    },
    pet: {
      pet_name: '',
      pet_age: '',
      pet_gender: '',
      pet_breed: '',
      pet_vaccinated: ''
    },
    vaccine: [
      {
        vaccine_name: '',
        vaccine_batch_id: '',
        vaccine_mfg_date: '',
        vaccine_expiry_date: '',
        vaccine_used_date: '',
        vaccine_next_date: '',
        vaccine_type: '',
        vaccine_doctor: '',
        vaccine_degree: ''
      }
    ]
  });

  const handleInputChange = (section, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value
      }
    }));
  };

  const handleVaccineChange = (index, field, value) => {
    const updatedVaccines = [...formData.vaccine];
    updatedVaccines[index][field] = value;
    setFormData({ ...formData, vaccine: updatedVaccines });
  };

  const handleAddVaccine = () => {
    setFormData({
      ...formData,
      vaccine: [
        ...formData.vaccine,
        {
          vaccine_name: '',
          vaccine_batch_id: '',
          vaccine_mfg_date: '',
          vaccine_expiry_date: '',
          vaccine_used_date: '',
          vaccine_next_date: '',
          vaccine_type: '',
          vaccine_doctor: '',
          vaccine_degree: ''
        }
      ]
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Grouping vaccines by vaccine_type
    const groupedVaccines = formData.vaccine.reduce((acc, vaccine) => {
      const { vaccine_type } = vaccine;
      if (!acc[vaccine_type]) {
        acc[vaccine_type] = [];
      }
      acc[vaccine_type].push(vaccine);
      return acc;
    }, {});

    // Prepare the data to be sent to the next page
    const groupedData = {
      ...formData,
      vaccine: groupedVaccines
    };

    // Send grouped data to the next page
    navigate('/vac-down', { state: { data: groupedData } });
  };

  const handleDateChange = (index, field, value, isManufactureOrExpiryDate = false) => {
    const regex = isManufactureOrExpiryDate ? /^(0[1-9]|1[0-2])-\d{4}$/ : /^\d{2}-\d{2}-\d{4}$/;
    if (regex.test(value) || value === "") {
      handleVaccineChange(index, field, value);
    }
  };

  const formatDate = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(date).toLocaleDateString('en-GB', options).replace(/\//g, '-');
  };

  const formatDateToInput = (date) => {
    // Converts DD-MM-YYYY to YYYY-MM-DD for displaying in input[type="date"]
    const [day, month, year] = date.split("-");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="vf-vaccine-form">
      <style>
        {`
          .vf-vaccine-form {
            max-width: 800px;
            margin: 2rem auto;
            background: #ffffff;
            padding: 5rem;
            border-radius: 8px;
            box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
          }
          .vf-vaccine-form h2 {
            text-align: center;
            color: #2c3e50;
          }
          .vf-vaccine-form h3 {
            color: #2980b9;
            margin-bottom: 10px;
          }
          .vf-form-section {
            margin-bottom: 1.5rem;
          }
          .vf-form-section label {
            font-weight: bold;
            display: block;
            margin-bottom: 5px;
          }
          .vf-vaccine-form input[type="text"],
          .vf-vaccine-form input[type="number"],
          .vf-vaccine-form input[type="date"] {
            width: 100%;
            padding: 12px;
            border: 1px solid #ddd;
            border-radius: 5px;
            margin-bottom: 10px;
            font-size: 14px;
            transition: all 0.3s ease;
          }
          .vf-vaccine-form input[type="text"]:focus,
          .vf-vaccine-form input[type="number"]:focus,
          .vf-vaccine-form input[type="date"]:focus {
            border-color: #2980b9;
            outline: none;
          }
          .vf-vaccine-form button {
            background-color: #2980b9;
            color: white;
            border: none;
            padding: 12px 20px;
            border-radius: 5px;
            font-size: 16px;
            cursor: pointer;
            transition: all 0.3s ease;
          }
          .vf-vaccine-form button:hover {
            background-color: #3498db;
          }
          .vf-vaccine-entry {
            padding: 15px;
            border: 1px solid #ddd;
            margin-bottom: 1rem;
            border-radius: 5px;
            background-color: #f9f9f9;
          }
          .vf-vaccine-entry h4 {
            margin-bottom: 10px;
            color: #2980b9;
          }
          .vf-vaccine-entry label {
            color: #2c3e50;
          }
          .vf-vaccine-entry input {
            margin-bottom: 10px;
          }
          .vf-vaccine-entry button {
            background-color: #e74c3c;
            margin-top: 10px;
            font-size: 14px;
          }
          .vf-vaccine-entry button:hover {
            background-color: #c0392b;
          }
        `}
      </style>

      <h2>Vaccine Details Form</h2>
      <form onSubmit={handleSubmit}>
        {/* Owner Section */}
        <div className="vf-form-section">
          <h3>Owner Details</h3>
          <label>Name:</label>
          <input
            type="text"
            value={formData.owner.name}
            onChange={(e) => handleInputChange('owner', 'name', e.target.value)}
            required
          />
          <label>Contact:</label>
          <input
            type="text"
            value={formData.owner.contact}
            onChange={(e) => handleInputChange('owner', 'contact', e.target.value)}
            required
          />
        </div>

        {/* Pet Section */}
        <div className="vf-form-section">
          <h3>Pet Details</h3>
          <label>Name:</label>
          <input
            type="text"
            value={formData.pet.pet_name}
            onChange={(e) => handleInputChange('pet', 'pet_name', e.target.value)}
            required
          />
          <label>Age:</label>
          <input
            type="text"
            value={formData.pet.pet_age}
            onChange={(e) => handleInputChange('pet', 'pet_age', e.target.value)}
            required
          />
          <label>Gender:</label>
          <input
            type="text"
            value={formData.pet.pet_gender}
            onChange={(e) => handleInputChange('pet', 'pet_gender', e.target.value)}
            required
          />
          <label>Breed:</label>
          <input
            type="text"
            value={formData.pet.pet_breed}
            onChange={(e) => handleInputChange('pet', 'pet_breed', e.target.value)}
            required
          />
          <label>Vaccinated:</label>
          <input
            type="text"
            value={formData.pet.pet_vaccinated}
            onChange={(e) => handleInputChange('pet', 'pet_vaccinated', e.target.value)}
            required
          />
        </div>

        {/* Vaccine Section */}
        <div className="vf-form-section">
          <h3>Vaccine Details</h3>
          {formData.vaccine.map((vaccine, index) => (
            <div key={index} className="vf-vaccine-entry">
              <h4>Vaccine {index + 1}</h4>
              <label>Vaccine Name:</label>
              <input
                type="text"
                value={vaccine.vaccine_name}
                onChange={(e) => handleVaccineChange(index, 'vaccine_name', e.target.value)}
                required
              />
              <label>Vaccine Batch ID:</label>
              <input
                type="text"
                value={vaccine.vaccine_batch_id}
                onChange={(e) => handleVaccineChange(index, 'vaccine_batch_id', e.target.value)}
                required
              />
              <label>Manufacture Date (MM-YYYY):</label>
              <input
                type="text"
                value={vaccine.vaccine_mfg_date}
                onChange={(e) => handleVaccineChange(index, 'vaccine_mfg_date', e.target.value, true)}
                
              />
              <label>Expiry Date (MM-YYYY):</label>
              <input
                type="text"
                value={vaccine.vaccine_expiry_date}
                onChange={(e) => handleVaccineChange(index, 'vaccine_expiry_date', e.target.value, true)}
                required
              />
              <label>Used Date (DD-MM-YYYY):</label>
              <input
                type="date"
                placeholder={vaccine.vaccine_used_date}
                value={formatDateToInput(vaccine.vaccine_used_date)}
                onChange={(e) => handleVaccineChange(index, 'vaccine_used_date', formatDate(e.target.value))}
                required
              />
              <label>Next Due Date (DD-MM-YYYY):</label>
              <input
                type="date"
                placeholder={vaccine.vaccine_next_date}
                value={formatDateToInput(vaccine.vaccine_next_date)}
                onChange={(e) => handleVaccineChange(index, 'vaccine_next_date', formatDate(e.target.value))}
                required
              />
              <label>Doctor Name:</label>
              <input
                type="text"
                value={vaccine.vaccine_doctor}
                onChange={(e) => handleVaccineChange(index, 'vaccine_doctor', e.target.value)}
                required
              />
              <label>Degree:</label>
              <input
                type="text"
                value={vaccine.vaccine_degree}
                onChange={(e) => handleVaccineChange(index, 'vaccine_degree', e.target.value)}
                required
              />
            </div>
          ))}
          <button type="button" onClick={handleAddVaccine}>Add Vaccine</button>
        </div>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default VaccineForm;
