import React from 'react';
import AtHome from '../../../assets/icons/vet_at_home.svg';
import OnCall from '../../../assets/icons/vet_on_call.svg';
import './OurServices.css';
import { useNavigate } from 'react-router-dom';

function OurServices() {
  const navigate = useNavigate();
  
  const services = [
    {
      id: 1,
      icon: AtHome,
      title: "At-Home Vet Services",
      description: "Convenient, Professional Care in the Comfort of Your Home",
      services: ["Vaccinations", "Blood Tests", "Health Check-ups & Treatments"],
      buttonText: "Book an At-Home Visit",
      onTap:() => {
        navigate("/vet-at-home-services");
      }
    },
    {
      id: 2,
      icon: OnCall,
      title: "Vet-on-Call Services",
      description: "Expert Advice via Video Consultation, Anytime",
      services: ["Gut Issues", "Skin Problems", "Behavioral Advice & More"],
      buttonText: "Book a Video Consultation",
      onTap:() =>{
        navigate("/vet-on-call", { state: { catId: "3", petType: "Both", selectedCityId: "3" } });
      }
    }
    
  ];

  return (
    <div className="our-services-container">
      <h3 className='our-service-title'>Our Services</h3>
      <div className="our-services-grid">
        {services.map((service) => (
          <Card
            key={service.id}
            icon={service.icon}
            title={service.title}
            description={service.description}
            services={service.services}
            buttonText={service.buttonText}
            onTap={service.onTap}
          />
        ))}
      </div>
    </div>
  );
}

const Card = ({ icon, title, description, services, buttonText, onTap }) => {
  return (
    <div className="v-card">
      <div className="v-card-icon">
        <img src={icon} alt={title} />
      </div>
      <h2 className="v-card-title">{title}</h2>
      <p className="v-card-subtitle">{description}</p>
      <ul className="v-card-services">
        {services.map((service, index) => (
          <li key={index}>{service}</li>
        ))}
      </ul>
      <button className="v-card-button" onClick={() =>{
        onTap();
      }}>
        {buttonText}
      </button>
    </div>
  );
};

export default OurServices;
