import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function PrescriptionForm() {
  const navigate = useNavigate();

  // Initial state to capture form data
  const [formData, setFormData] = useState({
    owner: {
      name: '',
      contact: '',
    },
    pet: {
      pet_name: '',
      pet_age: '',
      pet_gender: '',
      pet_breed: '',
      pet_vaccinated: '',
    },
    vendor: {
      vendor_name: '',
      vendor_degree: '',
      vendor_signature: 'https://dearpet.co/uploads/signature/1687607719957602814.png',
    },
    prescription: {
      custom_prescription_id: '',
      complaints: '',
      diagnosis: '',
      medication: '',
      advice_notes: '',
      diet_chart: [],
      updated_at: '',
    },
  });

  // Update state on input change
  const handleChange = (section, field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [field]: value,
      },
    }));
  };

  const handleDateChange = (field, subField, value) => {
    if (field === 'prescription' && subField === 'updated_at') {
      const date = new Date(value);
      const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
  
      setFormData(prevFormData => ({
        ...prevFormData,
        [field]: {
          ...prevFormData[field],
          [subField]: formattedDate
        }
      }));
    } 
  };
  

  // Add a new diet chart entry
  const handleAddDietChart = () => {
    setFormData((prevState) => ({
      ...prevState,
      prescription: {
        ...prevState.prescription,
        diet_chart: [
          ...prevState.prescription.diet_chart,
          { time: '', diet: '' },
        ],
      },
    }));
  };

  // Update diet chart entry
  const handleDietChartChange = (index, field, value) => {
    const updatedDietChart = [...formData.prescription.diet_chart];
    updatedDietChart[index][field] = value;
    setFormData((prevState) => ({
      ...prevState,
      prescription: {
        ...prevState.prescription,
        diet_chart: updatedDietChart,
      },
    }));
  };

  // Remove diet chart entry
  const handleRemoveDietChart = (index) => {
    const updatedDietChart = formData.prescription.diet_chart.filter(
      (_, i) => i !== index
    );
    setFormData((prevState) => ({
      ...prevState,
      prescription: {
        ...prevState.prescription,
        diet_chart: updatedDietChart,
      },
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/presc-down', { state: { data: formData } });
  };

  return (
    <div className="pf-form-container">
      <h2>Prescription Form</h2>
      <form onSubmit={handleSubmit}>
        {/* Owner Section */}
        <fieldset>
          <legend>Owner Details</legend>
          <input
            type="text"
            className="pf-input-field"
            placeholder="Owner Name"
            value={formData.owner.name}
            onChange={(e) => handleChange('owner', 'name', e.target.value)}
          />
          <input
            type="text"
            className="pf-input-field"
            placeholder="Contact"
            value={formData.owner.contact}
            onChange={(e) => handleChange('owner', 'contact', e.target.value)}
          />
        </fieldset>

        {/* Pet Section */}
        <fieldset>
          <legend>Pet Details</legend>
          <input
            type="text"
            className="pf-input-field"
            placeholder="Name"
            value={formData.pet.pet_name}
            onChange={(e) => handleChange('pet', 'pet_name', e.target.value)}
          />
          <input
            type="text"
            className="pf-input-field"
            placeholder="Age"
            value={formData.pet.pet_age}
            onChange={(e) => handleChange('pet', 'pet_age', e.target.value)}
          />
          <input
            type="text"
            className="pf-input-field"
            placeholder="Gender"
            value={formData.pet.pet_gender}
            onChange={(e) => handleChange('pet', 'pet_gender', e.target.value)}
          />
          <input
            type="text"
            className="pf-input-field"
            placeholder="Breed"
            value={formData.pet.pet_breed}
            onChange={(e) => handleChange('pet', 'pet_breed', e.target.value)}
          />
          <input
            type="text"
            className="pf-input-field"
            placeholder="Vaccinated"
            value={formData.pet.pet_vaccinated}
            onChange={(e) => handleChange('pet', 'pet_vaccinated', e.target.value)}
          />
        </fieldset>

        {/* Vendor Section */}
        <fieldset>
          <legend>Vendor Details</legend>
          <input
            type="text"
            className="pf-input-field"
            placeholder="Vendor Name"
            value={formData.vendor.vendor_name}
            onChange={(e) => handleChange('vendor', 'vendor_name', e.target.value)}
          />
          <input
            type="text"
            className="pf-input-field"
            placeholder="Vendor Degree"
            value={formData.vendor.vendor_degree}
            onChange={(e) => handleChange('vendor', 'vendor_degree', e.target.value)}
          />
          <input
            type="text"
            className="pf-input-field"
            placeholder="Vendor Signature"
            value={formData.vendor.vendor_signature}
            onChange={(e) => handleChange('vendor', 'vendor_signature', e.target.value)}
          />
        </fieldset>

        {/* Prescription Section */}
        <fieldset>
          <legend>Prescription Details</legend>
          <input
            type="text"
            className="pf-input-field"
            placeholder="Custom Prescription ID"
            value={formData.prescription.custom_prescription_id}
            onChange={(e) => handleChange('prescription', 'custom_prescription_id', e.target.value)}
          />
          <textarea
            className="pf-input-field"
            placeholder="Complaints"
            value={formData.prescription.complaints}
            onChange={(e) => handleChange('prescription', 'complaints', e.target.value)}
          />
          <textarea
            className="pf-input-field"
            placeholder="Diagnosis"
            value={formData.prescription.diagnosis}
            onChange={(e) => handleChange('prescription', 'diagnosis', e.target.value)}
          />
          <textarea
            className="pf-input-field"
            placeholder="Medication"
            value={formData.prescription.medication}
            onChange={(e) => handleChange('prescription', 'medication', e.target.value)}
          />
          <textarea
            className="pf-input-field"
            placeholder="Advice Notes"
            value={formData.prescription.advice_notes}
            onChange={(e) => handleChange('prescription', 'advice_notes', e.target.value)}
          />

          {/* <input
            type="text"
            className="pf-input-field"
            placeholder="Date and Time"
            value={formData.prescription.updated_at}
            onChange={(e) => handleChange('prescription', 'updated_at', e.target.value)}
          /> */}

          <input
            type="datetime-local"
            className="pf-input-field"
            placeholder="Date and Time"
            value={formData.prescription.updated_at}
            onChange={(e) => handleDateChange('prescription', 'updated_at', e.target.value)}
          />

          {/* Diet Chart Section */}
          <div>
            <h4>Diet Chart</h4>
            {formData.prescription.diet_chart.map((entry, index) => (
              <div key={index} className="pf-diet-entry">
                <input
                  type="text"
                  className="pf-input-field"
                  placeholder="Time"
                  value={entry.time}
                  onChange={(e) => handleDietChartChange(index, 'time', e.target.value)}
                />
                <input
                  type="text"
                  className="pf-input-field"
                  placeholder="Diet Description"
                  value={entry.diet}
                  onChange={(e) => handleDietChartChange(index, 'diet', e.target.value)}
                />
                <button
                  type="button"
                  className="pf-remove-diet-btn"
                  onClick={() => handleRemoveDietChart(index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button type="button" className="pf-add-diet-btn" onClick={handleAddDietChart}>
              Add Diet Entry
            </button>
          </div>
        </fieldset>

        <button type="submit" className="pf-submit-btn">Submit</button>
      </form>

      {/* CSS Styling */}
      <style>{`
        .pf-form-container {
          max-width: 800px;
          margin: 0 auto;
          padding: 100px;
          background-color: #f9f9f9;
          border-radius: 8px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        fieldset {
          border: none;
          margin-bottom: 20px;
        }

        .pf-input-field {
          width: 100%;
          padding: 10px;
          margin: 8px 0;
          border: 1px solid #ccc;
          border-radius: 4px;
        }

        .pf-input-field:focus {
          border-color: #007bff;
          outline: none;
        }

        .pf-submit-btn {
          width: 100%;
          padding: 10px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          font-size: 16px;
        }

        .pf-submit-btn:hover {
          background-color: #0056b3;
        }

        .pf-diet-entry {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        }

        .pf-remove-diet-btn {
          background-color: #ff4136;
          color: white;
          border: none;
          padding: 5px;
          cursor: pointer;
          border-radius: 4px;
        }

        .pf-remove-diet-btn:hover {
          background-color: #c0392b;
        }

        .pf-add-diet-btn {
          background-color: #28a745;
          color: white;
          border: none;
          padding: 10px;
          cursor: pointer;
          border-radius: 4px;
        }

        .pf-add-diet-btn:hover {
          background-color: #218838;
        }
      `}</style>
    </div>
  );
}

export default PrescriptionForm;
