import React, { useEffect } from "react";

import BenefitAtHome from "../BenefitAtHome/BenefitAtHome";
import CatService from "../CatServices/CatServices";
import DogService from "../DogServices/DogServices";
import HealthCheckUp from "../HealthCheckUp/HealthCheckUp";
import StarVets from "../StarVets/StarVets";

import TestiMonials from "../TestiMonials/TestiMonials";
import "./homestyle.css"; 
import BannerPage from "../BannerPage/BannerPage";
import Work from "../Work/Work";
import TestimonialSlider from "./TestimonialSlider";
import WhyChoose from "./Components/WhyChoose";
import OurServices from "./Components/OurServices";
import HowItWorks from "./Components/HowItWorks";
import HighlightPoints from "./Components/HighlightPoints";
import HighlightPointsV2 from "./Components/HighlightPointsV2";

export default function VetAtHome() {

  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  return (
    <div style={{ backgroundColor: '#f5f5f5', paddingTop:'70px' }}>
      {/* <BannerPage /> */}
      <Work />
      {/* <HealthCheckUp /> */}
      {/* <HowItWorks /> */}
      {/* <HighlightPoints /> */}
      <BenefitAtHome />
      <WhyChoose />
      <StarVets />
      <TestimonialSlider />
    </div>
  );
}
