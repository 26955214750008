import React, { useState } from 'react';
import '../Foods/Food.css';

function ProductDescription({ productDetails, color }) {
  const [view, setView] = useState('description');

  const buttonStyles = {
    padding: '10px 20px',
    border: '1px solid',
    borderColor: color,
    backgroundColor: 'white',
    color: color,
    fontWeight: 'bold',
    cursor: 'pointer',
    textAlign: 'center',
  };
  
  const activeButtonStyles = {
    ...buttonStyles,
    backgroundColor: color,
    color: 'white',
  };

  // Determine border radius based on which buttons are available
  const singleButtonRadius = '10px';
  const leftButtonRadius = productDetails?.info ? '10px 0 0 10px' : singleButtonRadius;
  const rightButtonRadius = productDetails?.description ? '0 10px 10px 0' : singleButtonRadius;

  return (
    <div style={{ padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
      <div style={{ marginBottom: '20px', display: 'flex' }}>
        {productDetails?.description && (
          <button
            onClick={() => setView('description')}
            style={{
              ...(view === 'description' ? activeButtonStyles : buttonStyles),
              borderRadius: leftButtonRadius,
            }}
            className="responsive-desc-button"
          >
            Description
          </button>
        )}

        {productDetails?.info && (
          <button
            onClick={() => setView('nutrition')}
            style={{
              ...(view === 'nutrition' ? activeButtonStyles : buttonStyles),
              borderRadius: rightButtonRadius,
            }}
            className="responsive-desc-button"
          >
            Nutrition
          </button>
        )}
      </div>

      <div>
        {view === 'description' && productDetails?.description && (
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: productDetails?.description?.replace(/\n/g, '<br />'),
              }}
            />
          </div>
        )}

        {view === 'nutrition' && productDetails?.info && (
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: productDetails?.info?.replace(/\n/g, '<br />'),
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductDescription;
