import React from "react";
import Img1 from "../../assets/nurturing-bonds/img1.svg"
import Img2 from "../../assets/nurturing-bonds/img2.svg"
import Img3 from "../../assets/nurturing-bonds/img3.svg"
import Img4 from "../../assets/nurturing-bonds/img4.svg"
import Img5 from "../../assets/nurturing-bonds/img5.svg"
import "./style.css"

const DearpetNurturing = () => {
  return (
    <div className="container mt-5">
      <h4 className="text-center nurturing-title mx-5">DearPet: Nurturing Bonds Since 2019.</h4>
      <div className="row mt-2">
        <div className="col-md-12">
            <p className="nuturing-desc">With a legacy of trust and love since 2019, DearPet has dedicatedly served over 10,000 pets across India. We're not just a service; we're a celebration of your pet's well-being. Experience the best in pet care with our trained professionals who treat your furry family with the same love and care that you do.</p>
        </div>
        <div className="col-md-12 firstRowImg">
            <img className="img-fluid top-2-img" src={Img1} alt="img1"/>
            <img className="img-fluid top-2-img" src={Img5} alt="img1"/>
        </div>
        <div className="col-md-12 firstRowImg ir-2">
            <img className="img-fluid top-3-img" src={Img3} alt="img1"/>
            <img className="img-fluid top-3-img" src={Img4} alt="img1"/>
            <img className="img-fluid top-3-img" src={Img2} alt="img1"/>
        </div>

      </div>
    </div>
  );
};

export default DearpetNurturing;
