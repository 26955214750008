import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaStar } from "react-icons/fa";
import doc1 from "../../assets/docImg/doc1.jpeg";
import doc2 from "../../assets/docImg/doc2.jpeg";

import "./style.css";

const vetsData = [
  {
    name: "Dr Monika Yadav",
    role: "Veterinarian",
    description:
      "Dr. Monika Yadav, with 2 years of experience in veterinary services. I earned my B.V.Sc & A.H and now proudly work with DearPet as an online consultant. My focus is on delivering compassionate, expert care to pets, ensuring their health and happiness. Whether it's a simple query or a complex concern, I'm here to guide and support pet parents at every step.",
    rating: 5,
    image: doc1,
  },
  {
    name: "Dr Mohan",
    role: "Veterinary Surgeon",
    description:
      "Meet Dr. Mohan, the heart and soul of DearPet’s veterinary team! With a specialization in small animal surgeries and a degree in B.V.Sc & A.H, Dr. Mohan brings a joyful spirit and have one year of hands-on experience to every pet he cares for. Whether it's a home visit or an online consultation, Dr. Mohan’s warm and friendly approach ensures that your pets feel safe, loved, and well-cared for.",
    rating: 4,
    image: doc2,
  },
  // {
  //   name: "Dr. Emily Davis",
  //   role: "Animal Nutritionist",
  //   description:
  //     "Dr. Emily provides expert advice on pet nutrition and dietary needs.",
  //   rating: 5,
  //   image: "https://via.placeholder.com/150",
  // },
];

export default function StarVets() {
  return (
    <div className="container my-3">
      <p className="benefits-heading">
        Star Veterinarian
      </p>
      <Swiper
        spaceBetween={20}
        slidesPerView={3}
        breakpoints={{
          900: {
            slidesPerView: 3,
          },
          0: {
            slidesPerView: 1,
          },
        }}
        pagination={{ clickable: true }}
      >
        {vetsData.map((vet, index) => (
          <SwiperSlide key={index}>
            <div className="vet-card">
              <img src={vet.image} alt={vet.name} className="vet-image" />
              <h3 className="vet-name">{vet.name}</h3>
              <p className="vet-role">{vet.role}</p>
              <p className="vet-description">{vet.description}</p>
              <div className="vet-rating">
                {[...Array(vet.rating)].map((_, i) => (
                  <FaStar key={i} className="star-icon" />
                ))}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
