import React from 'react'
import FoodDescImage from '../../../assets/images/instruction-food.png';

function FoodDesc({fill}) {
  return (
    <div style={{padding:"10px"}}>
      <img src={FoodDescImage} />
    </div>
  )
}

export default FoodDesc