import React, { useEffect, useState } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import done from "../../assets/images/thumbs-up.png";
import ReactPixel from "react-facebook-pixel";
import { getCartDataForCheckout } from "../../service/api";

const ThankYou = () => {
  const [cartData, setCartData] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  }, [])

  useEffect(() => {
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: true, // enable logs
    };
    ReactPixel.init("258887642266418", "", options);
    ReactPixel.pageView("Thank You");
    ReactPixel.track("CompleteRegistration");
  }, []);

  useEffect(() => {
    // Retrieve cart data from local storage
    const storedCartData = localStorage.getItem('cartData');
    if (storedCartData) {
      setCartData(JSON.parse(storedCartData));
    }
    getCart();
    
  }, []);

  const getCart = async() => {
    const cartBody = {
      cityId:""
    }
    const cartData = await getCartDataForCheckout(cartBody);
    if (cartData) {
      localStorage.setItem('cart_item_count', cartData.data.item_count);
    }
  }

  const gotohome = () => {
    navigate("/");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  return (
    <div className="container mb-5 bookingContainer">
      <div className="row mt-4 px-3">
        <div className="col-md-12 mainRow pt-4">
          <div className="thank-you-container">

            <img src="/thankthumbsup.png" className="thank-you-image" alt="Thank You Image" />
            <div className="thank-you-content">
              <h1 className="thank-you-h1">
                <span>🎉 Booking Confirmed 🎉</span>
              </h1>
              <div>
                <span>Thank you for choosing DearPet!</span>
              </div>
              <div>
                <span>We can’t wait to pamper your Pet!</span>
              </div>
            </div>
            <div className="my-3 px-3 text-center">
              <button
                type="submit"
                className="btn nextBtnH text-center"
                onClick={gotohome}
              >
                Go To Home
              </button>
            </div>
          </div>
          {cartData && (
            <div className=" summeryCard summaryCardTY mt-4 mb-4">
              <div className="h2 summeryTitle p-3"><h2 style={{ fontSize: "20px", fontWeight: "bold" }}>Order Summary</h2></div>
              <ul className="list-group list-group-flush">
                {cartData.items.map((item, index) => (
                  <li key={index} className="list-group-item ">
                    <div className="card-body pb-3">
                      <div className="d-flex justify-content-between">
                        <h5 className="itemtotal mb-0">{item.title}</h5>
                        <span className="ruppe">
                          {item.packs
                            ? `${item.quantity} x Pack of ${item.packs} x ${item.offer_price}`
                            : `${item.quantity} x ${item.offer_price}`}
                        </span>
                      </div>


                      <div className="card-text mt-2">
                        {item.appoinment_date && (
                          <div style={{ fontSize: "14px" }}><strong>Appointment Date:</strong> {item.appoinment_date}</div>
                        )}
                        {item.appoinment_time && (
                          <div style={{ fontSize: "14px" }}><strong>Appointment Time:</strong> {item.appoinment_time}</div>
                        )}
                        {item.pet_name && (
                          <div style={{ fontSize: "14px" }}><strong>Pet Name:</strong> {item.pet_name}</div>
                        )}
                      </div>
                    </div>
                  </li>

                ))}
              </ul>

              <div className="total mt-3">
                {/* <div className="flexRow my-1">
                  <span className="itemtotal m-0">Discount</span>
                  <span className="ruppe m-0">₹ {cartData?.discount}</span>
                </div> */}
                <hr className="hrLine" />
                <div className="flexRow my-2 p-3">
                  <span className="totaltext m-0">Total</span>
                  <span className="totaltext m-0">₹ {cartData?.grand_total}</span>
                </div>
                {/* <strong>Discount:</strong>{cartData.discount} <br></br>
                <strong>Total:</strong> {cartData.grand_total} */}

              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
