import React from 'react'
import bonebroths from '../../../assets/images/bonebroth.svg';
import bonebrothsmb from '../../../assets/images/bonebroth-mb.svg';
import './Food.css';

function BoneBroth() {
  return (
    <div className='container p-0'>
      {/* Image for larger screens */}
      <img
        src={bonebroths}
        className="bone-desktop-image"
        alt="Bone Broth"
      />
      {/* Image for mobile screens */}
      <img
        src={bonebrothsmb}
        className="bone-mobile-image"
        alt="Bone Broth Mobile"
      />
    </div>
  );
}

export default BoneBroth;
