import React from 'react';
import DogVetImage from '../../assets/images/vet-dog-care.JPG';
import HWOne from '../../assets/images/hw-1.svg';
import HWTwo from '../../assets/images/hw-2.svg';
import HWThree from '../../assets/images/hw-3.svg';
import HWFour from '../../assets/images/hw-4.svg';
import HHTwo from '../../assets/images/hh-2.svg';

import './VetHowWorks.css';

const VetHowWork = ({ width, home }) => {
  const renderSteps = () => {
    if (home) {
      return (
        <>
          <ChooseDetail
            width={width}
            Icon={HWOne}
            title="Book Your Appointment"
            desc="Choose your preferred date and time through our easy-to-use online booking system. You can select the type of service your pet needs."
          />
          <ChooseDetail
            width={width}
            Icon={HHTwo}
            title="Vet Arrives at Your Home"
            desc="At the scheduled time, our professional veterinarian will arrive at your residence, equipped with everything needed to provide top-tier care for your pet."
          />
          <ChooseDetail
            width={width}
            Icon={HWThree}
            title="Comprehensive Consultation"
            desc="The vet will conduct a thorough examination, address any concerns you have, and provide personalized advice on your pet’s health, including diet, exercise, and preventative care."
          />
          <ChooseDetail
            width={width}
            Icon={HWFour}
            title="Follow-Up"
            desc="After the visit, you’ll receive a detailed prescription with recommendations and next steps to ensure your pet’s continued well-being."
          />
        </>
      );
    } else {
      return (
        <>
          <ChooseDetail
            width={width}
            Icon={HWOne}
            title="Select Your Service"
            desc="Choose the type of consultation you require based on your pet’s needs. Whether it’s a general health check-up or a specific concern, we have a service tailored for you."
          />
          <ChooseDetail
            width={width}
            Icon={HWTwo}
            title="Book an Appointment"
            desc="Pick a time slot that works for you. Our platform is user-friendly, allowing you to see the availability of our vets in real-time."
          />
          <ChooseDetail
            width={width}
            Icon={HWThree}
            title="Consult with a Vet"
            desc="At your scheduled time, join the consultation via video or phone call. Discuss your concerns, share any relevant pet health records, and get expert advice directly from our qualified veterinarians."
          />
          <ChooseDetail
            width={width}
            Icon={HWFour}
            title="Follow-Up Care"
            desc="Post-consultation, receive a detailed report with recommendations, prescriptions if necessary, and any follow-up steps to ensure your pet’s health continues to improve."
          />
        </>
      );
    }
  };

  return (
    <div className="container-xxl mb-4">
      <div className="landing-services-title">
        How {home ? 'Home Consultation' : 'Online Consultation'} Works
      </div>
      <div className="flex-container">
        <img
          src={DogVetImage}
          alt="Consultation"
          className="vet-work-image"
        />
        <div className="text-container">
          {renderSteps()}
        </div>
      </div>
    </div>
  );
};

const ChooseDetail = ({ width, Icon, title, desc }) => {
  return (
    <div style={{ margin: 10, display: 'flex', alignItems: 'flex-start' }}>
      <div className="leftIconImg">
        <img style={{ width: '30px' }} src={Icon} alt="iconimg" />
      </div>
      <div style={{ width: width / 1.3, paddingLeft: 20, paddingRight: 10 }}>
        <div style={{ color: '#002244', fontWeight: 'bold', fontSize: '19px' }}>
          {title}
        </div>
        <div style={{ fontSize: '14px', fontWeight: '500' }}>
          {desc}
        </div>
      </div>
    </div>
  );
};

export default VetHowWork;
