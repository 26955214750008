import { useState } from "react";
import "./landingpage.css";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

export default function ReadMore({ city, handleBookNow, plan }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div>
      <span className="text-3xl cursor-pointer" onClick={toggleReadMore}>
        {isExpanded ? "" : <RiArrowDropDownLine />}
      </span>

      {isExpanded && (
        <>
          <div className="sectionTwo">
            <div className="subsectionTwo">
              <h1 className="subHeading">
                Dog Grooming Near Me: Convenient and Professional Care
              </h1>
              <p>
                Searching for "dog grooming near me"? DearPet brings
                professional grooming right to your doorstep. Our expert dog
                groomers close to me specialize in everything from precise dog
                clipping near me to tailored Shih Tzu haircuts. Enjoy
                stress-free dog grooming at home with personalized attention for
                your pet.
              </p>
            </div>
          </div>

          <div className="section">
            <div className="subsection">
              <h1 className="subHeading">
                Cat Grooming Near Me: Specialized Care
              </h1>
              <p>
                Looking for "cat grooming near me"? Our experienced groomers
                provide specialized cat grooming services, ensuring a safe and
                comfortable grooming session for cats of all sizes and
                temperaments.
              </p>
            </div>
          </div>

          <div className="sectionTwo">
            <div className="subsectionTwo">
              <h1 className="subHeading">Comprehensive Pet Grooming Near Me</h1>
              <p>
                At DearPet, we go beyond aesthetics with our comprehensive pet
                grooming services. Our offerings include dog bathing services
                with pet-friendly grooming products, routine trims using
                top-quality dog hair trimmers, and full grooming sessions
                tailored to your pet's needs.
              </p>
            </div>
          </div>

          <div className="section">
            <div className="subsection">
              <h1 className="subHeading">Tips for Dog Grooming at Home</h1>
              <p>
                Want to groom your dog at home? Our experts provide professional
                dog grooming at home tips. From selecting the right dog hair
                trimmer to mastering professional-grade Shih Tzu haircuts, we
                share everything you need to know to make dog grooming at home a
                rewarding experience.
              </p>
            </div>
          </div>

          <div className="sectionTwo">
            <div className="subsectionTwo">
              <h1 className="subHeading">Luxurious Dog Salon Near Me</h1>
              <p>
                Make your home a luxurious dog salon for your pet with DearPet.
                Our expert groomers bring spa baths and Shih Tzu haircuts right
                to your door. Enjoy a calming, luxurious dog grooming experience
                without leaving home. Pamper your furry friend with DearPet and
                Treat Your Loyals like Royals.
              </p>
            </div>
          </div>

          <div className="section">
            <div className="subsection">
              <h1 className="subHeading">
                Affordable Pet Grooming at Home in {city}
              </h1>
              <p>
                DearPet provides top-quality, affordable pet grooming at home in{" "}
                {city}. Book dog grooming near me or cat grooming near me today
                and experience the DearPet difference.
              </p>
            </div>
          </div>

          <div className="sectionTwo">
            <div className="subsectionTwo">
              <h1 className="subHeading">
                Professional Pet Grooming Services in {city}
              </h1>
              <p>
                Our skilled groomers in {city} offer a wide range of
                professional pet grooming services. From precision dog clipping
                to luxurious at-home grooming, we ensure your pet receives
                personalized and professional care. For the "best dog groomers
                near me in {city}," choose DearPet.
              </p>
            </div>
          </div>

          <div className="section">
            <div className="subsection">
              <h1 className="subHeading">Holistic Pet Grooming</h1>
              <p>
                Our holistic pet grooming approach focuses on your pet's overall
                health and hygiene. We use gentle, pet-friendly grooming
                products to ensure your pet looks great and feels even better.
              </p>
            </div>
          </div>

          <div className="sectionTwo">
            <div className="subsectionTwo">
              <h1 className="subHeading">Book Pet Grooming Near Me Today</h1>
              <p>
                Your search for the best pet grooming near me ends here. Contact
                us today to book your appointment and give your pet the grooming
                experience they deserve. Treat Your Loyals like Royals with
                DearPet!
              </p>
            </div>
          </div>

          <span className="toggle-button" onClick={toggleReadMore}>
          {/* {isExpanded ? <RiArrowDropUpLine /> : ""} */}
          {isExpanded ? "Read Less" : ""}
          </span>
        </>
      )}
    </div>
  );
}
