import React from 'react'
import dpmeal from '../../../assets/images/dearpettestmeal.svg';

import './Food.css';
import { useNavigate } from 'react-router-dom';

function FoodTest() {
    const navigate = useNavigate();
  return (
    <div className='container p-0' onClick={() => navigate(`/dearpet-food/${201}`)}>
      {/* Image for larger screens */}
      <img
        src={dpmeal}
        className="bone-desktop-image"
        alt="Meal "
      />
      {/* Image for mobile screens */}
      <img
        src={dpmeal}
        className="bone-mobile-image"
        alt="Meal"
      />
    </div>
  );
}

export default FoodTest;
