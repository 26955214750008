import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import './Food.css'



const Benefits = ({ color }) => {

  
  useEffect(() => {
    document.documentElement.style.setProperty("--bullet-color", color);
    document.documentElement.style.setProperty("--bullet-active-color", color)
  },[])

  

  const sliders = [
    {
      "id": 1,
      "icon": "https://dearpet.co/uploads/extras/icon1.png",
      "title": "Human-Grade Quality",
      "desc": "Only the finest ingredients, good enough for humans, because your pet deserves the best.",
      "iconColor": "#EFF7F9",
      "iconBackgroundColor": "#FFFFFF",
      "backgroundColor": "#EFF7F9",
      "primaryFontColor": "#000000",
      "secondaryFontColor": "#000000",
      "ratio": "1.3",
      "fraction": "0.55"
    },
    {
      "id": 2,
      "icon": "https://dearpet.co/uploads/extras/icon2.png",
      "title": "Nutritionally Balanced",
      "desc": "Custom-crafted for your pet's health and vitality.",
      "iconColor": "#EFF7F9",
      "iconBackgroundColor": "#FFFFFF",
      "backgroundColor": "#EFF2EC",
      "primaryFontColor": "#000000",
      "secondaryFontColor": "#000000",
      "ratio": "1.3",
      "fraction": "0.55"
    },
    {
      "id": 3,
      "icon": "https://dearpet.co/uploads/extras/icon3.png",
      "title": "No Refrigeration Required",
      "desc": "Space-tech packaging for easy, fresh servings.",
      "iconColor": "#f8e4e3",
      "iconBackgroundColor": "#FFFFFF",
      "backgroundColor": "#ffeed7",
      "primaryFontColor": "#000000",
      "secondaryFontColor": "#000000",
      "ratio": "1.3",
      "fraction": "0.55"
    },
    {
      "id": 4,
      "icon": "https://dearpet.co/uploads/extras/icon4.png",
      "title": "Pure & Preservative-Free",
      "desc": "Absolutely no additives or artificial supplements.",
      "iconColor": "#faf3dd",
      "iconBackgroundColor": "#FFFFFF",
      "backgroundColor": "#ffdee2",
      "primaryFontColor": "#000000",
      "secondaryFontColor": "#000000",
      "ratio": "1.3",
      "fraction": "0.55"
    },
    {
      "id": 5,
      "icon": "https://dearpet.co/uploads/extras/icon5.png",
      "title": "Vet Approved",
      "desc": "Customised to meet the nutritional needs of your pets.",
      "iconColor": "#f1fdd6",
      "iconBackgroundColor": "#FFFFFF",
      "backgroundColor": "#faf1e8",
      "primaryFontColor": "#000000",
      "secondaryFontColor": "#000000",
      "ratio": "1.3",
      "fraction": "0.55"
    },
    {
      "id": 6,
      "icon": "https://dearpet.co/uploads/extras/icon7.png",
      "title": "Ready to Eat",
      "desc": "Just Cut, Open and Serve",
      "iconColor": "#f1e7fb",
      "iconBackgroundColor": "#FFFFFF",
      "backgroundColor": "#ffe1ed",
      "primaryFontColor": "#000000",
      "secondaryFontColor": "#000000",
      "ratio": "1.3",
      "fraction": "0.55"
    }
  ]
  if (!sliders || sliders.length === 0) {
    return null;
  }

  return (
    <div
      style={{ padding: '0 50px', '--bullet-color': color, '--bullet-active-color': color }}
      className='container p-0 pl-5'
    >
      <h4 className="sec4Heading my-3 text-center" style={{ color: "#F29A4C" }}>
        Benefits of DearPet Food
      </h4>
      <Swiper
        slidesPerView="auto" // Enables fixed-width slides
        spaceBetween={20} // Adjusts the space between slides
        pagination={{ clickable: true}}
        modules={[Pagination]}
        className="mySwiper"
      >
        {sliders.map((slid) => (
          <SwiperSlide key={slid.id} style={{ width: '250px' }}>
            <SliderItem slider={slid} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

const SliderItem = ({ slider }) => {
  return (
    <div
      style={{
        margin: '10px',
        padding: '10px',
        borderRadius: '20px',
        backgroundColor: slider.backgroundColor,
        minWidth: '120px',
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {/* First Half: Logo */}
      <div
        style={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '10px',
          height: '50%',
          minHeight:'150px'
        }}
      >
        <div
          style={{
            borderRadius: '50%',
            backgroundColor: slider.iconBackgroundColor,
            padding: '10%',
            display: 'inline-block',
          }}
        >
          <img
            src={slider.icon}
            alt="icon"
            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
          />
        </div>
      </div>

      {/* Second Half: Title and Description */}
      <div
        style={{
          flex: 1,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          height: '50%',
        }}
      >
        <h3
          style={{
            color: '#3F3F3F',
            fontWeight: '900',
            fontSize: '16px',
            margin: '10px 0',
          }}
        >
          {slider.title}
        </h3>
        <p
          style={{
            color: '#3F3F3F',
            fontWeight: '400',
            fontSize: '14px',
            margin: '0 0 10px 0',
          }}
        >
          {slider.desc}
        </p>
      </div>
    </div>
  );
};





export default Benefits;
