import React, { useEffect, useState } from "react";
import {
  FaDog,
  FaHome,
  FaLaptopMedical,
  FaVials,
  FaMapMarkerAlt,
} from "react-icons/fa";
import ServiceCard from "./ServiceCard";

import "./style.css";
import { getVetCities ,getVetProductPlans} from "../../service/api";
import StarGreyIcon from "../../assets/icons/star-grey.svg";
import { ThreeDots } from "react-loader-spinner";

const iconMapping = {
  "Online Vet": <FaLaptopMedical />,
  "Vet at Home": <FaHome />,
  "Dog/Cat Vaccination": <FaDog />,
  "Lab Testing": <FaVials />,
};

export default function Work({ onCityChange }) {
  const [vetPlans, setVetPlans] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState(3);
  const [planLoader, setPlanLoader] = useState(true);
  const[selectedPet,setSelectedPet]=useState("Dog");


  useEffect(() => {
    fetchCities();
  }, []);

  const fetchCities = async () => {
    try {
      const cityData = await getVetCities();
      if (cityData.success === "1") {
        setCities(cityData.data);

        const storedItem = JSON.parse(localStorage.getItem("homeData"));
        if (storedItem) {
          // setSelectedCityId(storedItem.selectedCityId);
          
        } else {
          const defaultCity = cityData.data[0]; // Assuming the first city is default
          const data = {
            selectedCityId: defaultCity.id,
          };
          localStorage.setItem("homeData", JSON.stringify(data));
          // setSelectedCityId(defaultCity.id);
          
        }
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    setSelectedCityId(selectedCityId);
    if (onCityChange) {
      onCityChange({ selectedCityId });
    }
  };

  // this is for fetch packages according to selectCityId
  
  // useEffect(() => {
  //   fetchPlans();
  // }, [selectedCityId, selectedPet]);

  // const fetchPlans = async () => {
  //   try {
  //     const bodySend = {
  //       // category_id: "1",
  //       city_id: selectedCityId,

  //       // isMultipleCity: true,
  //       // pet: selectedPet,
  //     };


  //     const planData = await getVetProductPlans(bodySend);
      
  //     const order = ["Basic", "Basic + Hygiene", "Essential", "Advance"];
  //     const plans =
  //       planData.data &&
  //       planData?.data.sort((a, b) => {
  //         const getOrderIndex = (title) => {
  //           for (let i = 0; i < order.length; i++) {
  //             if (title.includes(order[i])) {
  //               return i;
  //             }
  //           }
  //           return -1;
  //         };

  //         const aIndex = getOrderIndex(a.title);
  //         const bIndex = getOrderIndex(b.title);

  //         if (aIndex === -1 && bIndex === -1) return 0;
  //         if (aIndex === -1) return 1;
  //         if (bIndex === -1) return -1;

  //         return aIndex - bIndex;
  //       });

  //     // setBookingPlans(plans);
  //     // setBookingPlans(planData.data);

  //     if (planData.data.length !== 0 && planData.success === "1") {
  //       setPlanLoader(false);
  //     }
  //   } catch (error) {
  //     // console.error("Error fetching cities:", error);
  //   }
  // };

  useEffect(() => {
    if (selectedCityId) {
      fetchPlans();
    }
  }, [selectedCityId]);

  const fetchPlans = async () => {
    try {
      const bodySend = { city_id: selectedCityId };

      const planData = await getVetProductPlans(bodySend);

      if (planData.success === "1" && planData.data.length > 0) {
        const filteredPlans = planData.data.filter(plan => plan.category_id != 3);

      // Sort the filtered plans
      const sortedPlans = filteredPlans.sort((a, b) => {
        return a.is_coming_soon === b.is_coming_soon ? 0 : a.is_coming_soon ? 1 : -1;
      });
      
        setVetPlans(sortedPlans); // Set the sorted plans
        setPlanLoader(false);
      } else {
        setVetPlans([]); // If no plans available, set an empty array
        setPlanLoader(true); // Show loader if no plans are available
      }
    } catch (error) {
      console.error("Error fetching vet product plans:", error);
      setPlanLoader(true); // Show loader or error message
    }
  };

  // ending here

  return (
    <div className="work-container">
      <div className="city-selection">
        <h3 className="city-title">Please select your city</h3>
        <div className="select-container">
          <select
            onChange={handleCityChange}
            value={selectedCityId || ""}
            className="city-select"
          >
            
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.title}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Other component logic */}

      <div className="services-container">
        <h2 className="font-extrabold col">DearPet Services</h2>
        <div className="services-grid">
          {planLoader ? (
            <p>Loading services...</p>
          ) : vetPlans.length > 0 ? (
            vetPlans.map((plan, index) => (
              <ServiceCard
                key={index}
                plan={plan}
                title={plan.title}
                selectedCity= {selectedCityId}
                icon={iconMapping[plan.title] || <FaHome />} // Fallback to FaHome if no icon found
                isDelhi={selectedCityId == "3"} // Assuming "1" is Delhi's ID
              />
            ))
          ) : (
            <p>No services available for this city.</p>
          )}
        </div>
      </div>

    </div>
  );
}



// import React, { useEffect, useState } from "react";
// import { FaDog, FaHome, FaLaptopMedical, FaVials, FaMapMarkerAlt } from "react-icons/fa";
// import ServiceCard from "./ServiceCard";
// import { getVetCities } from "../../service/api";
// import "./style.css";
// import StarGreyIcon from "../../assets/icons/star-grey.svg";
// import { ThreeDots } from "react-loader-spinner";

// export default function Work({ onCityChange }) {
//   const [cities, setCities] = useState([]);
//   const [selectedCityId, setSelectedCityId] = useState(null);
//   const [planLoader, setPlanLoader] = useState(true);

//   // List of services with their availability
//   const services = [
//     {
//       title: "Online Vet",
//       description: "Consult a vet online from the comfort of your home.",
//       icon: <FaLaptopMedical />,
//       availableIn: [1], // Assuming "1" is Delhi's ID
//     },
//     {
//       title: "Vet at Home",
//       description: "Get a vet visit at your home for your pet's health needs.",
//       icon: <FaHome />,
//       availableIn: [1], // Assuming "1" is Delhi's ID
//     },
//     {
//       title: "Dog/Cat Vaccination",
//       description: "Vaccination services to keep your dog healthy.",
//       icon: <FaDog />,
//       availableIn: [1], // Assuming "1" is Delhi's ID
//     },
//     {
//       title: "Lab Testing",
//       description: "Lab testing services to ensure your pet's health.",
//       icon: <FaVials />,
//       availableIn: [1], // Assuming "1" is Delhi's ID
//     },
//   ];

//   useEffect(() => {
//     fetchCities();
//   }, []);

//   const fetchCities = async () => {
//     try {
//       const cityData = await getVetCities();
//       if (cityData.success === "1") {
//         setCities(cityData.data);

//         const storedItem = JSON.parse(localStorage.getItem("homeData"));
//         if (storedItem) {
//           setSelectedCityId(storedItem.selectedCityId);
//           onCityChange(storedItem);
//         } else {
//           const defaultCity = cityData.data[0]; // Assuming the first city is default
//           const data = {
//             selectedCityId: defaultCity.id,
//           };
//           localStorage.setItem("homeData", JSON.stringify(data));
//           setSelectedCityId(defaultCity.id);
//           onCityChange(data);
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching cities:", error);
//     }
//   };

//   const handleCityChange = (event) => {
//     const selectedCityId = event.target.value;
//     setSelectedCityId(selectedCityId);
//     if (onCityChange) {
//       onCityChange({ selectedCityId });
//     }
//   };

//   // Filter services based on selected city
//   const filteredServices = services.filter(service =>
//     service.availableIn.includes(parseInt(selectedCityId))
//   );

//   return (
//     <div className="work-container">
//       <div className="city-selection">
//         <h3 className="city-title">Please select your city</h3>
//         <div className="select-container">
//           <FaMapMarkerAlt className="map-icon" />
//           <select
//             onChange={handleCityChange}
//             value={selectedCityId || ""}
//             className="city-select"
//           >
//             <option value="" disabled>Select a City</option>
//             {cities.map((city) => (
//               <option key={city.id} value={city.id}>
//                 {city.title}
//               </option>
//             ))}
//           </select>
//         </div>
//       </div>

//       <div className="services-container">
//         <h2 className="font-extrabold col">Our Services</h2>
//         <div className="services-grid">
//           {filteredServices.length > 0 ? (
//             filteredServices.map((service, index) => (
//               <ServiceCard
//                 key={index}
//                 title={service.title}
//                 description={service.description}
//                 icon={service.icon}
//                 isDelhi={selectedCityId === "1"} // Assuming "1" is Delhi's ID
//               />
//             ))
//           ) : (
//             <p>No services available for the selected city.</p>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }
