import React, { useEffect, useState } from "react";
import dogIocn from "../../assets/icons/dogmascot.svg";
import catIocn from "../../assets/icons/catmascot.svg";

import dogDarkIocn from "../../assets/icons/mdi-dark-dog.svg";
import catDarkIocn from "../../assets/icons/mdi-dark-cat.svg";
import DropdownIcon from "../../assets/icons/location-icon.svg";
import { getCities, getProductPlans } from "../../service/api";
import StarGreyIcon from "../../assets/icons/star-grey.svg";
import { ThreeDots } from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BookingPlanCard from "../../component/Card/BookingPlanCard";
import VetPlanCard from "../../component/Card/VetPlanCard";
import DogVaccineCover from "../../assets/images/vaccinedogcover.svg";
import CatVaccineCover from "../../assets/images/vaccinecatcover.svg";
import './VetPackages.css';

function VetPackages() {

  const location = useLocation();
  const catId = location.state?.catId;
  const selectedCityId = location.state?.selectedCityId;

  const planTitle = location.state?.planTitle;
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedPet, setSelectedPet] = useState("Dog");
  const [selectedStateId, setSelectedStateId] = useState();
  const [userAddress, setUserAddress] = useState("");
  const [userPincode, setUserPincode] = useState("");
  const [userAddId, setUserAddId] = useState();
  const [inputDisable, setInputDisable] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [loggedUserData, setLoggedUserData] = useState();
  const navigate = useNavigate();
  const [bookingPlans, setBookingPlans] = useState([]);
  const [planLoader, setPlanLoader] = useState(true);
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    const updateWindowDimensions = () => {
      setIsMobile(window.innerWidth < 768);
    };
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    const loginuser = JSON.parse(localStorage.getItem("loggedin"));
    const loginUserData = JSON.parse(localStorage.getItem("userIdData"));
    if (loginuser) {
      setIsLogin(loginuser);
    }
    if (loginUserData) {
      setLoggedUserData(loginUserData);
    }
  }, []);

  const onPetChange = (pettype) => {
    setSelectedPet(pettype);
    const selectedPet = pettype;

    const data = { selectedCity, selectedCityId, selectedPet };
    localStorage.setItem("homeData", JSON.stringify(data));
    const storedItem = JSON.parse(localStorage.getItem("homeData"));

    setSelectedPet(storedItem.selectedPet);


  };
  useEffect(() => {

    window.scroll(0, 0)
  }, []);
  useEffect(() => {
    fetchPlans();
  }, [selectedCityId, selectedPet]);

  const fetchPlans = async () => {
    try {
      const bodySend = {
        category_id: catId ?? "1",
        city_id: selectedCityId,
        isMultipleCity: true,
        pet: selectedPet,
        sort_by:true,
        price:"high_to_low"
      };
      const planData = await getProductPlans(bodySend);
      const sortedPlans = (planData.data ?? []).sort((a, b) => {
        if (a.id == 217 || a.id == 202) return -1;
        if (b.id == 217 || b.id == 202) return 1;
        return 0;
      });

      setBookingPlans(sortedPlans);
      if (planData.data.length !== 0 && planData.success === "1") {
        setPlanLoader(false);
      }
    } catch (error) {
      // Handle the error if needed
    }
  };

  const handleBookNow = (plan) => {
    localStorage.setItem("selectedPlan", JSON.stringify(plan));
    // Uncomment the navigation logic as needed
    const naviData = {
      fromPage: "packages",
    };
    if (isLogin) {
      navigate("/mypets", { state: { fromWhich: naviData } });
    } else {
      navigate("/personal-details", { state: { fromWhich: naviData } });
    }
  };

  return (
    <div className="mainBookingContainer" style={{ paddingTop: "70px", paddingBottom:"70px" }}>

<div className="vetPackageCover">
        <img src={selectedPet == "Dog" ? DogVaccineCover : CatVaccineCover} alt="cover" className="" />
        </div>
      <div className="bookingPlansWrapper">
        
        <div className="cityMainVaccine" >
          <div className="cityGroomingWrapperVet" >
            <div>
              <h1 className="vetVaccineHomeTitle mt-3">
                <span style={{ color: "#F29A4C" }}>{selectedCity?.title ?? ""}</span>{" "}
                {planTitle ?? 'Packages'}
              </h1>
              <div className="city-botom-txt mb-2" style={{fontSize:"14px"}}>
                <img src={StarGreyIcon} alt="star" className="me-1 pb-1" />
                <span className="citytext">
                  Prices may vary according to your City and Pet
                </span>
              </div>
            </div>
            <div className="selectPetDivVet ">
              <button
                className={
                  "petBtnVet " + (selectedPet === "Dog" ? "petBtnActive" : "")
                }
                onClick={() => {
                  // setSelectedPet("Dog");
                  onPetChange("Dog");
                }}
              >
                <img
                  src={dogIocn}
                  className="me-2 vetvaccineicon"
                  alt="Dog"
                />
                Dog{" "}
              </button>
              <button
                className={
                  "petBtnVet " + (selectedPet === "Cat" ? "petBtnActive" : "")
                }
                onClick={() => {
                  // setSelectedPet("Cat");
                  onPetChange("Cat");
                }}
              >
                <img
                  src={catIocn}
                  className="me-2 vetvaccineicon"
                  alt="Cat"
                />
                Cat{" "}
              </button>
            </div>
          </div>
        </div>
        {planLoader && (
          <div className="d-flex justify-content-center">
            <ThreeDots color="#7B5BFB" height={50} width={50} />
          </div>
        )}

        {!planLoader && (
          <div
            className="row justify-content-center bookingCardContainer mt-2"
            style={{ margin: "0 auto" }}
          >
            {bookingPlans.map((plan, index) => (
              <VetPlanCard
                key={index}
                plan={plan}
                index={index}
                length={bookingPlans.length}
                isMobile={isMobile}
                handleBookNow={handleBookNow}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default VetPackages;
