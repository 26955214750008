import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import Img1 from "../../assets/nurturing-bonds/img1.svg"
import Img2 from "../../assets/nurturing-bonds/img2.svg"
import Img3 from "../../assets/nurturing-bonds/img3.svg"
import Img4 from "../../assets/nurturing-bonds/img4.svg"
import Img5 from "../../assets/nurturing-bonds/img5.svg"
import Groom from "../../assets/landing/grooming.svg";
import Vet from "../../assets/landing/vet.svg";
import Food from "../../assets/landing/pet-food.svg";
import Shampoo from "../../assets/landing/shampoo.svg";
import { useNavigate } from 'react-router-dom';
import './LandingBanner.css'
// import 'swiper/swiper-bundle.min.css';
// import 'swiper/swiper.min.css';




function LandingBanner() {
  const navigate = useNavigate();
  return (
    <section className="landing-banner">
  <div className="banner-content-container">
    <div className="banner-background"></div>
    <div className="banner-content-landing container">
      <div className="categories-container">
        <h2 className="browse-title">Explore DeatPet Services</h2>
        <p className='browse-desc'>Click to find everything you need to pamper your pet!</p>
        <div className="categories-grid">
          <div className="landing-category-card" onClick={() => navigate('/grooming')}>
            <img src={Groom} alt="Grooming" />
            <span>Grooming</span>
          </div>
          <div className="landing-category-card" onClick={() => navigate('/vet')}>
            <img src={Vet} alt="Veterinary" />
            <span>Veterinary</span>
          </div>
          <div className="landing-category-card" onClick={() => navigate('/dearpet-foods')}>
            <img src={Food} alt="Food" />
            <span>Food</span>
          </div>
          <div className="landing-category-card" onClick={() => navigate('/products-list', { state: { catId: 6, title:'DearPet Shampoo & Conditioner' } })}>
            <img src={Shampoo} alt="Shampoo" />
            <span>Shampoo</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


  );
}

export default LandingBanner;
