import React from 'react';
import { FaDog, FaClock, FaPlusCircle, FaTag, FaMapMarkerAlt } from 'react-icons/fa';
import './style.css'; // Import the custom CSS file

const CheckOut = () => {
  const services = [
    {
      id: 1,
      title: "General Consultation",
      price: "₹399/-",
      pet: "Dog",
      breed: "Naa",
      time: "17 August 2024, 9:00 AM, Saturday",
    },
    {
      id: 2,
      title: "Dog Vaccine",
      price: "₹4199/-",
      pet: "Dog",
      breed: "Naa",
      time: "16 August 2024, 5:00 PM, Friday",
    },
  ];

  return (
    <div className="container">
      {/* Header */}
      <header className="header">
        <button className="back-button">
          <svg className="icon" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1-7V7h2v4h-2zm0 4v-2h2v2h-2z" clipRule="evenodd" />
          </svg>
        </button>
        <h2>Checkout</h2>
      </header>

      {/* Services Section */}
      <section className="services-section">
        <h3 className="section-title">Services</h3>
        {services.map(service => (
          <div key={service.id} className="service-card">
            <div className="service-info">
              <h4 className="service-title">{service.title}</h4>
              <p className="service-price">{service.price}</p>
              <div className="service-detail"><FaDog /> {service.pet}</div>
              <div className="service-detail"><FaMapMarkerAlt /> {service.breed}</div>
              <div className="service-detail"><FaClock /> {service.time}</div>
            </div>
            <button className="remove-button">✖️</button>
          </div>
        ))}
        <button className="add-more-button">
          <FaPlusCircle /> Add More Products/Services
        </button>
      </section>

      {/* Coupon Section */}
      <section className="coupon-section">
        <button className="coupon-button">
          <FaTag /> Apply Coupon Code
        </button>
      </section>

      {/* Image Section */}
      <section className="image-section">
        <h4 className="section-title">Things Required at the Time of Appointment</h4>
        <img src="/path-to-your-image.jpg" alt="Dog grooming" />
        <ul className="requirements-list">
          <li>A bathing area</li>
          <li>A towel (Optional)</li>
          <li>A plug for our magic dryer!</li>
        </ul>
      </section>

      {/* Address Section */}
      <section className="address-section">
        <h4 className="section-title">Address</h4>
        <button className="address-button">
          <FaMapMarkerAlt /> Select Address
        </button>
      </section>

      {/* Terms & Conditions and Place Order */}
      <section className="order-section">
        <div className="terms-section">
          <input type="checkbox" id="terms" />
          <label htmlFor="terms">
            I have read and agree to the <a href="#" className="terms-link">Terms & Conditions</a>
          </label>
        </div>
        <button className="place-order-button">
          Place Order
        </button>
      </section>
    </div>
  );
};

export default CheckOut;
