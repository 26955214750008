import React, { useEffect, useRef, useState } from "react";
import CarouselSection from "./Carousel";
import useCaptureUTMParameters from "../../component/capturedParameter";

import HowWorks from "./HowWorks";
import SelectCity from "./SelectCity";
import "./style.css";
// import BookingPlans from "./BookingPlans";
import FAQ from "./FAQ";
import VideoSlider from "./VideoSlider";
import Review from "./Review";
import { getDashboard } from "../../service/api";
import WhyChooes from "./WhyChooes";
import Gallery from "./Gallery";
import StarGroomer from "./StarGroomer";
import DearpetNurturing from "./DearpetNurturing";
import CoverPhoto from "./CoverPhoto";
import HomeLandingPage from "./HomeLandingPage";
import Celebrating from "./Celebrating";
import WhatsAppIcon from "../../../src/assets/icons/whatsapp3.png";

const GroomingHome = () => {
  const selectCityRef = useRef(null);
  const whyChooesRef = useRef(null);
  const homeLandingPageRef = useRef(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [sliderData, setSliderData] = useState([]);
  const [reviewData, setReviewData] = useState([]);


  // -----------------------------------------
  const handleCityChange = (data) => {
    setSelectedCity(data.selectedCity.title ?? "Delhi");
  };
  
  // -----------------------------------------

  // --------------------------
  useCaptureUTMParameters();


  
  useEffect(() => {
    
    dashboardData();
    window.scroll(0,0);
  }, []);

  const dashboardData = async () => {
    try {
      const dashboardData = await getDashboard();
      if (dashboardData.status === '200') {
        localStorage.setItem("dashboardData", JSON.stringify(dashboardData.data))
        setSliderData(dashboardData.data.slider);
        setReviewData(dashboardData.data.reviews);
      }
    } catch (error) {
      console.error("Error in get home page data:", error);
    }
  };

  const [showStickyButton, setShowStickyButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const whyChooesBottom = whyChooesRef.current.getBoundingClientRect().bottom;
      const homeLandingPageTop = homeLandingPageRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (whyChooesBottom <= windowHeight && homeLandingPageTop > windowHeight) {
        setShowStickyButton(true);
      } else {
        setShowStickyButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleCoverPhotoClick = () => {
    selectCityRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="home-page">
      {/* <CarouselSection sliderData={sliderData} /> */}
      <CoverPhoto onCoverPhotoClick={handleCoverPhotoClick} city={'all'} />
      <HowWorks />
      <div ref={selectCityRef}>
        <SelectCity onCityChange={handleCityChange} />
      </div>
      {/* <BookingPlans selectedCity={selectedCity} /> */}
      <div ref={whyChooesRef}>
        <WhyChooes />
      </div>
      <Celebrating />
      <VideoSlider />
      <Review reviewData={reviewData} />
      <Gallery />
      <StarGroomer />
      <DearpetNurturing />
      <FAQ />
      <HomeLandingPage city={selectedCity} />
      <div ref={homeLandingPageRef}>
      </div>
      
      {showStickyButton && (
        <div className="stickyBookAppointmentContainer">
          <div className="stickyBookWhatsapp">
            <button className="stickyBookAppointmentBtn" onClick={handleCoverPhotoClick}>
              Book Appointment
          </button>

            {/* We placed the whatsapp icons at navbar for both the mobile and desktop view do we don't need this icon here
            and it is places it at header.js */}
            {/* <button className="whatsapp-icon-sticky">
              <a href="https://wa.me/+917599975888" target="_blank" rel="noreferrer">
                <img src={WhatsAppIcon} alt="WhatsApp" />
              </a>
            </button> */}
          </div>
          {/* <button className="stickyWhatsappButton">
        <img src={WhatsAppIcon} alt="WhatsApp" /> Chat With Us
        </button> */}
        </div>
      )}


      {/* {!showStickyButton && <button className="whatsapp-icon">
        <a href="https://wa.me/+917599975888" target="_blank" rel="noreferrer">
          <img src={WhatsAppIcon} alt="WhatsApp" />
        </a>
      </button>} */}
    </div>
  );
};

export default GroomingHome;
