import React, { useState } from 'react';
import './Food.css'

function FoodDescription({ productDetails, color, secondaryColor }) {
  const [view, setView] = useState('description');


  const buttonStyles = {
    padding: '10px 20px',
    border: '1px solid',
    borderColor:color,
    backgroundColor: 'white',
    color: color,
    fontWeight: 'bold',
    cursor: 'pointer',
    textAlign: 'center',
  };
  
  const activeButtonStyles = {
    ...buttonStyles, // inherit the base styles
    backgroundColor: color,
    color: 'white',
  };

  return (
    <div style={{ padding: '20px', backgroundColor: secondaryColor, borderRadius: '8px', marginTop:'60px' }}>
      <div style={{ marginBottom: '20px', display: 'flex' }}>
      <button
        onClick={() => setView('description')}
        style={{
          ...(view === 'description' ? activeButtonStyles : buttonStyles),
          borderTopLeftRadius: '10px',
          borderBottomLeftRadius: '10px',
        }}
        className="responsive-desc-button"
      >
        Description
      </button>
      <button
        onClick={() => setView('nutrition')}
        style={{
          ...(view === 'nutrition' ? activeButtonStyles : buttonStyles),
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
        }}
        className="responsive-desc-button"
      >
        Nutrition
      </button>
    </div>

    <div>
  {view === 'description' && (
    <div>
      <span
        dangerouslySetInnerHTML={{
          __html: productDetails?.description?.replace(/\n/g, '<br />'),
        }}
      />
    </div>
  )}

  {view === 'nutrition' && (
    <div>
      <span
        dangerouslySetInnerHTML={{
          __html: productDetails?.info?.replace(/\n/g, '<br />'),
        }}
      />
    </div>
  )}
</div>

    </div>
  );

}

export default FoodDescription;
