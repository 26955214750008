import React from "react";
import "./BenefitCard.css";

export default function BenefitCard({ icon, title, description }) {
  return (
    <div className="benefit-card">
      {icon}
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
}
