import React from 'react';
import './VetCards.css';
import DogImage from '../../assets/images/dog-cardi.png';
import CatImage from '../../assets/images/cat-card.png';
import { useNavigate } from 'react-router-dom';
import { getProductPlans } from '../../service/api';

function VetCards({ catId, selectedCityId }) {

    const navigate = useNavigate();

    // Map of catId to productId
    const productIdMap = {
        "3": { Dog: 4, Cat: 216 },
        "12": { Dog: 202, Cat: 217 }, // Example IDs for catId 12
        // Add more mappings as needed
    };

    // Map of catId to content
    const contentMap = {
        "3": { // Online Consultation
            Dog: {
                title: "Dog Consultation Online",
                features: ["Gut Issues", "Skin Problems", "Behavioral Advice & More"],
                image: DogImage,
                buttonText: "Book A Video Dog Consultation"
            },
            Cat: {
                title: "Cat Consultation Online",
                features: ["Gut Issues", "Skin Problems", "Behavioral Advice & More"],
                image: CatImage,
                buttonText: "Book A Video Cat Consultation"
            },
        },
        "12": { // At-Home Consultation
            Dog: {
                title: "Dog Consultation At Home",
                features: ["Expert Health Advice for Your Dog", "Dog Care & Lifestyle Management", "Behavior Correction (Barking, Chewing, Anxiety) & More"],
                image: DogImage,
                buttonText: "Book At-Home Dog Consultation"
            },
            Cat: {
                title: "Cat Consultation At Home",
                features: ["Complete Health Checkup for Cats", "Managing Daily Cat Care", "Insights into Cat Behavior (Scratching, Litter Box Issues) & More"],
                image: CatImage,
                buttonText: "Book At-Home Cat Consultation"
            },
        },
        // Add more mappings as needed
    };


    const fetchPlans = async (petType) => {
        try {
            const productId = productIdMap[catId]?.[petType];
            const bodySend = {
                category_id: catId ?? "1",
                city_id: selectedCityId,
                isMultipleCity: true,
                pet: petType,
            };
            const planData = await getProductPlans(bodySend);
            if (planData && planData.data) {
                const filteredPlans = planData.data.filter(plan => plan.id == productId);
                if (filteredPlans.length > 0) {
                    localStorage.setItem("selectedPlan", JSON.stringify(filteredPlans[0]));
                    const homeData = JSON.parse(localStorage.getItem("homeData"));
                    if(homeData){
                        homeData.selectedPet = petType;
                    }
                    localStorage.setItem("homeData", JSON.stringify(homeData));
                    localStorage.setItem("petType", petType);
                    navigate("/issue-list");
                }
            }
        } catch (error) {
            console.error("Error fetching plans:", error);
        }
    };

    const dogContent = contentMap[catId]?.Dog;
    const catContent = contentMap[catId]?.Cat;

    return (
        <div className="vet-card-container">
            <div className='vet-service-card-container'>

                {dogContent && (
                    <div className="vet-service-card dog-card">
                        <img src={dogContent.image} alt="Dog" className="vet-card-image" />
                        <div className="vet-card-content">
                            <h2 className="vet-card-title">{dogContent.title}</h2>
                            <ul className="vet-card-text">
                                {dogContent.features.map((feature, index) => (
                                    <li key={index}>{feature}</li>
                                ))}
                            </ul>
                            <button className="vet-card-button" onClick={() => fetchPlans("Dog")}>
                                {dogContent?.buttonText}
                            </button>
                        </div>
                    </div>
                )}

                {catContent && (
                    <div className="vet-service-card cat-card">
                        <div className="vet-card-content">
                            <h2 className="vet-card-title">{catContent.title}</h2>
                            <ul className="vet-card-text">
                                {catContent.features.map((feature, index) => (
                                    <li key={index}>{feature}</li>
                                ))}
                            </ul>
                            <button className="vet-card-button" onClick={() => fetchPlans("Cat")}>
                                {catContent?.buttonText}
                            </button>
                        </div>
                        <img src={catContent.image} alt="Cat" className="vet-card-image" />
                    </div>
                )}

            </div>
        </div>
    );
}

export default VetCards;
