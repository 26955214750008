import React, { useEffect } from 'react'
import FoodBanner from './Foods/FoodBanner'
import FoodMeal from './Foods/FoodMeal'
import NitinStory from '../Home/NitinStory'
import BoneBroth from './Foods/BoneBroth'
import Natural from './Foods/Natural'
import FoodFAQ from './Foods/FoodFAQ'
import FoodReviews from './Foods/FoodReviews'
import Benefits from './Foods/Benefits'
import FAQList from './Foods/FoodiFAQItem'
import FoodTest from './Foods/FoodTest'

function FoodProducts() {
  useEffect(() => {
    window.scroll(0, 0);
  },[])
  return (
    <div>
        <FoodBanner />
        <FoodMeal />
        <FoodTest/>
        <NitinStory />
        <BoneBroth />
        <Benefits color={'#F29A4C'}  />
        <Natural />
        <FoodReviews />
        <FAQList primaryColor={'#F29A4C'} secondaryColor={'#FFF0E2'} />
    </div>
  )
}

export default FoodProducts