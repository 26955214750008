import React from 'react';
import PropTypes from 'prop-types';

const Star = ({ filled }) => {
  return (
    <span style={{ color: filled ? 'gold' : 'lightgray', fontSize: '21px' }}>
      &#9733;
    </span>
  );
};

Star.propTypes = {
  filled: PropTypes.bool.isRequired,
};

const Rating = ({ value, totalStars }) => {
  const stars = Array.from({ length: totalStars }, (_, index) => index + 1);
  return (
    <div>
      {stars.map((star) => (
        <Star key={star} filled={star <= value} />
      ))}
    </div>
  );
};

Rating.propTypes = {
  value: PropTypes.number.isRequired,
  totalStars: PropTypes.number.isRequired,
};

export default Rating;
