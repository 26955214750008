import React, { useEffect, useState } from 'react'
import FoodFeed from './FoodFeed'
import { useParams } from 'react-router-dom';
import { getProductDetails } from '../../../service/api';
import ProductDetails from '../ProductPage/ProductDetails';
import Benefits from './Benefits';
import FoodDescription from './FoodDescription';
import FoodReviews from './FoodReviews';
import FoodFAQ from './FoodFAQ';
import FAQList from './FoodiFAQItem';
import FoodTest from './FoodTest';
import FoodFeedMobile from './FoodFeedMobile.js';

function FoodPage() {

    const { id } = useParams();
  const [product, setProduct] = useState(null);
  

  useEffect(() => {
    // Fetch product details using the id
    
    fetchProduct();
    window.scroll(0, 0);
  
  }, []);

  function argbToHex(argb) {
    return '#' + argb.slice(-6);
  }

  const fetchProduct = async () => {
    try {
      const productData = await getProductDetails(id);
      setProduct(productData.data)
      if(productData.data){

        document.documentElement.style.setProperty("--bullet-color", productData.data?.background_color ? argbToHex(productData.data.background_color) : '#7B5BFB');
        document.documentElement.style.setProperty("--bullet-active-color",  productData.data?.background_color ? argbToHex(productData.data.background_color) : '#7B5BFB')
      }
    } catch (error) {
      console.error('Error fetching product:', error);
    }
  };

  return (
    <div className='container food-page-container'>
        <ProductDetails product={product} isFood={true} primaryColor={product?.background_color ? argbToHex(product.background_color) : '#7B5BFB'} secondaryColor={ product?.background_light_color ? argbToHex(product.background_light_color) : '#F6F3FF'} />
        <Benefits color={product?.background_color ? argbToHex(product.background_color) : '#7B5BFB'} />
        <FoodDescription productDetails={product} color={product?.background_color ? argbToHex(product.background_color) : '#7B5BFB'} secondaryColor={ product?.background_light_color ? argbToHex(product.background_light_color) : '#F6F3FF'} />
        <FoodFeed color={product?.background_color ? argbToHex(product.background_color) : '#7B5BFB'} />
        <FoodFeedMobile color={product?.background_color ? argbToHex(product.background_color) : '#7B5BFB'} />

        <FoodReviews />
        <FAQList primaryColor={product?.background_color ? argbToHex(product.background_color) : '#7B5BFB'} secondaryColor={ product?.background_light_color ? argbToHex(product.background_light_color) : '#F6F3FF'} />
    </div>
  )
}

export default FoodPage