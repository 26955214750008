import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { getDashboard } from "../../service/api";

export default function IssuedPage() {
  const [issueList, setIssueList] = useState([]);
  const [selectedIssues, setSelectedIssues] = useState(() => {
    const savedSelectedIssues = localStorage.getItem("selectedIssues");
    return savedSelectedIssues ? JSON.parse(savedSelectedIssues) : [];
  });
  const [description, setDescription] = useState(() => {
    return localStorage.getItem("description") || "";
  });
  const [issuesError, setIssuesError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = (issueId) => {
    setSelectedIssues((prevSelectedIssues) => {
      const updatedIssues = prevSelectedIssues.includes(issueId)
        ? prevSelectedIssues.filter((id) => id !== issueId)
        : [...prevSelectedIssues, issueId];

      localStorage.setItem("selectedIssues", JSON.stringify(updatedIssues));

      return updatedIssues;
    });
  };

  useEffect(() => {
    window.scroll(0, 0);
    dashboardData();
  }, []);

  const dashboardData = async () => {
    try {
      const dashboard = JSON.parse(localStorage.getItem("dashboardData"));
      if (!dashboard) {
        const dashboardData = await getDashboard();
        if (dashboardData.status === '200') {
          setIssueList(dashboardData.data.vet_issue_list);
          localStorage.setItem("issueList", JSON.stringify(dashboardData.data.vet_issue_list));
        }
      } else {
        setIssueList(dashboard.vet_issue_list);
      }
    } catch (error) {
      console.error("Error in get home page data:", error);
    }
  };

  const handleSubmit = () => {
    // Reset errors
    setIssuesError(false);
    setDescriptionError(false);

    // Check if there are selected issues and a description
    if (selectedIssues.length === 0) {
      setIssuesError(true);
    }
    if (!description.trim()) {
      setDescriptionError(true);
    }

    // If both fields are valid, proceed with submission
    if (selectedIssues.length > 0 && description.trim()) {
      const loginuser = JSON.parse(localStorage.getItem("loggedin"));
      // Redirect to "/myvets" route
      const naviData = {
        fromPage: "issue-list",
      };
      if (loginuser) {
        navigate("/mypets", { state: { fromWhich: naviData } });
      } else {
        navigate("/personal-details", { state: { fromWhich: naviData } });
      }
      // Optionally, scroll to the top after a short delay
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    }
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    localStorage.setItem("description", e.target.value);
    if (e.target.value.trim()) {
      setDescriptionError(false);
    }
  };

  return (
    <div className="pet-issues-main-container">

    <div className="pet-issues-container">
      <h2>Select Issues with Your Pet</h2>
      {issueList.map((issue) => (
        <label key={issue.id} className={`issue-item`}>
          <input
            type="checkbox"
            checked={selectedIssues.includes(issue.id)}
            onChange={() => handleCheckboxChange(issue.id)}
          />
          <span className="text">{issue.title}</span>
        </label>
      ))}
      {issuesError && selectedIssues.length === 0 && (
        <p className="error-message">Please select at least one issue.</p>
      )}
      <textarea
        className={`describe-issue`}
        placeholder="Describe the issue..."
        value={description}
        onChange={handleDescriptionChange}
      ></textarea>
      {descriptionError && (
        <p className="error-message">Description cannot be empty.</p>
      )}
      <button className="submit-button" onClick={handleSubmit}>
        Submit
      </button>
    </div>
    </div>

  );
}
