import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function RedirectionHandler() {
  const location = useLocation();
  const navigate = useNavigate();

  const oldUrls = [
    "/products/dearpet-mutton-and-brown-rice", 
    "/products/dear-pet-food-chicken-and-pumpkin",
    "/pages/puppy-guide",
    "/collections/shampoos-conditioners",
    "/pages/frequently-asked-questions",
    "/collections/woof-collection",
    "/collections/frontpage",
    "/collections/all",
    "/pages/grooming-page",
    "/pages/grooming",
    "/pages/dearpet-noida",
    "/pages/grooming-page",
    "/pages/grooming",
    "/pages/contact-us",
    "/pages/dearpet-delhi"
  ];

  const groomingUrl=[
    "pages/grooming-page",
    "pages/grooming"
  ]
  
  useEffect(() => {
    const path = location.pathname;
    if(groomingUrl.includes(path)){
      window.location.href="http://dearpet.in";
    }
    if (oldUrls.includes(path)) {
        window.location.href = `https://dearpetindia.com${path}`;
      
    }
  }, [location, navigate, oldUrls]);

  return null; 
}

export default RedirectionHandler;
