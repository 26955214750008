import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from "../../../assets/images/dark-logo.png";
import { useLocation } from 'react-router-dom';

// Utility function to format date
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};


function DownloadVaccine() {
    const location = useLocation();
    const [data, setData] = useState([]);
    const [vaccineData, setVaccineData] = useState([]);

    useEffect(() => {
        console.log(location)
        if (location?.state?.data) {
            setData(location?.state?.data);
            setVaccineData(location?.state?.data?.vaccine || {});
        } else {
            const queryParams = new URLSearchParams(location.search);

            const fetchedData = {
                owner: {
                    name: queryParams.get('owner.name'),
                    contact: queryParams.get('owner.contact'),
                },
                pet: {
                    pet_name: queryParams.get('pet.pet_name'),
                    pet_age: queryParams.get('pet.pet_age'),
                    pet_gender: queryParams.get('pet.pet_gender'),
                    pet_breed: queryParams.get('pet.pet_breed'),
                    pet_vaccinated: queryParams.get('pet.pet_vaccinated'),
                },
                vendor: {
                    vendor_name: queryParams.get('vendor.vendor_name'),
                    vendor_contact: queryParams.get('vendor.vendor_contact'),
                    vendor_degree: queryParams.get('vendor.vendor_degree'),
                    vendor_signature: queryParams.get('vendor.vendor_signature'),
                }
            };


            const vaccineDataString = queryParams.get('vaccine'); // This is the stringified vaccine data

            try {
                if (vaccineDataString) {
                    const parsedVaccineData = JSON.parse(vaccineDataString); // Parse the stringified JSON object
                    setVaccineData(parsedVaccineData);
                }
            } catch (e) {
                console.error('Failed to parse vaccine data:', e);
            }


            // Set the fetched data
            setData(fetchedData);
        }
    }, [location]);



    const downloadPDF = () => {
        const doc = new jsPDF();
        let currentY = 10;

        const logoWidth = 50;
        const logoHeight = 10;
        doc.addImage(Logo, 'PNG', 14, currentY, logoWidth, logoHeight);

        doc.setFontSize(10);
        const detailsX = 140; // Adjust this value for positioning on the right side

        doc.setFont('helvetica', 'bold');
        doc.text('Email Id:', detailsX, currentY + 5);
        doc.setFont('helvetica', 'normal');
        doc.text('support@dearpet.in', detailsX + 18, currentY + 5);
        doc.link(detailsX + 18, currentY + 5 - 5, 50, 10, { url: 'mailto:support@dearpet.in' });

        doc.setFont('helvetica', 'bold');
        doc.text('Website:', detailsX, currentY + 10);
        doc.setFont('helvetica', 'normal');
        doc.text('www.dearpet.in', detailsX + 18, currentY + 10);
        doc.link(detailsX + 18, currentY + 10 - 5, 50, 10, { url: 'https://www.dearpet.in' });

        doc.setFont('helvetica', 'bold');
        doc.text('Contact No.:', detailsX, currentY + 15);
        doc.setFont('helvetica', 'normal');
        doc.text('8888880404', detailsX + 23, currentY + 15);
        doc.link(detailsX + 23, currentY + 15 - 5, 50, 10, { url: 'tel:+918888880404' });

        currentY += logoHeight + 10;

        doc.setLineWidth(0.5);
        doc.line(14, currentY, 196, currentY);

        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text(`Pet Parent:`, 14, currentY + 10);
        doc.setFont('helvetica', 'normal');
        doc.text(`${data.owner.name}`, 14 + 25, currentY + 10); // Pet parent's name next to label

        // Contact
        doc.setFont('helvetica', 'bold');
        doc.text(`Contact No:`, 14, currentY + 15);
        doc.setFont('helvetica', 'normal');
        doc.text(`${data.owner.contact}`, 14 + 27, currentY + 15); // Pet parent's contact next to label

        // Pet Name
        doc.setFont('helvetica', 'bold');
        doc.text(`Pet:`, 14, currentY + 20);
        doc.setFont('helvetica', 'normal');
        doc.text(`${data.pet.pet_name}`, 14 + 10, currentY + 20); // Pet name next to label

        // Pet Breed
        doc.setFont('helvetica', 'bold');
        doc.text(`Breed:`, 14, currentY + 25);
        doc.setFont('helvetica', 'normal');
        doc.text(`${data.pet.pet_breed}`, 14 + 15, currentY + 25);

        currentY += 40;

        Object.keys(vaccineData).forEach((vaccineType, index) => {
            if (index > 0) currentY += 10;

            // Set font to bold
            doc.setFont('helvetica', 'bold');

            // Calculate text width for centering
            const textWidth = doc.getStringUnitWidth(vaccineType) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            const pageWidth = doc.internal.pageSize.width;
            const textX = (pageWidth - textWidth) / 2;

            doc.text(vaccineType, textX, currentY);

            doc.autoTable({
                startY: currentY + 5,
                head: [['Vaccine', 'Immunization Date', 'Next Date', 'Doctor']],
                body: vaccineData[vaccineType].map(vaccine => [
                    `Name: ${vaccine.vaccine_name}${vaccine.vaccine_batch_id ? `\nBatch ID: ${vaccine.vaccine_batch_id}` : ''}${vaccine.vaccine_mfg_date ? `\nMfg Date: ${vaccine.vaccine_mfg_date}` : ''}${vaccine.vaccine_expiry_date ? `\nExpiry Date: ${vaccine.vaccine_expiry_date}` : ''}`,
                    location?.state?.data ? vaccine.vaccine_used_date : formatDate(vaccine.vaccine_used_date),
                    location?.state?.data ? vaccine.vaccine_next_date : formatDate(vaccine.vaccine_next_date),
                    `${vaccine.vaccine_doctor}${vaccine.vaccine_degree ? `\n(${vaccine.vaccine_degree})` : ''}`,
                ]),
                theme: 'striped',
                margin: { top: 10, left: 14, right: 14 },
                styles: {
                    fontSize: 10,
                    cellPadding: 2,
                },
                headStyles: {
                    fillColor: [200, 200, 200], // Header background color
                    textColor: [0, 0, 0], // Header text color
                    fontStyle: 'bold', // Bold header text
                    halign: 'center', // Center-align header text
                    valign: 'middle', // Center-align header text vertically
                },
                bodyStyles: {
                    valign: 'middle', // Center-align body text vertically
                },
                columnStyles: {
                    0: { cellWidth: 60, halign: 'left' }, // First column (Vaccine) width
                    1: { cellWidth: "auto", halign: 'center' }, // Second column (Immunization Date) width
                    2: { cellWidth: "auto", halign: 'center' }, // Third column (Next Date) width
                    3: { cellWidth: "auto", halign: 'center' }, // Fourth column (Doctor) width
                },
            });

            // Reset font style back to normal
            doc.setFont('helvetica', 'normal');

            currentY = doc.autoTable.previous.finalY + 10;
        });

        // doc.save('vaccine.pdf');
        const petName = data.pet.pet_name.replace(/\s+/g, '_'); // Replace spaces with underscores
        const filename = `vaccine_${petName}.pdf`;
        doc.save(filename);
    };

    return (
        <div style={{ paddingTop: "100px", display: 'flex', justifyContent: 'center', flexDirection: "column", alignItems: "center" }}>
            <div
                id='pdfContent'
                style={{
                    padding: '20px',
                    width: '100%',
                    maxWidth: '800px',
                    minHeight: '1000px',
                    borderRadius: '10px',
                    backgroundColor: '#fff',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    border: '1px solid #ddd',
                    boxSizing: 'border-box',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: "10px 10px 10px 10px"
                    }}
                >

                    <img
                        src={Logo}
                        alt="Logo"
                        style={{ width: "260px" }}
                    />

                </div>
                <hr style={{ margin: '20px 0', borderColor: '#000' }} />
                <div style={{ margin: '20px 10px' }}>
                    <span style={{ color: '#555' }}>
                        <strong>Pet Parent:</strong> {data.owner?.name}
                    </span><br />
                    <span style={{ color: '#555' }}>
                        <strong>Contact:</strong> {data.owner?.contact}
                    </span><br />
                    <span style={{ color: '#555' }}>
                        <strong>Pet:</strong> {data.pet?.pet_name}
                    </span><br />
                    <span style={{ color: '#555' }}>
                        <strong>Breed:</strong> {data.pet?.pet_breed}
                    </span><br />
                </div>

                {Object.keys(vaccineData).map((vaccineType, index) => (
                    <div key={index} style={{ marginBottom: '20px' }}>
                        <h3
                            style={{
                                color: '#333',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                marginBottom: '15px',
                                fontSize: '18px',
                            }}
                        >
                            {vaccineType}
                        </h3>
                        <table
                            style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                                fontSize: '14px',
                                color: '#333',
                            }}
                        >
                            <thead>
                                <tr style={{ backgroundColor: '#f5f5f5' }}>
                                    <th style={{ padding: '10px', textAlign: 'center' }}>Vaccine</th>
                                    <th style={{ padding: '10px', textAlign: 'center' }}>Immunization Date</th>
                                    <th style={{ padding: '10px', textAlign: 'center' }}>Next Date</th>
                                    <th style={{ padding: '10px', textAlign: 'center' }}>Doctor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {vaccineData[vaccineType]?.map((vaccine, idx) => (
                                    <tr key={idx} style={{ backgroundColor: idx % 2 === 0 ? '#fafafa' : '#f0f0f0' }}>
                                        <td style={{ padding: '10px', textAlign: 'left' }}>
                                            <div>Name: {vaccine?.vaccine_name}</div>
                                            {vaccine?.vaccine_batch_id && <div>Batch ID: {vaccine?.vaccine_batch_id}</div>}
                                            {vaccine?.vaccine_mfg_date && <div>Mfg Date: {vaccine?.vaccine_mfg_date}</div>}
                                            {vaccine?.vaccine_expiry_date && <div>Expiry Date: {vaccine?.vaccine_expiry_date}</div>}
                                        </td>
                                        <td style={{ padding: '10px', textAlign: 'center' }}>
                                            {location?.state?.data ? vaccine?.vaccine_used_date : formatDate(vaccine?.vaccine_used_date)}
                                        </td>
                                        <td style={{ padding: '10px', textAlign: 'center' }}>
                                            {location?.state?.data ? vaccine?.vaccine_next_date : formatDate(vaccine?.vaccine_next_date)}
                                        </td>

                                        <td style={{ padding: '10px', textAlign: 'center' }}><div>{vaccine?.vaccine_doctor}</div>
                                            {vaccine?.vaccine_degree ? <div>({vaccine?.vaccine_degree})</div> : null}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>

            <button
                onClick={downloadPDF}
                style={{
                    marginTop: '20px',
                    marginBottom: '30px',
                    padding: '10px 20px',
                    backgroundColor: '#7b5bfb',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    transition: 'background-color 0.3s ease, transform 0.3s ease',
                    outline: 'none',
                    maxWidth: '250px'
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#6a4ae0')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#7b5bfb')}
                onMouseDown={(e) => (e.currentTarget.style.transform = 'scale(0.95)')}
                onMouseUp={(e) => (e.currentTarget.style.transform = 'scale(1)')}
            >
                Download PDF
            </button>
        </div>
    );
}

export default DownloadVaccine;
