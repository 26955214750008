import React from "react";
import BenefitCard from "./BenefitCard";
import Home from '../../assets/icons/home.svg';
import Handshake from '../../assets/icons/handshake.svg';
import Clock from '../../assets/icons/clock.svg';
import Dog from '../../assets/icons/dog.svg'; // Importing your SVGs
import "./BenefitsOfVetAtHome.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";

export default function BenefitAtHome() {
  const benefitData = [
    {
      icon: Home,
      title: "Comfort of Home",
      description: "Your pet remains in a familiar environment, reducing stress and anxiety."
    },
    {
      icon: Handshake,
      title: "Personalized Care",
      description: "Get one-on-one attention from the vet, tailored to your pet's specific needs."
    },
    {
      icon: Clock,
      title: "Convenient Scheduling",
      description: "Flexible appointment times that fit your schedule."
    },
    {
      icon: Dog,
      title: "Safe and Hygienic",
      description: "Minimize exposure to other animals and potential infections."
    }
  ];
  return (
    <div className="container benefits-container">
      <h2 className="benefits-heading">Benefits of Vet at Home</h2>
      <Swiper
        slidesPerView="auto" // Enables fixed-width slides
        spaceBetween={20} // Adjusts the space between slides
        className="mySwiper"
      >
        {benefitData.map((slid) => (
          <SwiperSlide key={slid.id} style={{ width: '300px' }}>
            <SliderItem slider={slid} />
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <div className="benefits-grid">
        <BenefitCard
          icon={<img src={Home} alt="Comfort of Home" className="benefit-icon" />}
          title="Comfort of Home"
          description="Your pet remains in a familiar environment, reducing stress and anxiety."
        />
        <BenefitCard
          icon={<img src={Handshake} alt="Personalized Care" className="benefit-icon" />}
          title="Personalized Care"
          description="Get one-on-one attention from the vet, tailored to your pet's specific needs."
        />
        <BenefitCard
          icon={<img src={Clock} alt="Convenient Scheduling" className="benefit-icon" />}
          title="Convenient Scheduling"
          description="Flexible appointment times that fit your schedule."
        />
        <BenefitCard
          icon={<img src={Dog} alt="Safe and Hygienic" className="benefit-icon" />}
          title="Safe and Hygienic"
          description="Minimize exposure to other animals and potential infections."
        />
      </div> */}
    </div>
  );
}

const SliderItem = ({ slider,index }) => {
  return (
    <BenefitCard
            key={index}
            icon={<img src={slider.icon} alt={slider.title} className="benefit-icon" />}
            title={slider.title}
            description={slider.description}
          />
  );
};
