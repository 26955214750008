import React, { useEffect, useState } from "react";
import "./style.css";
import LeftImg from "../../assets/images/calender.svg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { ReschaduleOrder, addToCart, getTimeSlotes } from "../../service/api";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const fetchTimeSlotsForDate = async (date) => {
  try {
    const timeSlotData = await getTimeSlotes(date);
    return timeSlotData.data;
  } catch (error) {
    console.error("Error fetching TimeSlots:", error);
    return [];
  }
};

const TimeSlot = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const fromWhich = location.state?.fromWhich || "";


  const currentDate = new Date();
  const [date, setDate] = useState(currentDate.toISOString().split("T")[0]);
  const today = new Date();

  const [TimeSlotDatas, setTimeSlotDatas] = useState([]);
  const [selectedTimeSlote, setSelectedTimeSlote] = useState();
  const [petId, setPetId] = useState();
  const [timeSlotError, setTimeSlotError] = useState("");

  useEffect(() => {
    const slotDatas = JSON.parse(localStorage.getItem("petData"));
    if (slotDatas?.selectedDate) {
      // setDate(slotDatas?.selectedDate);
      // setSelectedTimeSlote(slotDatas?.selectedSlot);
      setPetId(slotDatas?.pet_id);
    }
  }, []);

  // useEffect(() => {
  //   fetchTimeSlotes(date);
  // }, [date]);

  // const fetchTimeSlotes = async (datee) => {
  //   try {
  //     const timeSloteData = await getTimeSlotes(datee);
  //     // setCities(cityData.data);
  //     setTimeSlotDatas(timeSloteData.data);
  //   } catch (error) {
  //     console.error("Error fetching TimeSlote:", error);
  //   }
  // };

  useEffect(() => {
    const initializeTimeSlots = async () => {
      // setLoading(true);
      let timeSlots = await fetchTimeSlotsForDate(date);
      if (timeSlots && timeSlots.length === 0) {
        // If no time slots available for the current date, find the next available date with time slots
        let nextDate = new Date(currentDate);
        while (timeSlots.length === 0) {
          nextDate.setDate(nextDate.getDate() + 1);
          const nextFormattedDate = formatDate(nextDate);
          const nextTimeSlots = await fetchTimeSlotsForDate(nextFormattedDate);
          timeSlots = nextTimeSlots;
          setDate(nextFormattedDate);
        }
      }
      setTimeSlotDatas(timeSlots);
      // setLoading(false);
    };

    initializeTimeSlots();
  }, []);

  const formatWeekday = (locale, date) => {
    // Example: Return first letter of the weekday
    return date.toLocaleDateString(locale, { weekday: 'short' });
  };

  const formatDate = (selectedDate) => {
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
    const day = String(selectedDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const onChange = async (e) => {
    setTimeSlotError("");
    const existingData = JSON.parse(localStorage.getItem("petData"));

    setSelectedTimeSlote(existingData?.selectedSlot);

    if (existingData) {
      const selectedDate = "";
      const selectedSlot = "";

      existingData.selectedDate = selectedDate;
      existingData.selectedSlot = selectedSlot;

      localStorage.setItem("petData", JSON.stringify(existingData));
    }

    const selectedDate = new Date(e);
    // Format the date
    const formattedDate = formatDate(selectedDate);
    setDate(formattedDate);
    const timeSlots = await fetchTimeSlotsForDate(formattedDate);
    setTimeSlotDatas(timeSlots);
  };

  const saveSlot = async (e) => {
    e.preventDefault();

    const existingData = JSON.parse(localStorage.getItem("petData"));
    const bookingPlanData = JSON.parse(localStorage.getItem("selectedPlan"));
    const homeData = JSON.parse(localStorage.getItem("homeData"));

    if (existingData) {
      setPetId(existingData.pet_id);
      existingData.selectedDate = date;
      existingData.selectedSlot = selectedTimeSlote;
      localStorage.setItem("petData", JSON.stringify(existingData));
    }

    if (TimeSlotDatas && TimeSlotDatas.length === 0) {
      setTimeSlotError("No Time Slotes Available. Please Select Another Date And Time Slot.");
    }
    else {
      if (selectedTimeSlote) {
        setTimeSlotError("");
        const titleParts = selectedTimeSlote.title.split("-");
        const firstPartOfTitle = titleParts[0].trim();

        if (fromWhich.fromPage === "bookedplan") {
          const bodySend = {
            order_id: fromWhich.id,
            appoinment_date: date,
            // appoinment_time: firstPartOfTitle,
            appoinment_time: selectedTimeSlote.slug,
          };

          try {
            const orderReshadule = await ReschaduleOrder(bodySend);

            if (orderReshadule.success === "1") {
              Swal.fire({
                title: "Success",
                text: "Rescheduled Successfully!!",
                icon: "success",
                showConfirmButton: false,
                confirmButtonText: "OK",
                willClose: (isConfirmed) => {
                  if (isConfirmed) {
                    navigate("/mybookings");
                    setTimeout(() => {
                      window.scrollTo(0, 0);
                    }, 500);
                  }
                },
                timer: 1000,
                timerProgressBar: true,
              }).then((confirm) => { });
            } else {
              Swal.fire({
                title: "",
                text: orderReshadule.message,
                icon: "error",
                showConfirmButton: true,
                confirmButtonText: "OK",
                willClose: (isConfirmed) => {
                  if (isConfirmed) {
                    navigate("/mybookings");
                    setTimeout(() => {
                      window.scrollTo(0, 0);
                    }, 500);
                  }
                },
                timer: 1000,
                timerProgressBar: true,
              }).then((confirm) => { });
            }
          } catch (error) {
            console.log("Error on Reshadule Order", error);
          }
        } else {
          const bodySend = {
            item_id: bookingPlanData?.id,
            // variation_id: selectedTimeSlote.id,
            clear_cart: 0,
            quantity: 1,
            city_id: homeData.selectedCityId,
            isMultipleCity: false,
            appoinment_date: date,
            appoinment_time: selectedTimeSlote.slug,
            pet_id: existingData.pet_id,
          };

          try {
            const cart = await addToCart(bodySend);
            if (cart.status == 200 || cart.message == "Item already in cart") {
              navigate("/myorder");
            }
            // setTimeout(() => {
            //   window.scrollTo(0, 0);
            // }, 500);
            // if (cart.success === "1") {
            //   Swal.fire({
            //     title: "Success",
            //     text: "Slot Booked Successfully.",
            //     icon: "success",
            //     showConfirmButton: false,
            //     confirmButtonText: "OK",
            //     // willClose: (isConfirmed) => {
            //     //   if (isConfirmed) {
            //     //     window.scrollTo(0, 0);
            //     //     navigate("/myorder");
            //     //   }
            //     // },
            //     timer: 1000,
            //     timerProgressBar: true,
            //   }).then(() => {
            //     navigate("/myorder");
            //     setTimeout(() => {
            //       window.scrollTo(0, 0);
            //     }, 500);
            //   });
            // } else {
            //   navigate("/myorder");
            //   setTimeout(() => {
            //     window.scrollTo(0, 0);
            //   }, 500);
            //   // Swal.fire({
            //   //   title: "",
            //   //   text: cart.message,
            //   //   icon: "error",
            //   //   showConfirmButton: true,
            //   //   confirmButtonText: "OK",
            //   //   willClose: (isConfirmed) => {
            //   //     if (isConfirmed) {
            //   //       navigate("/myorder");
            //   //       setTimeout(() => {
            //   //         window.scrollTo(0, 0);
            //   //       }, 500);
            //   //     }
            //   //   },
            //   //   timer: 1000,
            //   //   timerProgressBar: true,
            //   // }).then((confirm) => {});
            // }
            // navigate("/myorder");
          } catch (error) {
            console.log("Error on Add to Cart", error);
          }
        }
      } else {
        setTimeSlotError("Please Select Time");
      }
    }


  };

  return (
    <div className="container my-5">
      <div className="row justify-content-center align-items-center px-4">
        <div className="col-md-6 d-none d-lg-block">
          <img src={LeftImg} alt="img" />
        </div>
        <div className="col-md-6 text-center">
          <h5 className="text-center">When Your Pet Will Feel Special?</h5>
          <form className="my-4 text-center calendar-modal-slot">
            <>
              <Calendar
                onChange={onChange}
                min={formatDate(today)}
                minDate={new Date()}
                value={date}
                formatWeekday={formatWeekday}
              />
              <div className="dividerBelow my-2"></div>
              <div className="select-time-label">
                <span>Select Time</span>
              </div>
              <div className="mb-3 px-3 sloteDiv justify-content-center align-items-center">
                {Array.isArray(TimeSlotDatas) && TimeSlotDatas.length > 0 ? (
                  TimeSlotDatas.map((timeSlot) => (
                    <button
                      key={timeSlot.id}
                      type="button"
                      className={`timeSlotBtn m-1 ${selectedTimeSlote && selectedTimeSlote.id === timeSlot.id ? "timeSloteActive" : ""} ${timeSlot.available ? "availableSlot" : "bookedSlot"}`}
                      onClick={() => {
                        setSelectedTimeSlote(timeSlot);
                        setTimeSlotError("");
                      }}
                      disabled={!timeSlot.available}
                    >
                      {timeSlot.title}
                      <span className={`status ${timeSlot.available ? "available" : "booked"}`}>
                        {timeSlot.available ? "Available" : "Booked"}
                      </span>
                    </button>
                    // <input
                    //     key={timeSlot.id}
                    //     type="button"
                    //     className={`timeSlotBtn m-1 ${selectedTimeSlote && selectedTimeSlote.id === timeSlot.id
                    //         ? "timeSloteActive"
                    //         : ""
                    //         }`}
                    //     value={timeSlot.title}
                    //     onClick={() => {
                    //         if (timeSlot.available) {
                    //             setSelectedTimeSlote(timeSlot);
                    //             setTimeSlotError("");
                    //         }
                    //     }}
                    //     disabled={!timeSlot.available}
                    // />
                  ))
                ) : (
                  <p className="mt-3">No Time Slotes Available. Please Select Another Date.</p>
                )}

              </div>

            </>
            <div className="mb-3 px-3 text-center">
              <button
                type="submit"
                className="btn-save text-center"
                onClick={saveSlot}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TimeSlot;
