import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Prescription from '../../component/Prescription';
import Vaccine from '../../component/Vaccine';

function BookedPDF() {
  const location = useLocation();

  const data = location?.state?.data;
  const pdfType = location?.state?.pdfType;
  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  return (
    <div>
      {pdfType == "Prescription" ? <Prescription data={data} /> : <Vaccine data={data} />}
    </div>

  )
}

export default BookedPDF