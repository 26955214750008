import React from 'react';
import Convenient from '../../../assets/icons/convinent.svg';
import Ethic from '../../../assets/icons/ethicvaccine.svg';
import Care from '../../../assets/icons/trancare.svg';
import './HighlightPointsV2.css'

function HighlightPoints() {
    const highlightData = [
        {
            icon: Convenient,
            title: 'Convenient Vet Visits',
            description:
                'No need to visit the clinic. Get professional veterinary care at your doorstep or consult a vet via video from the comfort of your home.',
        },
        {
            icon: Ethic,
            title: 'Ethical Vaccinations',
            description:
                "We provide only the vaccinations your pet truly needs, with no unnecessary treatments. You can trust us to keep your pet's health a priority.",
        },
        {
            icon: Care,
            title: 'Transparent Care',
            description:
                'From diagnosis to treatment, we keep you informed every step of the way, with clear explanations and no hidden fees.',
        },
    ];

    return (
        <div className="container mt-0 mb-0 benefits-container highlightpc">
            <h2 className="benefits-heading">Highlight Points</h2>
            <div className="highlight-cards">
                {highlightData.map((highlight, index) => (
                    <div key={index} className="benefit-card">
                        <SliderItem slider={highlight} />
                    </div>
                ))}
            </div>
        </div>
    );
}

const SliderItem = ({ slider }) => {
    return (
        <div className="benefit-card-content">
            <img src={slider.icon} alt={slider.title} className="benefit-icon" />
            <h3>{slider.title}</h3>
            <p>{slider.description}</p>
        </div>
    );
};

export default HighlightPoints;
