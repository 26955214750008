import React, { useEffect, useState } from 'react';


const FAQItem = ({ title, description, primaryColor, secondaryColor }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
    const handleExpansionChange = () => {
      setIsExpanded(!isExpanded);
    };
  
    // Define default colors
    const defaultPrimaryColor = '#B6A6E0'; // Default primary color
    const defaultSecondaryColor = '#F2F1FA'; // Default secondary color
  
    return (
      <div
        style={{
          margin: '10px',
          borderRadius: '20px',
          border: `1px solid ${primaryColor || defaultPrimaryColor}`,
          overflow: 'hidden',
          flex: `1 1 100%`,
          boxSizing: 'border-box',
        }}
      >
        {/* Header */}
        <div
          onClick={handleExpansionChange}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '15px',
            backgroundColor: isExpanded ? secondaryColor || defaultSecondaryColor : 'white',
            cursor: 'pointer',
            // borderRadius: '20px',
          }}
        >
          <h4
            style={{
              margin: 0,
              fontSize: isExpanded ? '18px' : '16px',
              color: isExpanded ? '#000' : '#000',
              fontWeight: '700',
            }}
          >
            {title}
          </h4>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Toggle Icon */}
            <div
              style={{
                width: '30px',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                backgroundColor: isExpanded ? primaryColor || defaultPrimaryColor : secondaryColor || defaultSecondaryColor,
              }}
            >
              {isExpanded ? (
                <span
                  style={{
                    fontSize: '20px',
                    color: 'white',
                  }}
                >
                  &times; {/* Close Icon */}
                </span>
              ) : (
                <span
                  style={{
                    fontSize: '20px',
                    color: '#000',
                  }}
                >
                  + {/* Add Icon */}
                </span>
              )}
            </div>
          </div>
        </div>
  
        {/* Content */}
        {isExpanded && (
          <div style={{ backgroundColor: 'white', padding: '20px' }}>
            <p
              style={{
                fontSize: '15px',
                fontWeight: '500',
                lineHeight: '1.5',
                margin: 0,
              }}
            >
              {description}
            </p>
          </div>
        )}
      </div>
    );
  };
  



const FAQList = ({ primaryColor, secondaryColor }) => {
    
  const faqData = [
    {
      title: "What makes DearPet food different from other dog foods?",
      description: "DearPet foods are crafted with human-grade ingredients and cooked in bone broth, ensuring they are rich in natural nutrients. Unlike many other foods, we use no preservatives and our unique retort packaging keeps our food fresh without refrigeration."
    },
    {
      title: "Is DearPet food suitable for all dog breeds and sizes?",
      description: "Absolutely! Our recipes are formulated to meet the nutritional needs of dogs of all breeds and sizes. We recommend checking the feeding guidelines on our packaging to determine the right portion for your dog."
    },
    {
      title: "Can DearPet food be used for both puppies and adult dogs?",
      description: "Yes, our food is designed to be well-balanced and nutritious for dogs at all life stages. However, puppies may have specific nutritional requirements, so we advise consulting with your vet for a feeding plan tailored to young dogs."
    },
    {
      title: "How long does DearPet food last once the package is opened?",
      description: "Once opened, we recommend consuming DearPet food within 48 hours if stored in the refrigerator to maintain its freshness and nutritional value."
    },
    {
      title: "Do I need to cook or prepare DearPet food before serving?",
      description: "No, there's no cooking or additional preparation needed. Our food is ready-to-serve right out of the packet, making mealtime convenient and enjoyable for both you and your pet."
    },
    {
      title: "How should I store DearPet food?",
      description: "Keep unopened pouches in a cool, dry place away from direct sunlight. There’s no need for refrigeration, making storage hassle-free."
    },
    {
      title: "Are there any artificial colors, flavors, or preservatives in DearPet food?",
      description: "No, we're proud to say that our food is free from artificial colors, flavors, and preservatives. We're committed to providing your pet with food that’s as close to nature as possible."
    },
    {
      title: "What are the benefits of food being cooked in bone broth?",
      description: "Cooking in bone broth enhances the flavor and provides a natural source of nutrition like minerals and collagen, which are great for joint health and overall vitality."
    }
  ];

  // Define default colors
  const defaultPrimaryColor = '#B6A6E0'; // Default primary color
  const defaultSecondaryColor = '#F2F1FA'; // Default secondary color

  return (
    <div className='container' style={{ padding: '0 20px' }}>
      {/* Title */}
      <h4
        className="sec4Heading text-start"
        style={{
          color: '#F29A4C',
          marginBottom: '20px',
          textAlign: 'left',
        }}
      >
        Frequently asked questions
      </h4>

      {/* FAQ Items */}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap', // Allows wrapping of FAQs in multiple rows
          justifyContent: 'space-between', // Adjust spacing between items
        }}
      >
        {faqData.map((faq, index) => (
          <FAQItem
            key={index}
            title={faq.title}
            description={faq.description}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQList;
