import React from "react";
import "./style.css";

const ReturnRefundPolicy = () => {
  return (
    <div className="container container-adjusted">
      <h5 className="text-center my-4 rr-title mt-3 pt-5">Return & Refund Policy</h5>
      <div className="row">
        <div className="col-md-12">
          <div className="rte">
            <div className="spreate">
              <p className="rr-t1">Cancellation and Rescheduling</p>
              <p className="p4">
                <b>Flexible Cancellation : </b> Customers can cancel their
                appointment up to 24 hours before the scheduled time without any
                charge.
              </p>
              <p className="p4">
                <b>Late Cancellation Fee : </b> Cancellations made within 24
                hours of the appointment time will incur a 50% charge of the
                service fee.
              </p>
              <p className="p4">
                <b>Rescheduling : </b> Customers may reschedule an appointment
                up to 12 hours prior to the appointment without incurring any
                additional fees.
              </p>
            </div>
            <div className="spreate">
              <p className="rr-t1">Refunds</p>
              <p className="p4">
                <b>Full Refund : </b> In cases of cancellation 24 hours prior to
                the appointment, customers are entitled to a full refund.
              </p>
              <p className="p4">
                <b>Partial Refund : </b> For late cancellations, 50% of the
                service fee will be refunded.
              </p>
              <p className="p4">
                <b>No Show : </b> If the customer is not available at the time
                of the appointment and fails to notify us, no refund will be
                issued.
              </p>
              <p className="p4">
                <b>Service Dissatisfaction : </b> If you are dissatisfied with
                the grooming service provided, please contact us within 24 hours
                of the service. We will investigate the matter and, if found
                justified, offer a partial or full refund or a complimentary
                grooming session, depending on the case.
              </p>
              <p className="p4">
                <b>Refund Processing : </b> Refunds will be processed within
                7-10 business days and will be credited to the original method
                of payment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnRefundPolicy;
