import React from 'react';
import StressFree from '../../../assets/icons/stressfree.svg';
import Personalize from '../../../assets/icons/cat-lover.svg';
import Attention from '../../../assets/icons/search-person.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import './WhyChoose.css'

function WhyChoosePc() {
    const chooseData = [
        {
            icon: StressFree,
            title: 'Stress-Free Experience',
            description:
                "Whether it's an at-home visit or a video consultation, your pet remains in a familiar and comfortable environment, reducing stress.",
        },
        {
            icon: Personalize,
            title: 'Personalize Attention',
            description:
                'Every pet is unique. Our vets provide individualized care, focusing on your pet’s specific needs with one-on-one attention.',
        },
        {
            icon: Attention,
            title: 'Trusted by Pet Parents',
            description:
                'Thousands of pet parents trust DearPet for our ethical approach, transparent communication, and commitment to quality care.',
        },
    ];

    return (
        <div className="container py-0 whychoosepc-container">
            <h3 className="benefits-heading">Why Choose DearPet?</h3>
            <div className="whychoose-wrapper">
                <div className="whychoose-desktop">
                    {chooseData.map((slid, index) => (
                        <div key={index}>
                            <SliderItem slider={slid} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

const SliderItem = ({ slider }) => {
    return (
        <div className="benefit-card" style={{minHeight:"220px"}}>
            <img src={slider.icon} alt={slider.title} className="benefit-icon" />
            <h3>{slider.title}</h3>
            <p>{slider.description}</p>
        </div>
    );
};

export default WhyChoosePc;
