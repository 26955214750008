import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProductPlans } from '../../service/api';
import './ProductsPage.css';
import { ThreeDots } from "react-loader-spinner";

function ProductsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const catId = location.state?.catId;
  const title = location.state?.title;
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    if (catId) {
      setLoading(true); // Set loading to true before fetching
      try {
        const bodySend = {
          category_id: catId,
          city_id: "Delhi",
          isMultipleCity: false,
        };

        const planData = await getProductPlans(bodySend);
        
        // Sort the plans by offer_price in ascending order
        const sortedPlans = (planData?.data || []).sort((a, b) => {
          return a.offer_price - b.offer_price; // Ascending order
        });

        setProducts(sortedPlans); // Save the sorted plans in state
      } catch (error) {
        console.error("Error fetching product plans:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    } else {
      setLoading(false); // Set loading to false if no category ID
    }
  };

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  }

  return (
    <div className="products-page container-xxl">
      <h2 className='landing-services-title pb-5'>{title ?? 'DearPet Products'}</h2>
      {loading ? ( // Display loading indicator when fetching
        <div className="d-flex justify-content-center">
        <ThreeDots color="#7B5BFB" height={50} width={50} />
      </div>
      ) : products.length > 0 ? (
        <div className="products-grid">
          {products.map((product) => (
            <div className="product-card" key={product.id} onClick={() => handleProductClick(product.id)}>
              <img src={product.image} alt={product.title} className="product-image" />
              <div className="product-details">
                <h2 className="product-list-title">{product.title}</h2>
                <p className="product-card-price">
                  <span className="offer-price">₹{product.offer_price}</span>
                  <span className="original-price">₹{product.price}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-products">
          <p>No products found</p>
        </div>
      )}
    </div>
  );
}

export default ProductsPage;
