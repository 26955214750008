import React, { useEffect, useState } from 'react';
import { getProductPlans, addToCart } from '../../service/api';
import './style.css';
import { useNavigate } from 'react-router-dom';

export default function VaccinationPackage() {
  const navigate = useNavigate();
  const [bookingPlans, setBookingPlans] = useState([]);
  const [city, setCity] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    getAllPlans();
  }, []);

  const getAllPlans = async () => {
    const localCity = JSON.parse(localStorage.getItem('homeData'));
    const existingPetData = JSON.parse(localStorage.getItem('petData'));
    const existingData = JSON.parse(localStorage.getItem('userIdData'));

    setCity(existingData?.city || localCity?.selectedCity?.title || '');
    
    try {
      const bodySend = {
        category_id: '1',
        city_id: existingData?.selectedCityId || localCity?.selectedCityId,
        isMultipleCity: true,
        pet: existingPetData?.pet_type,
      };

      const planData = await getProductPlans(bodySend);
      setBookingPlans(planData.data || []);
    } catch (error) {
      console.log('Error fetching plans:', error);
    }
  };

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  const submitSlot = async () => {
    try {
      const bookingPlanData = JSON.parse(localStorage.getItem('selectedPlan'));
      const homeData = JSON.parse(localStorage.getItem('homeData'));
      const existingData = JSON.parse(localStorage.getItem('petData'));
      const userData = JSON.parse(localStorage.getItem('userIdData'));

      const bodySend = {
        item_id: bookingPlanData?.id,
        quantity: 1,
        city_id: userData?.selectedCityId || homeData?.selectedCityId,
        isMultipleCity: false,
        appoinment_date: existingData?.selectedDate,
        appoinment_time: existingData?.selectedSlot?.slug,
        pet_id: existingData?.pet_id,
      };

      const cart = await addToCart(bodySend);
      if (cart.status === 200 || cart.message === 'Item already in cart') {
        navigate('/myorder');
      }
    } catch (error) {
      console.log('Error adding to cart:', error);
    }
  };

  return (
    <div className="container-xxl mt-20 mb-5 text-center package-confirm-container">
      <h1 className="packageConfirmTitle">
        {/* Packages {city && `for`} <span style={{ color: '#F29A4C' }}>{city}</span> */}
        Plans
      </h1>

      <div className="packages-list row">
        {bookingPlans.map((plan, index) => (
          <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div
              className={`booking-card ${selectedPlan?.id === plan.id ? 'selected' : ''}`}
              onClick={() => handlePlanSelect(plan)}
            >
              <div className="booking-card-header">
                <h3>{plan.title}</h3>
                <p className="price">
                  <span className="original-price">₹{plan.price}/-</span>
                  <span className="discounted-price">₹{plan.offer_price}/-</span>
                </p>
              </div>
              <div className="booking-card-body">
                <ul>
                  {plan.includedServices?.map((service, subIndex) => (
                    <li key={subIndex}>{service}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="text-center my-4">
        <button type="submit" className="btn nextBtn text-center mt-4" onClick={submitSlot}>
          Next
        </button>
      </div>
    </div>
  );
}
