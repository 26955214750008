import React from 'react';
import Convenient from '../../../assets/icons/convinent.svg';
import Ethic from '../../../assets/icons/ethicvaccine.svg';
import Care from '../../../assets/icons/trancare.svg';
import './HighlightPointsV2.css'

function HighlightPointsV2() {
    const highlightData = [
        {
            icon: Convenient,
            title: "Convenient Vet Visits",
        },
        {
            icon: Ethic,
            title: "Ethical Vaccinations",
        },
        {
            icon: Care,
            title: "Transparent Care",
        },
    ];

    return (
        <div className=" mt-0 highlights-container highlightmb">
            {highlightData.map((item, index) => (
                <div key={index} className="highlight-item">
                    <img src={item.icon} alt={item.title} className="highlight-icon" />
                    <h3>{item.title}</h3>
                </div>
            ))}
        </div>
    );
}

export default HighlightPointsV2;
