import React, { useState, useEffect } from 'react';
import '../../pages/Products/Foods/Food.css'
import StarIcon from '../../assets/icons/staricon.svg'
import { useNavigate } from 'react-router-dom';

const FoodGridCard = ({ data, colorPrimary, backgroundColor, backgroundLightColor }) => {
    const [isVisible, setIsVisible] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (data.title.toLowerCase().includes('grain free') ||
            data.title.toLowerCase().includes('grain-free') ||
            data.title.toLowerCase().includes('grainfree')) {
            const timer = setInterval(() => {
                setIsVisible(prev => !prev);
            }, 1000);
            return () => clearInterval(timer); // Clean up the interval on component unmount
        }
    }, [data.title]);

    const handleProductClick = (id) => {
        navigate(`/dearpet-food/${id}`);
      }

    return (
        <div className="food-card" style={{ backgroundColor }}>
            <div className="food-card-image">
                <img src={data.image} alt={data.title} />
            </div>
            <div className="food-card-content">
                <h4>{data.title}</h4>
                <div className="food-card-footer mt-4">
                    <div className="add-button" style={{ backgroundColor: colorPrimary }} onClick={() => handleProductClick(data.id)}>
                        Add to cart
                    </div>
                    <div className="food-price">
                        ₹ {data.offer_price}
                    </div>
                </div>
                <div className={`rating ${backgroundLightColor}`}>
                    <img src={StarIcon} className='star-icon' />
                    <span className="rating-text">{data.rating}</span>
                </div>
                {data.title.toLowerCase().includes('grain free') ||
                 data.title.toLowerCase().includes('grain-free') ||
                 data.title.toLowerCase().includes('grainfree') ? (
                    <div className={`grain-free-badge ${isVisible ? 'visible' : ''}`}>
                        Grain Free
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default FoodGridCard;
