import React from 'react';
import './LandingCities.css';
import Delhi from '../../assets/landingcities/delhi.svg';
import Hyderabad from '../../assets/landingcities/hyderabad.svg';
import Banglore from '../../assets/landingcities/bangalore.svg';
import Mumbai from '../../assets/landingcities/mumbai.svg';
import Gurugram from '../../assets/landingcities/gurugram.svg';
import Ghaziabad from '../../assets/landingcities/ghaziabad.svg';
import Faridabad from '../../assets/landingcities/faridabad.svg';
import Noida from '../../assets/landingcities/noida.svg';
import GreaterNoida from '../../assets/landingcities/greaternoida.svg';
import Ballabhgarh from '../../assets/landingcities/ballabhgarh.svg';
import Narela from '../../assets/landingcities/narela.svg';

function LandingCities() {
  const cities = [
    { name: 'Delhi', image: Delhi, url: '/delhi' },
    { name: 'Mumbai', image: Mumbai, url: '/mumbai' },
    { name: 'Hyderabad', image: Hyderabad, url: '/hyderabad' },
    { name: 'Bangalore', image: Banglore, url: '/bangalore' },
    { name: 'Gurugram', image: Gurugram , url: '/gurugram'},
    { name: 'Ghaziabad', image: Ghaziabad , url: '/ghaziabad'},
    { name: 'Faridabad', image: Faridabad , url: '/faridabad'},
    { name: 'Noida', image: Noida , url: '/noida'},
    { name: 'Greater Noida', image: GreaterNoida , url: '/greaternoida'},
    { name: 'Ballabhgarh', image: Ballabhgarh , url: '/ballabhgarh'},
    { name: 'Narela', image: Narela , url: '/narela'}
  ];

  return (
    <div>
      <h2 className="landingcitytitle">Professional Pet Services at Your City</h2>
      <div className="city-grid">
        {cities.map((city, index) => (
          <a key={index} href={city.url} className="city-item">
            <img src={city.image} alt={city.name} className="city-image" />
            <span className="city-name">{city.name}</span>
          </a>
        ))}
      </div>
    </div>
  );
}

export default LandingCities;
