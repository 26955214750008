import React, { useRef, useState } from "react";
import "./style.css";
import { useEffect } from "react";
import {
  Deletepet,
  getBreedList,
  getMyPets,
  getServiceDetails,
  getAddress,
  getProductPlans,
  getTimeSlotes,
  ReschaduleOrder,
  addToCart,
  getCities,
  addPet,
  saveAddress,
  getProfile,
} from "../../service/api";
import { ThreeDots } from "react-loader-spinner";
import Deleticon from "../../assets/icons/delete-icons.svg";
import EditIcon from "../../assets/icons/edit.png";
import DeletIconWhite from "../../assets/icons/delete-icons-white.svg";
import EditIconWhite from "../../assets/icons/edit-white.png";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import DefaultDog from "../../assets/images/d-dog.png";
import DefaultCat from "../../assets/images/d-cat.png";
import DSelectedDog from "../../assets/images/d-s-dog.png";
import DSelectedCat from "../../assets/images/d-s-cat.png";
import swal from "sweetalert";
import Modal from "react-modal";
import CrossIcon from "../../assets/icons/cross.svg";
import StarIcon from "../../assets/icons/star.svg";
import AerrowBottom from "../../assets/icons/aerrow-bottom.svg";
import RightCheck from "../../assets/icons/rightCheck.svg";
import BookingPlanCard from "../../component/Card/BookingPlanCard";
import dogIocn from "../../assets/icons/mdi-dog.svg";
import catIocn from "../../assets/icons/mdi-cat.svg";
import dogDarkIocn from "../../assets/icons/mdi-dark-dog.svg";
import catDarkIocn from "../../assets/icons/mdi-dark-cat.svg";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import RightBox from "../../assets/icons/right-box.svg";
import PlusIcon from "../../assets/icons/Plus.svg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

// ----------------------------------
const fetchTimeSlotsForDate = async (date, city) => {
  try {
    const timeSlotData = await getTimeSlotes(date, city);
    return timeSlotData.data;
  } catch (error) {
    console.error("Error fetching TimeSlots:");
    return [];
  }
};


export default function Vaccination() {
  const navigate = useNavigate();

  const [petList, setPetList] = useState();
  const [selectedPet, setSelectedPet] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [breedData, setBreedData] = useState([]);
  const [peterror, setPeterror] = useState("");
  const [isShowPlan, setIsShowPlan] = useState(false);

  const currentDate = new Date();
  const [date, setDate] = useState(currentDate.toISOString().split("T")[0]);
  const today = new Date();

  const [TimeSlotDatas, setTimeSlotDatas] = useState([]);
  const [selectedTimeSlote, setSelectedTimeSlote] = useState();
  const [timeSlotError, setTimeSlotError] = useState("");
  const [petId, setPetId] = useState();
  const location = useLocation();
  const fromWhich = location.state?.fromWhich || "";

  // plan confirm
  const [planId, setPlanId] = useState();
  const [selectedPlanData, setSelectedPlanData] = useState();
  // const [isLoading, setIsLoading] = useState(true);

  const [localUAddress, setLocalUAddress] = useState();
  const [uAddress, setUAddress] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [bookingPlans, setBookingPlans] = useState([]);
  const [planLoadingHeader, setPlanLoadingHeader] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [pet_name, setPet_name] = useState("");
  const [pet_type, setPet_type] = useState("");
  const [breed, setBreed] = useState("");
  const [breedName, setBreedName] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [breedOptions, setBreedOptions] = useState([]);
  const [petNameError, setPetNameError] = useState("");
  const [petTypeError, setPetTypeError] = useState("");
  const [breedError, setBreedError] = useState("");
  const [aggressionError, setAggressionError] = useState("");
  const [aggression, setAggression] = useState("");
  const [latitude, setLatitude] = useState("23.022505");
  const [longitude, setLongitude] = useState("72.5713621");
  const autocompleteRef = useRef(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [address, setAddress] = useState("");
  const [locality, setLocality] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [pincode, setPincode] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCityId, setSelectedCityId] = useState();
  const [selectedStateId, setSelectedStateId] = useState();
  const [addressError, setAddressError] = useState("");
  const [localityError, setLocalityError] = useState("");
  const [houseNoError, setHouseNoError] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [pincodeError, setPincodeError] = useState("");
  const [googleAutocomplete, setGoogleAutocomplete] = useState();
  const [inputSearchValue, setInputSearchValue] = useState();
  const [cities, setCities] = useState([]);
  const [authTokens, setAuthTokens] = useState();
  const [nationalNumber, setNationalNumber] = useState();
  const [fname, setFname] = useState();

  //   this function is for redirect at VetsOrder
  const handleSubmitVetOrder = () => {
    navigate("/vaccination-package");
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };
  //   ends here

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setIsMobile(window.innerWidth < 768);
    };
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    if (authTokens === undefined) {
      const mytoken = localStorage.getItem("authToken");
      setAuthTokens(mytoken);
    }
  }, [authTokens]);

  // plan confirm

  const fetchCities = async () => {
    try {
      const cityData = await getCities();

      setCities(cityData.data);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  useEffect(() => {
    getPetList();
    getBreedDataList();
    handleSelectPet();
    fetchCities();
    getUserProfille();
  }, []);

  const getUserProfille = async () => {
    try {
      const getUData = await getProfile();
      if (getUData.data) {
        const fullName = getUData.data.name;

        setFname(fullName);

        setNationalNumber(getUData.data.phone_number);
      }
    } catch (error) {
      console.log("Error on get Profile", error);
    }
  };

  useEffect(() => {
    const slotDatas = JSON.parse(localStorage.getItem("petData"));
    if (slotDatas?.selectedDate) {
      // setDate(slotDatas?.selectedDate);
      // setSelectedTimeSlote(slotDatas?.selectedSlot);
      setPetId(slotDatas?.pet_id);
    }
  }, []);

  const getPetList = async () => {
    try {
      const petData = await getMyPets();
      setIsLoading(false);
      setPetList(petData.data);
      handleSelectPet(petData.data[0]);
    } catch (error) {
      console.log("Error on get pet List", error);
    }
  };

  const validatePetDetails = () => {
    let isValid = true;

    if (!pet_name) {
      setPetNameError("Pet Name is required");
      isValid = false;
    } else {
      setPetNameError("");
    }

    if (!pet_type) {
      setPetTypeError("Pet Type is required");
      isValid = false;
    } else {
      setPetTypeError("");
    }

    if (!aggression) {
      setAggressionError("Aggression is required");
      isValid = false;
    } else {
      setAggressionError("");
    }

    if (!breed) {
      setBreedError("Breed is required");
      isValid = false;
    } else {
      setBreedError("");
    }
    return isValid;
  };

  const validateAddressDetails = () => {
    let isValid = true;

    if (!houseNo) {
      setHouseNoError("House No. is required.");
      isValid = false;
    } else if (houseNo.length < 3 || houseNo.length > 1000) {
      setHouseNoError("House No. must be between 3 and 1000 characters.");
      isValid = false;
    } else {
      setHouseNoError("");
    }

    // Validate Address
    if (!address) {
      setAddressError("Address is required.");
      isValid = false;
    } else if (address.length < 3 || address.length > 1000) {
      setAddressError("Address must be between 3 and 1000 characters.");
      isValid = false;
    } else {
      setAddressError("");
    }

    if (!locality) {
      setLocalityError("locality is required.");
      isValid = false;
    } else if (locality.length < 3 || locality.length > 1000) {
      setLocalityError("Locality must be between 3 and 1000 characters.");
      isValid = false;
    } else {
      setLocalityError("");
    }

    // Validate City
    if (!selectedCityId) {
      setCityError("City is required.");
      isValid = false;
    } else {
      setCityError("");
    }

    // Validate Pincode
    if (!pincode) {
      setPincodeError("Pincode is required.");
      isValid = false;
    } else if (!/^\d+$/.test(pincode)) {
      setPincodeError("Pincode must contain only digits.");
      isValid = false;
    } else if (pincode.length != 6) {
      setPincodeError("Pincode must be 6 digits.");
      isValid = false;
    }  else {
      const city = cities.find(city => city.id == selectedCityId);
      if (city) {
          const regex = new RegExp(city.pincode_prefix);
          if (!regex.test(pincode)) {
              setPincodeError(`Invalid Pincode for ${city.title}. Please enter a valid pincode.`);
              isValid = false;
          } else {
              setPincodeError(""); // Clear error if valid
          }
      } else {
          setPincodeError("Invalid city selection.");
          isValid = false;
      }
  }

    return isValid;
  };

  useEffect(() => {
    if (googleAutocomplete) {
      const selectedCityMap = cities.find((city) =>
        googleAutocomplete.address_components.some((component) =>
          component.long_name.toLowerCase().includes(city.title.toLowerCase())
        )
      );

      const pincodeComponent = googleAutocomplete.address_components.find(
        (component) => component.types.includes("postal_code")
      );
      const pincode = pincodeComponent ? pincodeComponent.long_name : "";

      const filteredComponents = googleAutocomplete.address_components.filter(
        (component) =>
          !component.types.some((type) =>
            ["plus_code", "locality", "country", "postal_code"].includes(type)
          )
      );

      // Construct the address from the remaining components
      const filteredAddress = filteredComponents
        .map((component) => component.long_name)
        .join(", ");

      const sublocalityComponents =
        googleAutocomplete.address_components.filter((component) =>
          component.types.includes("sublocality")
        );

      const sublocalities = sublocalityComponents.map(
        (component) => component.long_name
      );

      const combinedSublocalities = sublocalities.join(", ");

      // Set the address state
      setAddress(filteredAddress);
      setAddressError("");

      if (selectedCityMap) {
        setSelectedCity(selectedCityMap.title);
        setSelectedCityId(selectedCityMap.id);
      }
      if (pincode) {
        setPincode(pincode);
        setPincodeError("");
      }

      if (combinedSublocalities) {
        setLocality(combinedSublocalities);
      }
    }
  }, [cities, googleAutocomplete]);

  const handleAddPet = async (e) => {
    if (validatePetDetails()) {
      try {
        const bodySend = {
          name: pet_name,
          pet_type: pet_type,
          breed: breed,
          aggression: aggression,
        };
        const petData = await addPet(bodySend);

        if (petData.success === "1") {
          const localPetData = {
            pet_name: pet_name,
            pet_type: pet_type,
            breed: breed,
            pet_id: petData.data.id,
            id: petData.data.id,
            aggression: aggression,
          };

          localStorage.setItem("petData", JSON.stringify(localPetData));
          getPetList();
          return true;
          // setTimeout(() => {

          //   if (Object.keys(uAddress).length > 0) {
          //     saveSlot();
          //   }
          // }, 1500);
        }
      } catch (error) {
        return false;
      }
    }
    return false;
  };

  const handleAddAddress = async (e) => {
    let completeAddress = houseNo + ", " + locality + ", " + address;

    if (validateAddressDetails()) {
      try {
        const bodySend = {
          name: fname,
          phone_number: nationalNumber,
          state_id: selectedStateId,
          city_id: selectedCityId,
          address_type: "other",
          address: completeAddress,
          pincode: pincode,
          latitude: latitude,
          longitude: longitude,
        };
        const saveAddressData = await saveAddress(bodySend);

        if (saveAddressData.status === "200") {
          const existingData = JSON.parse(localStorage.getItem("userIdData"));

          if (existingData) {
            existingData.state = selectedState;
            existingData.city = selectedCity;
            existingData.address = address;
            existingData.pincode = pincode;
            existingData.selectedCityId = selectedCityId;
            existingData.selectedStateId = selectedStateId;
            localStorage.setItem("userIdData", JSON.stringify(existingData));
          }
          getUserAddress();
          return true;
          // setTimeout(() => {
          //   if (selectedPet) {
          //     saveSlot();
          //   }
          // }, 1500);
        }
      } catch (error) {
        console.error("Error in Save Address:", error);
        return false;
        // Swal.fire({
        //   title: "",
        //   text: "Error on Save User Address",
        //   icon: "error",
        // });
      }
    }
    return false;
  };

  const getBreedDataList = async () => {
    try {
      const breedData = await getBreedList();
      setBreedData(breedData.data);
      setBreedOptions(breedData.data);
    } catch (error) {
      console.log("Error on get breed list", error);
    }
  };

  const handleSelectPet = (pet = "") => {
    const existingData = JSON.parse(localStorage.getItem("petData"));

    if (existingData) {
      const selectedDate = existingData.selectedDate;
      const selectedSlot = existingData.selectedSlot;

      existingData.aggression = pet.aggression;
      existingData.breed = pet.breed;
      existingData.description = pet.description;
      existingData.dob = pet.dob;
      existingData.gender = pet.gender;
      existingData.pet_age = pet.pet_age;
      existingData.pet_id = pet.id;
      existingData.pet_name = pet.name;
      existingData.pet_size = pet.pet_size;
      existingData.pet_type = pet.pet_type;
      existingData.selectedDate = selectedDate;
      existingData.selectedSlot = selectedSlot;

      localStorage.setItem("petData", JSON.stringify(existingData));
    } else {
      const localPetData = {
        aggression: pet.aggression,
        breed: pet.breed,
        description: pet.description,
        dob: pet.dob,
        gender: pet.gender,
        pet_age: pet.pet_age,
        pet_id: pet.id,
        pet_name: pet.name,
        pet_size: pet.pet_size,
        pet_type: pet.pet_type,
      };
      localStorage.setItem("petData", JSON.stringify(localPetData));
    }
    setPeterror("");
    setSelectedPet(pet);
  };

  const getBreedTitle = (breedId) => {
    const breed = breedData?.find((item) => item.id === breedId);
    return breed ? breed.title : "Unknown Breed";
  };

  const handleNextBtn = () => {
    if (selectedPet) {
      setIsShowPlan(true);
    } else {
      setPeterror("Please select pet");
    }
  };

  const deletepet = async (id) => {
    Swal.fire({
      title: "Are you sure you want to Delete Pet ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7B5BFB",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const bodyData = { id: id };
          const petdelete = await Deletepet(bodyData);
          getPetList();
          if (petdelete.success === "1") {
            Swal.fire({
              title: "Pet Deleted Successfully!",
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            }).then(() => {});
          }
        } catch (error) {
          console.log("Error on Delete pet ", error);
        }
      }
    });
  };

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    let filteredSuggestions = [];
    if (userInput.length > 0) {
      filteredSuggestions = breedOptions.filter(
        (option) =>
          option.title.toLowerCase().includes(userInput.toLowerCase()) &&
          option.type == (pet_type ?? "Dog")
      );
    }
    setSuggestions(filteredSuggestions);
    setBreedName(userInput);
    setShowSuggestions(true);
    setBreedError("");
  };

  const handleSelectSuggestion = (breed) => {
    setBreedName(breed.title);
    setBreed(breed.id);
    setShowSuggestions(false);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 200); // Delay closing suggestions to allow selection
  };

  const editPet = (id) => {
    navigate("/editpet", { state: { pid: id } });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  // paln confirm
  const customStyles = {
    content: {
      width: "70%", // Default width for larger screens
      maxHeight: "80vh",
      margin: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };
  const mobileStyles = {
    content: {
      maxHeight: "80vh",
      width: "95%", // Adjusted width for mobile screens
      top: "10%", // Inset by 25%
      left: "2.5%", // Center horizontally
      right: "2.5%",
      bottom: "10%",
    },
  };

  useEffect(() => {
    const localCity = JSON.parse(localStorage.getItem("homeData"));
    setPet_type(localCity.selectedPet);
    setLocalUAddress(localCity);
    if (localCity) {
      setSelectedCity(localCity.selectedCity.title);
      setSelectedCityId(localCity.selectedCityId);
      setSelectedStateId(localCity.selectedCity.state_id);
    }
  }, []);

  useEffect(() => {
    getServiceData();
    getUserAddress();
    // getAllPlans();
  }, []);

  const getServiceData = async () => {
    try {
      const plandata = JSON.parse(localStorage.getItem("selectedPlan"));
      const localAdd = JSON.parse(localStorage.getItem("homeData"));

      if (plandata) {
        setPlanId(plandata.id);
      }
      const serviceData = await getServiceDetails(
        plandata.id,
        localAdd.selectedCityId
      );
      if (serviceData.success === "1") {
        setIsLoading(false);
        if (serviceData.data[0]) {
          setSelectedPlanData(serviceData.data[0]);
        }
      }
    } catch (error) {
      console.log("Error on Get ServiceData", error);
    }
  };

  const getUserAddress = async () => {
    try {
      const userAddData = await getAddress();
      if (userAddData.data[0]) {
        setUAddress(userAddData.data[0]);
      }
    } catch (error) {
      console.log("Error on get User address", error);
    }
  };

  const getAllPlans = async () => {
    try {
      const bodySend = {
        category_id: "1",
        city_id: localUAddress.selectedCityId,
        isMultipleCity: true,
        pet: localUAddress.selectedPet,
      };
      const planData = await getProductPlans(bodySend);
      setBookingPlans(planData.data);
      setPlanLoadingHeader(false);
    } catch (error) {
      console.log("Error on get palns", error);
    }
  };

  // useEffect(() => {
  //   console.log("event call",date);
  //   fetchTimeSlotes(date);
  // }, [date]);

  useEffect(() => {
    const initializeTimeSlots = async () => {
      // setLoading(true);
      let timeSlots = await fetchTimeSlotsForDate(
        date,
        uAddress.city_id ?? localUAddress?.selectedCityId
      );
      if (timeSlots && timeSlots.length === 0) {
        // If no time slots available for the current date, find the next available date with time slots
        let nextDate = new Date(currentDate);
        while (timeSlots.length === 0) {
          nextDate.setDate(nextDate.getDate() + 1);
          const nextFormattedDate = formatDate(nextDate);
          const nextTimeSlots = await fetchTimeSlotsForDate(
            nextFormattedDate,
            uAddress.city_id ?? localUAddress?.selectedCityId
          );
          timeSlots = nextTimeSlots;
          setDate(nextFormattedDate);
        }
      }
      setTimeSlotDatas(timeSlots);
      // setLoading(false);
    };

    initializeTimeSlots();
  }, []);

  const formatDate = (selectedDate) => {
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
    const day = String(selectedDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const onChange = async (e) => {
    setTimeSlotError("");
    const existingData = JSON.parse(localStorage.getItem("petData"));

    setSelectedTimeSlote(existingData?.selectedSlot);

    if (existingData) {
      const selectedDate = "";
      const selectedSlot = "";

      existingData.selectedDate = selectedDate;
      existingData.selectedSlot = selectedSlot;

      localStorage.setItem("petData", JSON.stringify(existingData));
    }

    const selectedDate = new Date(e);
    // Format the date
    const formattedDate = formatDate(selectedDate);
    setDate(formattedDate);
    const timeSlots = await fetchTimeSlotsForDate(
      formattedDate,
      uAddress.city_id ?? localUAddress.selectedCityId
    );
    setTimeSlotDatas(timeSlots);
  };

  // const fetchTimeSlotes = async (datee) => {
  //   try {
  //     const timeSloteData = await getTimeSlotes(datee);
  //     // setCities(cityData.data);
  //     setTimeSlotDatas(timeSloteData.data);
  //   } catch (error) {
  //     console.error("Error fetching TimeSlote:", error);
  //   }
  // };

  const saveSlot = async () => {
    const existingData = JSON.parse(localStorage.getItem("petData"));
    const bookingPlanData = JSON.parse(localStorage.getItem("selectedPlan"));
    const homeData = JSON.parse(localStorage.getItem("homeData"));

    if (existingData) {
      setPetId(existingData.pet_id);
      existingData.selectedDate = date;
      existingData.selectedSlot = selectedTimeSlote;
      localStorage.setItem("petData", JSON.stringify(existingData));
    }

    if (TimeSlotDatas && TimeSlotDatas.length === 0) {
      setTimeSlotError(
        "No Time Slotes Available. Please Select Another Date And Time Slot."
      );
    } else {
      if (selectedTimeSlote) {
        setTimeSlotError("");
        const titleParts = selectedTimeSlote.title.split("-");
        const firstPartOfTitle = titleParts[0].trim();

        if (fromWhich.fromPage === "bookedplan") {
          const bodySend = {
            order_id: fromWhich.id,
            appoinment_date: date,
            // appoinment_time: firstPartOfTitle,
            appoinment_time: selectedTimeSlote.slug,
          };

          try {
            const orderReshadule = await ReschaduleOrder(bodySend);

            if (orderReshadule.success === "1") {
              Swal.fire({
                title: "Success",
                text: "Rescheduled Successfully!!",
                icon: "success",
                showConfirmButton: false,
                confirmButtonText: "OK",
                willClose: (isConfirmed) => {
                  if (isConfirmed) {
                    navigate("/mybookings");
                    setTimeout(() => {
                      window.scrollTo(0, 0);
                    }, 500);
                  }
                },
                timer: 1000,
                timerProgressBar: true,
              }).then((confirm) => {});
            } else {
              Swal.fire({
                title: "",
                text: orderReshadule.message,
                icon: "error",
                showConfirmButton: true,
                confirmButtonText: "OK",
                willClose: (isConfirmed) => {
                  if (isConfirmed) {
                    navigate("/mybookings");
                    setTimeout(() => {
                      window.scrollTo(0, 0);
                    }, 500);
                  }
                },
                timer: 1000,
                timerProgressBar: true,
              }).then((confirm) => {});
            }
          } catch (error) {
            console.log("Error on Reshadule Order", error);
          }
        } else {
          navigate("/package-confirmation");
        }
      } else {
        setTimeSlotError("Please Select Time Slot");
        Swal.fire({
          title: "",
          text: "Please Select Time Slot",
          icon: "error",
          showConfirmButton: true,
          confirmButtonText: "OK",

          timer: 1000,
          timerProgressBar: true,
        });
      }
    }
  };

  const formatWeekday = (locale, date) => {
    // Example: Return first letter of the weekday
    return date.toLocaleDateString(locale, { weekday: "short" });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (petList.length === 0 || Object.keys(uAddress).length === 0) {

  //     let isPetAdded = true;
  //     let isAddressAdded = true

  //     if (petList.length === 0) {
  //       isPetAdded = await handleAddPet();
  //     }
  //     if (Object.keys(uAddress).length === 0) {
  //       isAddressAdded = await handleAddAddress();
  //     }

  //     window.scrollTo(0, 0);
  //     setTimeout(() => {
  //       if (isPetAdded && isAddressAdded) {
  //         saveSlot();
  //       }

  //     }, 1000);

  //   } else {
  //     if (Object.keys(uAddress).length > 0 && selectedPet) {
  //       saveSlot();
  //     } else if (petList.length > 0 && !selectedPet) {
  //       setPeterror("Please select a pet");
  //       window.scrollTo(0, 0);
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check and log values for debugging

    // Ensure petList and uAddress are defined
    const safePetList = Array.isArray(petList) ? petList : [];
    const safeUAddress =
      typeof uAddress === "object" && uAddress !== null ? uAddress : {};

    // Log the safe values to verify

    // Check if petList is empty or uAddress is empty
    if (safePetList.length === 0 || Object.keys(safeUAddress).length === 0) {
      let isPetAdded = true;
      let isAddressAdded = true;

      if (safePetList.length === 0) {
        isPetAdded = await handleAddPet();
      }
      if (Object.keys(safeUAddress).length === 0) {
        isAddressAdded = await handleAddAddress();
      }

      window.scrollTo(0, 0);
      setTimeout(() => {
        if (isPetAdded && isAddressAdded) {
          saveSlot();
        }
      }, 1000);
    } else {
      if (Object.keys(safeUAddress).length > 0 && selectedPet) {
        saveSlot();
      } else if (safePetList.length > 0 && !selectedPet) {
        setPeterror("Please select a pet");
        window.scrollTo(0, 0);
      }
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   if (petList.length === 0 || Object.keys(uAddress).length === 0) {

  //     if (petList.length === 0) {
  //       handleAddPet(e);
  //     }
  //     if (Object.keys(uAddress).length === 0) {
  //       handleAddAddress(e);
  //     }
  //     setTimeout(() => {
  //       window.scrollTo(0, 0);
  //     }, 500);

  //   } else {
  //     if (Object.keys(uAddress).length > 0 && selectedPet) {
  //       saveSlot();
  //     } else {

  //       if (petList.length > 0 && !selectedPet) {
  //         setPeterror("Please select a pet");
  //         window.scrollTo(0, 0);
  //       }

  //     }
  //   }

  // };

  const onEditAddressMove = () => {
    navigate("/editProfile", { state: { frompage: "confirm" } });
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  const onEditPlanMove = () => {
    // window.scrollTo(0, 0);
    // navigate("/");
    getAllPlans();
    Swal.fire({
      title: "Are you sure you want to change your package?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7B5BFB",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsModalOpen(true);
      }
    });
  };

  const handleChangeBookNow = async (plan) => {
    localStorage.setItem("selectedPlan", JSON.stringify(plan));
    setSelectedPlanData(plan);
    setIsModalOpen(false);
    window.scrollTo(0, 0);
    // window.location.reloa1d();
  };

  const handleModalClose = (async) => {
    setIsModalOpen(false);
  };

  const handleClick = (event) => {
    event.target.showPicker(); // Opens the calendar on supported browsers
  };

  return (
    <div className="container myPetContainer">
      <div className={` myPetRow `}>
        <h5 className="text-center mypet-title ">
          Who wants Royal Treatment ?
        </h5>
        {petList && petList.length > 0 ? (
          <div className="d-flex justify-content-end mt-1 mb-3">
            <button
              className="AddPet mp-btn mp-btn2"
              onClick={() => {
                navigate("/addpet");
                setTimeout(() => {
                  window.scrollTo(0, 0);
                }, 500);
              }}
            >
              Add Pet
            </button>
          </div>
        ) : (
          <div></div>
        )}
        {isLoading && (
          <div className="d-flex justify-content-center">
            <ThreeDots color="#7B5BFB" height={50} width={50} />
          </div>
        )}

        {!isLoading && petList && petList.length > 0 ? (
          <div className={`pet-container`}>
            {petList.map((pet) => (
              <div className={` mypetscontainer my-1`} key={pet.id}>
                <div
                  className={`form-check p-0 petBox ${
                    selectedPet && selectedPet.id === pet.id
                      ? "selectedPetItemMyPet"
                      : ""
                  }`}
                >
                  <label className="pet-btn-mypet">
                    <input
                      type="radio"
                      className="btn-check"
                      name="pet"
                      checked={selectedPet && selectedPet.id === pet.id}
                      onChange={() => {
                        handleSelectPet(pet);
                      }}
                    />
                    <div className="pet-details-mypet">
                      <div className="pet-row">
                        <div className="pet-image-container">
                          <img
                            src={
                              selectedPet?.id !== pet.id &&
                              pet.pet_type === "Dog"
                                ? DefaultDog
                                : DSelectedDog
                            }
                            alt={pet.name}
                            className={`pet-image ${
                              pet.pet_type === "Dog" ? "" : "d-none"
                            }`}
                          />
                          <img
                            src={
                              selectedPet?.id !== pet.id &&
                              pet.pet_type === "Cat"
                                ? DefaultCat
                                : DSelectedCat
                            }
                            alt={pet.name}
                            className={`pet-image ${
                              pet.pet_type === "Cat" ? "" : "d-none"
                            }`}
                          />
                        </div>
                        <div className="pet-nm-div mt-1 mb-2">
                          <span className={`pet-name`}>{pet.name}</span>
                          <span className={`pet-breed `}>
                            {getBreedTitle(pet.breed)}
                          </span>
                        </div>
                      </div>

                      <div className="bookingSelectedBox">
                        {selectedPet && selectedPet.id === pet.id ? (
                          <img
                            src={RightBox}
                            className="bookingSelectedBoxCheck"
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            ))}
          </div>
        ) : (
          !isLoading && (
            // <h3 className="text-center mypet-title my-3">Pet Not Found.</h3>
            <div className="mb-3 top-details">
              <span className="ab-title">Add Pet</span>
              <br />
              <div
                className="mb-5 top-typeDiv"
                style={{ borderRadius: "15px" }}
              >
                <label
                  htmlFor="pet_type"
                  className="form-label text-center whatTypeTitle"
                >
                  What type of pet?
                </label>

                <div className="selectPetDivAdd">
                  <button
                    className={
                      "petBtn " + (pet_type === "Dog" ? "petBtnActive" : "")
                    }
                    onClick={() => {
                      setPet_type("Dog");
                    }}
                  >
                    <img
                      src={pet_type === "Dog" ? dogIocn : dogDarkIocn}
                      className="me-2"
                      alt="Dog"
                    />
                    Dogs{" "}
                  </button>
                  <button
                    className={
                      "petBtn " + (pet_type === "Cat" ? "petBtnActive" : "")
                    }
                    onClick={() => {
                      setPet_type("Cat");
                    }}
                  >
                    <img
                      src={pet_type === "Cat" ? catIocn : catDarkIocn}
                      className="me-2"
                      alt="Cat"
                    />
                    Cats{" "}
                  </button>
                </div>
                {petTypeError && (
                  <div className="invalid-feedback">{petTypeError} </div>
                )}
              </div>
              <div className="mb-3 px-3 inputWrapper">
                <label htmlFor="pet_name" className="inputLabel">
                  Pet's Name
                </label>
                <input
                  type="text"
                  className={`inputFormControl borderradius ${
                    petNameError ? "is-invalid" : ""
                  }`}
                  id="pet_name"
                  name="pet_name"
                  placeholder="Please enter your pet's name..."
                  value={pet_name}
                  onChange={(e) => {
                    setPet_name(e.target.value);
                    setPetNameError("");
                  }}
                />
                {petNameError && (
                  <div className="invalid-feedback">{petNameError}</div>
                )}
              </div>

              <div className="mb-3 px-3 inputWrapper">
                <label htmlFor="pet_breed" className="inputLabel">
                  Pet's Breed
                </label>
                <input
                  type="text"
                  className={`inputFormControl borderradius ${
                    breedName && suggestions.length === 0 ? "selected" : ""
                  }`}
                  id="pet_breed"
                  name="pet_breed"
                  placeholder="Please enter your pet’s breed"
                  value={breedName}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                />
                {showSuggestions && suggestions.length > 0 && (
                  <ul className="suggestionList">
                    {suggestions.map((breed, index) => (
                      <li
                        key={index}
                        onClick={() => handleSelectSuggestion(breed)}
                        className="suggestionItem"
                      >
                        {breed.title}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className=" px-3">
                {breedError && (
                  <div className="invalid-feedback mb-3">{breedError}</div>
                )}
              </div>
              <div className="mb-3 px-3 ">
                <label htmlFor="petAggresive" className="form-label">
                  How aggressive is your pet?{" "}
                </label>
                <div className="form-row px-3 py-2">
                  <div className="form-check">
                    <input
                      className={`form-check-input ${
                        aggressionError ? "is-invalid" : ""
                      }`}
                      type="radio"
                      name="aggression"
                      id="aggressive1"
                      value="18"
                      checked={aggression === "18"}
                      onChange={() => {
                        setAggression("18");
                        setAggressionError("");
                      }}
                    />
                    <label
                      className="form-check-label radioLabel"
                      htmlFor="aggressive1"
                    >
                      {" "}
                      Low{" "}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className={`form-check-input ${
                        aggressionError ? "is-invalid" : ""
                      }`}
                      type="radio"
                      name="aggression"
                      id="aggressive2"
                      value="17"
                      checked={aggression === "17"}
                      onChange={() => {
                        setAggression("17");
                        setAggressionError("");
                      }}
                    />
                    <label
                      className="form-check-label radioLabel"
                      htmlFor="aggressive2"
                    >
                      {" "}
                      Normal{" "}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className={`form-check-input ${
                        aggressionError ? "is-invalid" : ""
                      }`}
                      type="radio"
                      name="aggression"
                      id="aggressive3"
                      value="16"
                      checked={aggression === "16"}
                      onChange={() => {
                        setAggression("16");
                        setAggressionError("");
                      }}
                    />
                    <label
                      className="form-check-label radioLabel"
                      htmlFor="aggressive3"
                    >
                      High
                    </label>
                  </div>
                </div>
                {aggressionError && (
                  <div className="invalid-feedback">{aggressionError}</div>
                )}

                {/* <div className="mb-3 px-3 mt-3 text-center">
                  <button
                    type="submit"
                    className={
                      "btn nextBtn text-center "
                    }
                    onClick={handleAddPet}
                  >
                    Save Pet
                  </button>

                </div> */}
              </div>
            </div>
          )
        )}

        {peterror && (
          <div className="invalid-feedback justify-content-center">
            {peterror}
          </div>
        )}
        {/* 
        {
          !isShowPlan ?
            <div className="botom-btn">
              <button
                className="Next mp-btn"
                onClick={() => {
                  handleNextBtn();
                }}
              >
                Next
              </button>
            </div> : ''} */}
      </div>

      {/* planConfirm */}

      <div className=" justify-content-center">
        {isLoading && (
          <div className="d-flex justify-content-center mt-5">
            <ThreeDots color="#7B5BFB" height={50} width={50} />
          </div>
        )}

        {!isLoading && (
          <div className=" pb-3 px-0 top-details-row">
            <div className="top-details mb-4">
              {Object.keys(uAddress).length > 0 ? (
                <div>
                  <div className="details-header-add mb-3">
                    <span className="ab-title">Address</span>
                    <br />
                    <div className="editIconClass">
                      <img
                        src={EditIcon}
                        alt="edit"
                        className="editIcon"
                        onClick={onEditAddressMove}
                      />
                      <span>Edit</span>
                    </div>
                  </div>
                  <div className="AddressDiv">
                    {/* <div className="leftCity">
                    <span className="ab-title">Your Selected City</span><br />
                    <span className="ab-desc m-0">{localUAddress.selectedCity.title}</span>
                  </div>
                  <div className="dividerCity"></div> */}
                    <div className="rightCity">
                      <span className="ab-desc m-0">{uAddress.address}</span>
                      <br />
                      <span className="ab-desc m-0">{uAddress.city_name}</span>
                      <br />
                      <span className="ab-desc m-0">{uAddress.pincode}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <span className="ab-title">Add Address</span>
                  <br />
                  <form className="my-4">
                    <div className="mb-3 px-3 inputWrapper">
                      <label htmlFor="location" className="inputLabel">
                        Your locality
                      </label>
                      <ReactGoogleAutocomplete
                        ref={autocompleteRef}
                        apiKey="AIzaSyATEexg-rv1JsMhL7B46chlOv3wWzGrR7E"
                        onPlaceSelected={(place) => {
                          if (place.geometry) {
                            setSelectedPlace(place);
                            const lat = place.geometry.location.lat();
                            const lng = place.geometry.location.lng();
                            setLatitude(lat);
                            setLongitude(lng);
                            setGoogleAutocomplete(place);
                            setInputSearchValue("");
                            setAddressError("");
                          }
                        }}
                        placeholder={"Search for location"}
                        className="inputFormControl"
                        options={{
                          types: [],
                          componentRestrictions: { country: "in" },
                        }}
                      />

                      {addressError && (
                        <div className="invalid-feedback">{addressError}</div>
                      )}
                    </div>

                    <div className="mb-3 px-3 inputWrapper">
                      <label htmlFor="houseno" className="inputLabel">
                        Flat/Budling
                      </label>
                      <input
                        type="text"
                        className={`inputFormControl ${
                          addressError ? "is-invalid" : ""
                        }`}
                        name="houseno"
                        placeholder="Please add Flat, House no."
                        onChange={(e) => {
                          setHouseNo(e.target.value);
                          setHouseNoError("");
                        }}
                        value={houseNo}
                      />
                      {houseNoError && (
                        <div className="invalid-feedback">{houseNoError}</div>
                      )}
                    </div>

                    <div className="mb-3 px-3 inputWrapper">
                      <label htmlFor="locality" className="inputLabel">
                        Locality/Street
                      </label>
                      <input
                        type="text"
                        className={`inputFormControl ${
                          localityError ? "is-invalid" : ""
                        }`}
                        name="locality"
                        placeholder="Please add Locality, Street"
                        onChange={(e) => {
                          setLocality(e.target.value);
                          setLocalityError("");
                        }}
                        value={locality}
                      />
                      {localityError && (
                        <div className="invalid-feedback">{localityError}</div>
                      )}
                    </div>

                    <div className="mb-3 px-3 inputWrapper">
                      <label htmlFor="city" className="inputLabel">
                        City
                      </label>
                      <select
                        className="inputFormControl"
                        onChange={async (e) => {
                          const selectedCityId = e.target.value;
                          const selectedCity = cities.find(
                            (city) => city.id === selectedCityId
                          );

                          setSelectedCityId(selectedCityId);
                          setSelectedCity(
                            selectedCity ? selectedCity.title : ""
                          );
                          setCityError("");
                          const timeSlots = await fetchTimeSlotsForDate(
                            date,
                            selectedCityId
                          );
                          setTimeSlotDatas(timeSlots);
                        }}
                        value={selectedCityId}
                      >
                        <option value="" disabled hidden>
                          {" "}
                          Select City
                        </option>
                        {cities.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.title}
                          </option>
                        ))}
                      </select>
                      {cityError && (
                        <div className="invalid-feedback">{cityError}</div>
                      )}
                    </div>
                    <div className="mb-3 px-3 inputWrapper">
                      <label htmlFor="pincode" className="inputLabel">
                        Pincode
                      </label>
                      <input
                        type="text"
                        className={`inputFormControl ${
                          pincodeError ? "is-invalid" : ""
                        }`}
                        name="pincode"
                        placeholder="Pincode"
                        onChange={(e) => {
                          setPincode(e.target.value);
                          setPincodeError("");
                        }}
                        value={pincode}
                      />
                      {pincodeError && (
                        <div className="invalid-feedback">{pincodeError}</div>
                      )}
                    </div>
                    {/* <div className="mb-3 px-3 text-center">
                      <button
                        type="submit"
                        className={
                          "btn nextBtn text-center "
                        }
                        onClick={handleAddAddress}
                      >
                        Save Address
                      </button>

                    </div> */}
                  </form>
                </div>
              )}
            </div>

            <div className=" top-details">
              <div className="details-header">
                <p className="ab-title mb-3">Choose Slot</p>
              </div>

              <div className="mb-3 px-3 inputWrapper">
                <label htmlFor="slots" className="inputLabel">
                  Date and Time
                </label>
                <input
                  className="dateInputFormControl"
                  id="slots"
                  readOnly
                  onClick={() => setIsModalOpen(true)}
                  type="text"
                  onChange={onChange}
                  value={`${
                    date && selectedTimeSlote?.title
                      ? new Date(date).toLocaleDateString()
                      : "Please Select Date and Time"
                  } ${
                    selectedTimeSlote?.title
                      ? `• ${selectedTimeSlote.title}`
                      : ""
                  }`}
                  min={formatDate(today)}
                />
              </div>
              {/* <div className="mb-3  mt-3 inputWrapper">
                <label htmlFor="slots" className="inputLabel" style={{ left: 15 }}>Date and Time</label>
                <input className="dateInputFormControl" id="slots" onClick={handleClick} type="date" onChange={onChange} value={date} min={formatDate(today)} />
              </div>
              <div className="select-time-label p-0">
                <span>Select Time</span>
              </div>
              <div className="mb-3 p-0 sloteDiv justify-content-center align-items-center">
                {Array.isArray(TimeSlotDatas) && TimeSlotDatas.length > 0 ? (
                  TimeSlotDatas.map((timeSlot) => (
                    <button
                      key={timeSlot.id}
                      type="button"
                      className={`timeSlotBtn m-1 ${selectedTimeSlote && selectedTimeSlote.id === timeSlot.id ? "timeSloteActive" : ""} ${timeSlot.available ? "availableSlot" : "bookedSlot"}`}
                      onClick={() => {
                        setSelectedTimeSlote(timeSlot);
                        setTimeSlotError("");
                      }}
                      disabled={!timeSlot.available}
                    >
                      {timeSlot.title}
                      <span className={`status ${timeSlot.available ? "available" : "booked"}`}>
                        {timeSlot.available ? "Available" : "Booked"}
                      </span>
                    </button>
                  
                  ))
                ) : (
                  <p className="mt-3">No Time Slotes Available. Please Select Another Date.</p>
                )}
              </div> */}
              {timeSlotError && (
                <div className="mt-3 invalid-feedback justify-content-center">
                  {timeSlotError}
                </div>
              )}
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn nextBtn text-center mt-4"
                onClick={handleSubmitVetOrder}
              >
                Next
              </button>
            </div>

            {/* <Modal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              style={window.innerWidth <= 768 ? { ...customStyles, ...mobileStyles } : customStyles}
            >
              <div className="r-m">
                <div className="modal-header-row">
                  <h2>Change Your Plan</h2>
                  <img src={CrossIcon} alt="cross" onClick={() => { handleModalClose() }} style={{ cursor: 'pointer' }} />
                </div>
                {planLoadingHeader ? (
                  <div className="d-flex justify-content-center mt-5">
                    <ThreeDots color="#7B5BFB" height={50} width={50} />
                  </div>
                ) : (
                  <div className="row planMainRow">
                    {bookingPlans.map((plan, index) => (
                      <BookingPlanCard key={index} plan={plan} index={index} length={bookingPlans.length} isMobile={isMobile} isModal={true} handleBookNow={handleChangeBookNow} />

                    ))}
                  </div>
                )}
              </div>
            </Modal> */}

            <Modal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              contentLabel="Select Date"
              className="calendar-modal"
              overlayClassName={"calendar-modal-overlay"}
              ariaHideApp={false}
            >
              <>
                <Calendar
                  onChange={onChange}
                  min={formatDate(today)}
                  minDate={new Date()}
                  value={date}
                  formatWeekday={formatWeekday}
                />
                <div className="dividerBelow my-2"></div>
                <div className="select-time-label">
                  <span>Select Time</span>
                </div>

                <div className="mb-3 px-3 sloteDiv justify-content-center align-items-center">
                  {Array.isArray(TimeSlotDatas) && TimeSlotDatas.length > 0 ? (
                    TimeSlotDatas.map((timeSlot) => (
                      <button
                        key={timeSlot.id}
                        type="button"
                        className={`timeSlotBtn m-1 ${
                          selectedTimeSlote &&
                          selectedTimeSlote.id === timeSlot.id
                            ? "timeSloteActive"
                            : ""
                        } ${
                          timeSlot.available ? "availableSlot" : "bookedSlot"
                        }`}
                        onClick={() => {
                          setSelectedTimeSlote(timeSlot);
                          setTimeSlotError("");
                        }}
                        disabled={!timeSlot.available}
                      >
                        {timeSlot.title}
                        <span
                          className={`status ${
                            timeSlot.available ? "available" : "booked"
                          }`}
                        >
                          {timeSlot.available ? "Available" : "Booked"}
                        </span>
                      </button>
                      // <input
                      //     key={timeSlot.id}
                      //     type="button"
                      //     className={`timeSlotBtn m-1 ${selectedTimeSlote && selectedTimeSlote.id === timeSlot.id
                      //         ? "timeSloteActive"
                      //         : ""
                      //         }`}
                      //     value={timeSlot.title}
                      //     onClick={() => {
                      //         if (timeSlot.available) {
                      //             setSelectedTimeSlote(timeSlot);
                      //             setTimeSlotError("");
                      //         }
                      //     }}
                      //     disabled={!timeSlot.available}
                      // />
                    ))
                  ) : (
                    <p className="mt-3">
                      No Time Slotes Available. Please Select Another Date.
                    </p>
                  )}
                </div>
                <button
                  className="btn-save"
                  onClick={() => setIsModalOpen(false)}
                >
                  Save
                </button>
              </>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};
