import React, { useEffect, useState } from "react";
import dogIocn from "../../assets/icons/mdi-dog.svg";
import catIocn from "../../assets/icons/mdi-cat.svg";
import DropdownIcon from "../../assets/icons/location-icon.svg";
import { getCities, getProductPlans } from "../../service/api";
import StarGreyIcon from "../../assets/icons/star-grey.svg";
import { ThreeDots } from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BookingPlanCard from "../../component/Card/BookingPlanCard";
import VetPlanCard from "../../component/Card/VetPlanCard";

function VetPacks() {

  const location = useLocation();
  const catId = location.state?.catId;
  const selectedCityId = location.state?.selectedCityId;
  const petType = location.state?.petType;
  const planTitle  = location.state?.planTitle;
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  const [selectedStateId, setSelectedStateId] = useState();
  const [userAddress, setUserAddress] = useState("");
  const [userPincode, setUserPincode] = useState("");
  const [userAddId, setUserAddId] = useState();
  const [inputDisable, setInputDisable] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [loggedUserData, setLoggedUserData] = useState();
  const navigate = useNavigate();
  const [bookingPlans, setBookingPlans] = useState([]);
  const [planLoader, setPlanLoader] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setIsMobile(window.innerWidth < 768);
    };
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    const loginuser = JSON.parse(localStorage.getItem("loggedin"));
    const loginUserData = JSON.parse(localStorage.getItem("userIdData"));
    if (loginuser) {
      setIsLogin(loginuser);
    }
    if (loginUserData) {
      setLoggedUserData(loginUserData);
    }
  }, []);

  useEffect(() => {
    window.scroll(0,0)
    fetchPlans();
  }, [selectedCityId]);

  const fetchPlans = async () => {
    try {
  
      const bodySend = {
        category_id: catId ?? "1",
        city_id: selectedCityId,
        isMultipleCity: true,
        pet: petType,  // Use pet type from params
      };
      const planData = await getProductPlans(bodySend);
      const order = ["Basic", "Basic + Hygiene", "Essential", "Advance"];
      const plans =
        planData.data &&
        planData?.data.sort((a, b) => {
          const getOrderIndex = (title) => {
            for (let i = 0; i < order.length; i++) {
              if (title.includes(order[i])) {
                return i;
              }
            }
            return -1;
          };

          const aIndex = getOrderIndex(a.title);
          const bIndex = getOrderIndex(b.title);

          if (aIndex === -1 && bIndex === -1) return 0;
          if (aIndex === -1) return 1;
          if (bIndex === -1) return -1;

          return aIndex - bIndex;
        });

      setBookingPlans(plans);
      if (planData.data.length !== 0 && planData.success === "1") {
        setPlanLoader(false);
      }
    } catch (error) {
      // Handle the error if needed
    }
  };

  const handleBookNow = (plan) => {
    localStorage.setItem("selectedPlan", JSON.stringify(plan));
    // Uncomment the navigation logic as needed
    const naviData = {
      fromPage: "packages",
    };
    if (isLogin) {
      navigate("/mypets", { state: { fromWhich: naviData } });
    } else {
      navigate("/personal-details", { state: { fromWhich: naviData } });
    }
  };

  return (
    <div className="mainBookingContainer" style={{ marginTop: "100px" }}>
      

      <div className="bookingPlansWrapper">
        <h1 className="packageConfirmTitle homePackageTitle mt-3 text-center">
          <span style={{ color: "#F29A4C" }}>{selectedCity?.title ?? ""}</span>{" "}
          {planTitle ?? 'Packages'}
        </h1>
        <div className="city-botom-txt mb-4">
          <img src={StarGreyIcon} alt="star" className="me-1 pb-1" />
          <span className="citytext">
            Prices may vary according to your City and Pet
          </span>
        </div>

        {planLoader && (
          <div className="d-flex justify-content-center">
            <ThreeDots color="#7B5BFB" height={50} width={50} />
          </div>
        )}

        {!planLoader && (
          <div
            className="row justify-content-center bookingCardContainer mt-2"
            style={{ margin: "0 auto" }}
          >
            {bookingPlans.map((plan, index) => (
              <VetPlanCard
                key={index}
                plan={plan}
                index={index}
                length={bookingPlans.length}
                isMobile={isMobile}
                handleBookNow={handleBookNow}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default VetPacks;
