import React, { useEffect, useRef } from "react";

import BenefitAtHome from "../BenefitAtHome/BenefitAtHome";
import CatService from "../CatServices/CatServices";
import DogService from "../DogServices/DogServices";
import HealthCheckUp from "../HealthCheckUp/HealthCheckUp";
import StarVets from "../StarVets/StarVets";

import TestiMonials from "../TestiMonials/TestiMonials";
import "./homestyle.css"; 
import BannerPage from "../BannerPage/BannerPage";
import Work from "../Work/Work";
import TestimonialSlider from "./TestimonialSlider";
import WhyChoose from "./Components/WhyChoose";
import OurServices from "./Components/OurServices";
import HowItWorks from "./Components/HowItWorks";
import HighlightPoints from "./Components/HighlightPoints";
import HighlightPointsV2 from "./Components/HighlightPointsV2";
import VetServices from "./Components/VetServices";
import WhyChoosePc from "./Components/WhyChoosePc";

export default function Vets() {
  const ourServicesRef = useRef(null);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const scrollToOurServices = () => {
    if (ourServicesRef.current) {
      ourServicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  return (
    <div style={{ backgroundColor: '#f5f5f5' }}>
      <BannerPage onButtonClick={scrollToOurServices} />
      <div  ref={ourServicesRef}>
        {/* <OurServices /> */}
        <VetServices />
      </div>
      <HealthCheckUp />
      <WhyChoose />
      <WhyChoosePc />
      <HowItWorks />
      <HighlightPoints />
      {/* <BenefitAtHome /> */}
      <HighlightPointsV2 />
      <StarVets />
      <br />
      <TestimonialSlider />
    </div>
  );
}
