import React, { useEffect } from 'react'
import VetCards from './VetCards'
import VetHowWork from './VetHowWork'
import VetPacks from './VetPacks'
import VetCover from './VetCover'
import { useLocation } from 'react-router-dom'
import StarVets from '../StarVets/StarVets'

function VetPageHome() {
    const location = useLocation();
  const selectedCityId = location.state?.selectedCityId;
  useEffect(() => {
    window.scroll(0,0);
  },[])
    return (
        <div>
            <VetCover />
            <VetHowWork home={true} />
            <VetCards catId="12" selectedCityId ={selectedCityId} />
            <StarVets />
        </div>
    )
}

export default VetPageHome