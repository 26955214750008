import React, { useEffect } from 'react'
import VetCards from './VetCards'
import VetHowWork from './VetHowWork'
import VetPacks from './VetPacks'
import VetCover from './VetCover'
import { useLocation } from 'react-router-dom'
import StarVets from '../StarVets/StarVets'
import VetOnCallPackages from './Components/VetOnCallPackages'

function VetPage() {
  const location = useLocation();
  const selectedCityId = location.state?.selectedCityId;


  useEffect(() => {
    window.scroll(0,0);
  },[])
  return (
    <div >
      <VetCover />
      <VetHowWork />
      {/* <VetCards catId="3" selectedCityId ={selectedCityId}  />   */}
      <VetOnCallPackages catId="3" selectedCityId ={selectedCityId} />
      <StarVets />
    </div>
  )
}

export default VetPage