import React from 'react'
import aboutus from "../../assets/img/aboutus.svg";
import nitinstorymb from '../../assets/images/nitinstory-mb.svg';
import './style.css'; // Import the CSS file for media queries

function NitinStory() {
  return (
    <div className='container mt-0'>
      {/* Image for larger screens */}
      <img
        src={aboutus}
        className="desktop-image"
        alt="About Us"
      />
      {/* Image for mobile screens */}
      <img
        src={nitinstorymb}
        className="mobile-image"
        alt="Nitin Story Mobile"
      />
    </div>
  );
}

export default NitinStory;
