import React from 'react'
import BannerImg from '../../../assets/images/foodbanner.svg'

function FoodBanner() {
  return (
    <div style={{marginTop:"80px"}}>
        <img src={BannerImg} style={{marginLeft:"auto", marginRight:"auto"}} />
    </div>
  )
}

export default FoodBanner