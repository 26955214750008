import React, { useEffect, useState } from "react";
import {
  FaDog,
  FaHome,
  FaLaptopMedical,
  FaVials,
  FaMapMarkerAlt,
} from "react-icons/fa";
import './VetServices.css'
import { getAddress, getCities, getVetCategoriesPlans, getVetCities, getVetProductPlans } from "../../../service/api";
import { useNavigate } from "react-router-dom";

const iconMapping = {
  "Online Vet": <FaLaptopMedical />,
  "Vet at Home": <FaHome />,
  "Dog/Cat Vaccination": <FaDog />,
  "Lab Testing": <FaVials />,
};

const tempData = [
  {
      "id": 1,
      "category_id": [
          3
      ],
      "title": "Vet on call",
      "type": "Both",
      "image": "https://dearpet.co/uploads/vet/vet_on_call.png",
      "product": {
          "id": "4",
          "title": "General Online Consultation",
          "image": "https://dearpet.co/uploads/2023/06/ba967bdc3041aacff7d629ab420cf233.png",
          "owner_id": "",
          "quantity": "0",
          "price": "499",
          "offer_price": "299",
          "offer": "",
          "save_rupees": "",
          "status": "active",
          "rating": "4.8",
          "total_rating": "281",
          "variation_title": "",
          "variations": [],
          "reviews": [],
          "info": "\n\n\n\n\n\n\nDearPet is committed to ensuring that your pet receives the best possible care, no matter where you are. Our Online Vet Consultation service is designed with convenience, quality, and compassion in mind. By leveraging technology, we're able to bring expert veterinary advice to your fingertips, eliminating the stress of travel and long wait times.\nEach of our veterinarians is carefully selected for their expertise, experience, and dedication to animal welfare. When you consult with a DearPet vet, you can rest assured that your pet is in the hands of a professional who genuinely cares.\nDearPet App is easy to use, ensuring that even those new to online services can navigate with ease. We also provide detailed follow-up care instructions, ensuring that you leave the consultation with a clear understanding of the next steps.\nAt DearPet, we believe that every pet deserves the best care, and we are proud to offer a service that is both accessible and reliable. Trust DearPet for a convenient, compassionate, and comprehensive veterinary experience, tailored to meet the unique needs of your furry family members.\n\n\n\n\n\n\n",
          "how_it_works": "\n\n\n\n\n\n1. Select Your Service: Choose the type of consultation you require based on your pet&rsquo;s needs. Whether it&rsquo;s a general health check-up or a specific concern, we have a service tailored for you.\n\n2. Book an Appointment: Pick a time slot that works for you. Our platform is user-friendly, allowing you to see the availability of our vets in real-time.\n\n3. Consult with a Vet: At your scheduled time, join the consultation via video or phone call. Discuss your concerns, share any relevant pet health records, and get expert advice directly from our qualified veterinarians.\n\n4. Follow-Up Care: Post-consultation, receive a detailed report with recommendations, prescriptions if necessary, and any follow-up steps to ensure your pet&rsquo;s health continues to improve.\n\n\n\n\n\n",
          "description": "Gut issues\nSkin Problems\nBehavioral Advice & More",
          "info_html": "<div class=\"s7\">\n<ul>\n<li>\n<div class=\"s7\">\n<div class=\"s6\">\n<div class=\"s7\">\n<div class=\"s7\">\n<p class=\"s10\"><span class=\"s6\">DearPet is committed to ensuring that your pet receives the best possible care, no matter where you are. Our Online Vet Consultation service is designed with convenience, quality, and compassion in mind. By leveraging technology, we're able to bring expert veterinary advice to your fingertips, eliminating the stress of travel and long wait times.</span></p>\n<p class=\"s10\"><span class=\"s6\">Each of our veterinarians is carefully selected for their expertise, experience, and dedication to animal welfare. When you consult with a DearPet vet, you can rest assured that your pet is in the hands of a professional who genuinely cares.</span></p>\n<p class=\"s10\"><span class=\"s6\">DearPet App is easy to use, ensuring that even those new to online services can navigate with ease. We also provide detailed follow-up care instructions, ensuring that you leave the consultation with a clear understanding of the next steps.</span></p>\n<p class=\"s10\"><span class=\"s6\">At DearPet, we believe that every pet deserves the best care, and we are proud to offer a service that is both accessible and reliable. Trust DearPet for a convenient, compassionate, and comprehensive veterinary experience, tailored to meet the unique needs of your furry family members.</span></p>\n</div>\n</div>\n</div>\n</div>\n</li>\n</ul>\n</div>",
          "how_it_works_html": "<div class=\"s7\">\n<ul>\n<li>\n<div class=\"s7\">\n<div class=\"s6\">\n<div class=\"s7\">\n<div class=\"s7\"><strong><span class=\"s4\">1. </span><span class=\"s5\">Select Your Service:</span></strong><span class=\"s6\"> Choose the type of consultation you require based on your pet&rsquo;s needs. Whether it&rsquo;s a general health check-up or a specific concern, we have a service tailored for you.</span></div>\n<div class=\"s7\"></div>\n<div class=\"s8\"><strong><span class=\"s4\">2. </span><span class=\"s5\">Book an Appointment:</span></strong><span class=\"s6\"> Pick a time slot that works for you. Our platform is user-friendly, allowing you to see the availability of our vets in real-time.</span></div>\n<div class=\"s8\"></div>\n<div class=\"s8\"><strong><span class=\"s4\">3. </span><span class=\"s5\">Consult with a Vet:</span></strong><span class=\"s6\"> At your scheduled time, join the consultation via video or phone call. Discuss your concerns, share any relevant pet health records, and get expert advice directly from our qualified veterinarians.</span></div>\n<div class=\"s8\"></div>\n<div class=\"s9\"><strong><span class=\"s4\">4. </span><span class=\"s5\">Follow-Up Care:</span></strong><span class=\"s6\"> Post-consultation, receive a detailed report with recommendations, prescriptions if necessary, and any follow-up steps to ensure your pet&rsquo;s health continues to improve.</span></div>\n</div>\n</div>\n</div>\n</li>\n</ul>\n</div>",
          "description_html": "<p><strong>Gut issues</strong></p>\n<p><strong>Skin Problems</strong></p>\n<p><strong>Behavioral Advice & More</strong></p>",
          "cancellation_and_refund_policy_html": "<div class=\"s7\">\n<ul>\n<li>\n<div class=\"s7\">\n<div class=\"s6\">\n<div class=\"s7\"><strong><span class=\"s5\">Cancellation and Rescheduling:</span></strong><span class=\"s6\"> We understand that plans can change. If you need to cancel or reschedule your consultation, please notify us at least 1 hour prior to your scheduled appointment time. This allows us to accommodate other pet parents in need and ensures a smooth experience for everyone.</span></div>\n<div class=\"s7\"></div>\n<div class=\"s9\"><strong><span class=\"s5\">Refunds:</span></strong><span class=\"s6\"> If cancellation is made within the appropriate time frame, you are eligible for a full refund. Rescheduling within this period is also free of charge.</span></div>\n</div>\n</div>\n</li>\n</ul>\n</div>",
          "cancellation_and_refund_policy": "\n\n\n\n\nCancellation and Rescheduling: We understand that plans can change. If you need to cancel or reschedule your consultation, please notify us at least 1 hour prior to your scheduled appointment time. This allows us to accommodate other pet parents in need and ensures a smooth experience for everyone.\n\nRefunds: If cancellation is made within the appropriate time frame, you are eligible for a full refund. Rescheduling within this period is also free of charge.\n\n\n\n\n"
      },
      "is_coming_soon": false,
      "is_product": false
  },
  {
      "id": 2,
      "category_id": [
          9
      ],
      "title": "Vaccine",
      "type": "Dog",
      "image": "https://dearpet.co/uploads/vet/dog_vaccine.png",
      "product": null,
      "is_coming_soon": true,
      "is_product": false
  },
  {
      "id": 3,
      "category_id": [
          11,
          12
      ],
      "title": "General Consultation",
      "type": "Both",
      "image": "https://dearpet.co/uploads/vet/vet_at_home.png",
      "product": null,
      "is_coming_soon": true,
      "is_product": false
  },
  {
      "id": 4,
      "category_id": [
          10
      ],
      "title": "Lab Testing",
      "type": "Both",
      "image": "https://dearpet.co/uploads/vet/lab_testing.png",
      "product": null,
      "is_coming_soon": true,
      "is_product": false
  }
]

export default function VetServices({ onCityChange }) {
  const [vetPlans, setVetPlans] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState(3);
  const [planLoader, setPlanLoader] = useState(false);
  const[selectedPet,setSelectedPet]=useState("Dog");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedStateId, setSelectedStateId] = useState();

  const [userAddress, setUserAddress] = useState("");
  const [userPincode, setUserPincode] = useState("");
  const [userAddId, setUserAddId] = useState();

  const [inputDisable, setInputDisable] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [loggedUserData, setLoggedUserData] = useState();
  const [bookingPlans, setBookingPlans] = useState([]);
  const [isMobile, setIsMobile] = useState(false);


  

  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    if (isLogin) {
      getUserAddress();
    }
  }, [isLogin]);

  useEffect(() => {
    const loginuser = JSON.parse(localStorage.getItem("loggedin"));
    const loginUserData = JSON.parse(localStorage.getItem("userIdData"));
    if (loginuser) {
      setIsLogin(loginuser);
    }
    if (loginUserData) {
      setLoggedUserData(loginUserData);
    }
  }, []);

  const fetchCities = async () => {
    try {
      const cityData = await getCities();

      // const sortedCities = cityData.data.sort((a, b) => a.sorting - b.sorting);

      // let addselectCity = [{ id: '-1', title: 'Select City', state_id: 13, sorting: '0' }, ...sortedCities]

      // setCities(sortedCities);
      // setCities(addselectCity);
      setCities(cityData.data);

      if (cityData.success === "1") {
        const storedItem = JSON.parse(localStorage.getItem("homeData"));
        if (storedItem) {
          setSelectedCity(storedItem.selectedCity);
          setSelectedPet(storedItem.selectedPet);
          setSelectedCityId(storedItem.selectedCityId);
          setSelectedStateId(storedItem.selectedCity.state_id);
          onCityChange(storedItem);
        } else {
          // setSelectedCity(cityData.data[0]);
          // setSelectedStateId(cityData.data[0].state_id);
          // setSelectedCityId(cityData.data[0].id);

          // setSelectedCity(cityData.data[0]);
          // setSelectedCityId(cityData.data[0].id);
          // setSelectedPet(selectedPet);

          const data = {
            selectedCity: cityData.data[1],
            selectedCityId: cityData.data[1].id,
            selectedPet: selectedPet,
          };

          // const data = {
          //   selectedCity: addselectCity[0],
          //   selectedCityId: addselectCity[0].id,
          //   selectedPet: selectedPet,
          // };

          // Store the default values in localStorage
          localStorage.setItem("homeData", JSON.stringify(data));

          // Retrieve stored item and update state
          const storedItem = JSON.parse(localStorage.getItem("homeData"));
          setSelectedCity(storedItem.selectedCity);
          setSelectedPet(storedItem.selectedPet);
          setSelectedCityId(storedItem.selectedCityId);
          setSelectedStateId(storedItem.selectedCity.state_id);

          // Call onCityChange with the desired data structure
          const onCityChangeData = {
            selectedCity: storedItem.selectedCity,
            selectedCityId: storedItem.selectedCityId,
            selectedPet: storedItem.selectedPet,
          };
          onCityChange(onCityChangeData);
        }
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const getUserAddress = async () => {
    try {
      const addressData = await getAddress();
      if (addressData?.data?.length > 0) {
        setSelectedStateId(addressData.data[0].state_id);
        setSelectedCityId(addressData.data[0].city_id);
        setUserAddress(addressData.data[0].address);
        setUserPincode(addressData.data[0].pincode);
        setUserAddId(addressData.data[0].id);
        setInputDisable(true);

        const mycityData = {
          id: addressData.data[0].id,
          state_id: addressData.data[0].state_id,
          title: addressData.data[0].city_name,
        };

        let userIdData = JSON.parse(localStorage.getItem("userIdData"));
        userIdData = {
          ...userIdData,
          selectedCity: mycityData,
          city: addressData.data[0].city_name,
          selectedCityId: addressData.data[0].city_id,
        };

        localStorage.setItem("userIdData", JSON.stringify(userIdData));

        const data = {
          selectedCity: mycityData,
          selectedCityId: addressData.data[0].city_id,
          selectedPet: selectedPet,
        };
        localStorage.setItem("homeData", JSON.stringify(data));
        const storedItem = JSON.parse(localStorage.getItem("homeData"));
        setSelectedCity(storedItem.selectedCity);
        setSelectedPet(storedItem.selectedPet);
        setSelectedCityId(storedItem.selectedCityId);
        setSelectedStateId(storedItem.selectedCity.state_id);
      } else {
        setInputDisable(false);
      }
    } catch (error) {
      console.log("city get address error", error);
    }
  };

  const onPetChange = (pettype) => {
    setSelectedPet(pettype);
    const selectedPet = pettype;

    const data = { selectedCity, selectedCityId, selectedPet };
    localStorage.setItem("homeData", JSON.stringify(data));
    const storedItem = JSON.parse(localStorage.getItem("homeData"));
    setSelectedCity(storedItem.selectedCity);
    setSelectedPet(storedItem.selectedPet);
    setSelectedCityId(storedItem.selectedCityId);

    const updatedData = {
      ...storedItem,
      inputVal: inputDisable,
    };
    onCityChange(updatedData);
  };

  const withoutLoginChangeCity = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = cities.find((city) => city.id === selectedCityId);

    setSelectedCityId(selectedCityId);
    setSelectedCity(selectedCity.title);

    const data = { selectedCity, selectedCityId, selectedPet };
    localStorage.setItem("homeData", JSON.stringify(data));
    const storedItem = JSON.parse(localStorage.getItem("homeData"));
    setSelectedCity(storedItem.selectedCity);
    setSelectedPet(storedItem.selectedPet);
    setSelectedCityId(storedItem.selectedCityId);
    setSelectedStateId(storedItem.selectedCity.state_id);
    if (onCityChange) {
      onCityChange(storedItem);
    }
  };

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    setSelectedCityId(selectedCityId);
    if (onCityChange) {
      onCityChange({ selectedCityId });
    }
  };



  useEffect(() => {
    if (selectedCityId) {
      fetchPlans();
    }
  }, [selectedCityId]);

  const fetchPlans = async () => {
    try {
      const bodySend = { city_id: selectedCityId };

      const planData = await getVetCategoriesPlans(bodySend);

      if (planData.success === "1" && planData.data.length > 0) {
        // const filteredPlans = planData.data.filter(plan => plan.category_id != 3);

      // Sort the filtered plans
      const sortedPlans = planData.data.sort((a, b) => {
        return a.is_coming_soon === b.is_coming_soon ? 0 : a.is_coming_soon ? 1 : -1;
      });
        
        setVetPlans(sortedPlans); // Set the sorted plans
        setPlanLoader(false);
      } else {
        setVetPlans([]); // If no plans available, set an empty array
        setPlanLoader(true); // Show loader if no plans are available
      }
    } catch (error) {
      console.error("Error fetching vet product plans:", error);
      setPlanLoader(true); // Show loader or error message
    }
  };

  // ending here

  return (
    <div className="work-container">
        <h2 className="font-extrabold landing-services-title col mb-0">Veterinary Services</h2>

      {/* <div className="city-selection">
        <h3 className="city-title">Please select your city</h3>
        <div className="select-container">
          <select
            onChange={withoutLoginChangeCity}
            value={selectedCityId || ""}
            className="city-select"
          >
            
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.title}
              </option>
            ))}
          </select>
        </div>
      </div> */}
      <div className="selectCityPart">
            <h5 className="sec2Headingcity mb-1">Please select your city</h5>
            <select
              // className={`cityDropdown1 ${inputDisable ? "muteInput" : ""}`}
              className="cityDropdown1"
              onChange={withoutLoginChangeCity}
              value={selectedCityId}
              style={
                {minWidth:'300px'}
              }
            >
              {cities.map((city) =>
                city.id == -1 ? (
                  <option key={city.id} value={city.id} disabled={true}>
                    {city.title}
                  </option>
                ) : (
                  <option key={city.id} value={city.id}>
                    {city.title}
                  </option>
                )
              )}
            </select>
          </div>

      {/* Other component logic */}

      <div className="services-container mt-4">
        <div className="vets-services-grid">
          {planLoader ? (
            <p>Loading services...</p>
          ) : vetPlans.length > 0 ? (
            vetPlans.map((plan, index) => (
              <VetServiceCard
                key={index}
                plan={plan}
                title={plan.title}
                selectedCity= {selectedCityId}
                icon={iconMapping[plan.title] || <FaHome />} // Fallback to FaHome if no icon found
                isDelhi={selectedCityId == "3"} // Assuming "1" is Delhi's ID
              />
            ))
          ) : (
            <></>
          )}
        </div>
      </div>

    </div>
  );
}

const VetServiceCard = ({ plan, title, description, selectedCity, icon, isDelhi }) => {
  const navigate = useNavigate();


  const handleBookNow = () => {

    if (Array.isArray(plan.category_id) && plan.category_id.includes(3)) {
      // localStorage.setItem("selectedPlan", JSON.stringify(plan.product));
      // navigate("/issue-list");
      navigate("/vet-on-call", { state: { catId: plan.category_id, petType: plan.type, selectedCityId: selectedCity } });
    } else if (!plan.is_product) {
      localStorage.setItem("categoryId", JSON.stringify(plan.category_id));
      navigate(`/packages`, {
        state: { catId: plan.category_id, petType: plan.type, selectedCityId: selectedCity, planTitle: plan.title }
      });
    } else {
      localStorage.setItem("selectedPlan", JSON.stringify(plan.product));
      const loginuser = JSON.parse(localStorage.getItem("loggedin"));
      const naviData = { fromPage: "vet" };
    
      if (loginuser) {
        navigate("/mypets", { state: { fromWhich: naviData } });
      } else {
        navigate("/personal-details", { state: { fromWhich: naviData } });
      }
    }
    
  };

    return (
      <div
        className={`vets-service-card ${!plan.is_coming_soon ? "clickable" : ""}`}
        onClick={() => !plan.is_coming_soon ? handleBookNow() : {}}
      >
        <div className="vets-service-image-container">
          {plan.image && (
            <img
              src={plan.image}
              alt={plan.title}
              className={`vets-service-image ${!plan.is_coming_soon ? "zoom-on-hover" : ""}`}
            />
          )}
    
          {plan.is_coming_soon && (
            <div className="coming-soon-overlay">
              <div className="coming-soon-text">Coming Soon</div>
            </div>
          )}
        </div>
        <h3 className="vets-service-title">{plan.title}</h3>
      </div>
    );
    
}